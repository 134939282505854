.post-description{display: none;}
.module-bcblog-categoryPost {
	.select {
		text-align: right;
	}
	div.selector {
		max-width: 200px;
		display: inline-block;
		span {
			text-align: left;
		}
	}
	.product-count{
		margin-top: 10px;
		display: inline-block;
	}
	.rss {
		margin-left: 10px;
		text-transform: uppercase;
		&:hover {
			color: $bkgMenu;
		}
	}
	.columns-container {
		ul {
			&.product_list {
				margin-left: -15px;
				margin-right: -15px;
				border: none;
				.bc-item {
					margin-bottom: 40px;
					padding: 0 15px;
				}
				.bc-left-post {
					.post-image {
						position: relative;
						overflow: hidden;						
						a {											
							@include imghover() ;								
						}						
						img{
							width: 100%;
							height: auto;							
							transition: 1s;																					
						}
						&:hover {
							img {
								transform: scale3d(1.1, 1.1, 1);
							}
							a:before {
								opacity: 1
							}
						}
					}
					
				}
				.bc-right-post {
					.post-name {
						h3 {
							font-size: em(12px);
							text-transform: uppercase;
						}
					}
					.post-description {
						font-size: em(12px);
						display: block;
					}	
				}
				&.grid {
					.bc-item {
						float: left;
						width: 50%;
						&:nth-child(2n+1) {
							clear: both;
						}
						@media (max-width: 480px) {
							width: 100%;
						}
					}
				}
				&.list {
					.bc-item {
						float: left;
						width: 100%;
						.bc-left-post {
							width: 50%;
							float: left;
							padding-right: 15px;
						}
						.bc-right-post {	
							width: 50%;
							float: left;
							h3 {
								margin-top: 0;
							}
						}
					}	
				}
			}
		}	
	}
}
.frame-date-author {
	font-family: $fontText;
	font-style: italic;	
	color: $colorText2;
	border-bottom: 1px solid #f3f3f3;
	padding-bottom: 10px;
	@include clearfix();
	>div {
		display: block;
		@include clearfix();
		float: left;
		margin-right: 12px;
	}
	.post-comment{
		span{font-size: 0;}
	}
	span {
		font-family: $fontText;
		font-style: inherit;
		font-size: 12px;
		color: inherit;
		display: inline-block;
		//float: left;
		//line-height: 12px;
		&:nth-child(1) {
			//color: $colorHeading;
			font-style: normal;
			//padding-right: 5px;
			font-weight: bold;
			font-size: 0;
			width: 18px;
		}		
	}	
	.date-add{
		@include blog-icon("\f017")
	}
	.author{
		@include blog-icon("\f007")
	}
	span.comment-count{		
		@include blog-icon("\f086")
		font-size: 12px;
		width: auto;
		&:before{
			padding-right: 5px;
		}
	}
}
.module-bcblog-post {
	.name_detail {
		text-transform: uppercase;
		color: $bkgMenuHover;
		margin-bottom: 5px;
		margin-top: 0;
	}
	.img_detailblog {
		margin-bottom: 30px;
		text-align: center;
	}
	.plpost_content {
		padding-bottom: 10px;	
	}
	.tag_blog {
		padding-bottom: 10px;
		.title_tag_blog {
			@include fontStyle_2();
			color: $colorHeading;
		}
		.tag_list {
			font-style: italic;
			a {
				&:hover{
					color: $bkgMenu;
					text-decoration: underline;
				}
			}
		}
	}

	.out_related_products {
		margin-bottom: 50px;
		.title {
			color: $bkgMenuHover;
			padding-bottom: 10px;
			margin-bottom: 20px;
			border-bottom: 2px solid $bkgMenu;		
		}
		.product-image {
			border: none;
		}
		.price {
			@include fontStyle_1();
			color: $bkgMenu;
		}
		.ajax_add_to_cart_button {
			margin-top: 10px;
		}
	}

	.blog-comment-list {
		margin-bottom: 50px;
		.title {
			color: $bkgMenuHover;
			padding-bottom: 10px;
			margin-bottom: 20px;
			border-bottom: 2px solid $bkgMenu;	
		}
		.comment-item {
			margin-bottom: 20px;
			>div {
				span {
					font-family: $fontTextArt;
					font-style: italic;
					&:nth-child(1) {
						@include fontStyle_2();
						margin-right: 10px;
						font-style: normal;
					}
				}
				&.comment-content {
					font-size: em(12px);
					margin: 5px 0;
				}
			}
		}
	}

	#plpost {
		margin-bottom: 40px;
		.frame-date-author{
			border-bottom: none;
			padding-bottom: 30px;
		}
	}

	.related_posts {
		.title {
			color: $bkgMenuHover;
			padding-bottom: 10px;
			margin-bottom: 30px;
			border-bottom: 2px solid $bkgMenu;
		}
		.blog_content {
			.item-related-post {
				margin-bottom: 40px;
				&:last-child {
					margin-bottom: 0;
				}
				.post-name {
					margin-bottom: 20px;
					h3 {
						margin-top: 0;
						color: $colortheme1;
						font-weight: bold;
						text-transform: uppercase;
						font-size: em(12px);
						a {
							color: inherit;	
						}						
					}
				}
				.post-description {
					margin: 0 0 10px;
					padding: 10px 0;
					border-top: 1px solid #f3f3f3;
					border-bottom: 1px solid #f3f3f3;
				}
				.post-date-add, .post-author {
					@include clearfix();
					display: inline-block; margin-right: 12px;
					span {
						font-family: $fontText;
						font-style: inherit;
						font-size: 12px;
						color: inherit;
						display: inline-block;
						float: left;
						line-height: 1.5;
						&:nth-child(1) {
							color: $colorHeading;
							font-style: normal;
							padding-right: 5px;
							font-weight: bold;
						}
					}
					.date-add{
							@include blog-icon("\f017")
						}
						.author{
							@include blog-icon("\f007")
						}						
				}	
			}
		}	
	}

	.pl_comment_form{
		.title {
			color: $bkgMenuHover;
			padding-bottom: 10px;
			margin-bottom: 30px;
			border-bottom: 2px solid $bkgMenu;
		}
		.submit {
			a {
				@extend .btn-style-3;
				display: inline-block;
				
			}	
		}
	}	
}
.post-name {
	a {
		&:hover {
			color: $bkgMenu;
		}
	}
}