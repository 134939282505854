#slider_row{
  margin-top: 20px;
  .col-sm-12,.col-xs-12{
    padding: 0;
    @media(max-width: 767px){
      padding: 0 15px;
    }
  }
  .ls-l.very_big_white{
    color: $colortheme1 !important;
    @media(max-width: 767px){
      letter-spacing: 0 !important;
    }
  }
}
.ls-slide .ls-bg{
  border-radius: 10px !important;
}
.ls-active, .ls-animating{
  border-radius: 10px;
}
#full-slider-wrapper{  
  padding-bottom: 15px;
  .ls-slide {    
    .ls-l.big_black {
      color: $colortheme;
      font-size: 30px;
      font-family: $fontHeading;     
      text-transform: none;
      letter-spacing: 0;
      &:after, &:before {
        display: none;
      }
    }
    .ls-l.large_text {
      text-transform: uppercase;
      font-size: 36px;
      text-shadow: none;
    }
    .ls-l.very_big_white {
      font-size: 60px;
      font-family: $fontHeading;
      text-transform: uppercase;  
      font-weight: normal;
    }
    .ls-l.small_text {
      font-size: 13px;
      color: #000;
      text-transform: none;
      text-align: left;
      @media (max-width: 380px) { 
        display: none!important;
      }
      &:hover{
        background: $colortheme1;
        color: $colorText1;
      }
    }
    .ls-l.medium_text {
      padding: 0px 30px;
      @extend .btn-style-2;      
      display: block; 
      font-size: 100% !important; 
      background: $colortheme1;   
      @media (min-width: 991px) { 
        height: 50px !important;
        line-height: 50px !important;
       // font-size: 60% !important;
      }   
      @media (max-width: 991px) { 
        min-width: 0;
        padding: 0 15px;        
        span {
          font-size: 14px!important;
        }
      }
      @media (max-width: 767px) {
        //font-size: 12px !important;
      }
      @media (max-width: 380px) { 
        padding: 0 10px!important;
        line-height: 25px !important;
        height: 25px !important;
        span {
         // font-size: 13px!important;
        }
      }
      &:hover{
        background: $colortheme;
      }
    }
     .ls-l.small_thin_grey{
         @media (max-width: 767px) { 
            //display: none !important;
            font-size: inherit;
         }
          @media (max-width: 380px) { 
            display: none !important;
            
         }
     }
    .ls-l.big_orange  {
      text-transform: none;
      background-color: transparent;
      @media(max-width: 991px) {
        font-size: 25px;
        line-height: 1;
      }
      @media(max-width: 320px) {
        font-size: 20px;
        text-align: center;
      }
    }
    &:nth-child(2) {
      .ls-l.small_text {
        @media(max-width: 1199px) {
          margin-top: 10px !important;
        }
        @media(max-width: 991px) {
          font-size: 13px;
          line-height: 1;
          width: 60%;
          white-space: normal;
        }
        @media(max-width: 767px) {
          display: none !important;
        }
      }
    }
  } 
}

.ls-nav-prev,
.ls-nav-next {
  display: none !important;
  position: absolute;
  background: #fff;
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 20px;
  line-height: 75px !important;
  z-index: 3;
  top: 50%;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(0,-50%);
  -ms-transform: translate(0,-50%);
  -o-transform: translate(0,-50%);
  transform: translate(0,-50%);
  // -webkit-box-shadow: 0 3px 3px 0px rgba(0,0,0,0.1);
  // box-shadow: 0 3px 3px 0px rgba(0,0,0,0.1);
  -webkit-transition: all .35s !important;
  -o-transition: all .35s !important;
  transition: all .35s !important;
  &:before {
    content: "";
    font-family: "FontAweSome";
    display: block;
    font-size: 48px;
    color: $colortheme1;
  }
  &:hover {
    background: $bkgMenu;
    &:before {
      color: #fff;
    }
  }
  @media(max-width: 767px) {
  &:before{
      font-size: 14px;
    }
  }
}
.ls-nav-prev {
  left: 2%;
  margin-left: 30%;
  &:before {
    content: "\f104";
  }
  @media (max-width: 991px) {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    width: 40px;
      height: 40px;
      line-height: 40px !important; 
  }
  @media(max-width: 767px) {
    width: 30px;
    height: 30px;
    line-height: 30px !important;

  }
}
.ls-nav-next {
  right: 2%;
  margin-right: 30%;
  &:before {
    content: "\f105";
  }
  @media (max-width: 991px) {
    opacity: 1;
    margin-left: 0;
    margin-right: 0; 
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
  @media(max-width: 767px) {
    width: 30px;
    height: 30px;
    line-height: 30px !important;    
  }
}
#layerslider {
  &:hover {
    .ls-nav-prev,
    .ls-nav-next  {
      opacity: 1;
      margin-left: 0;
      margin-right: 0;
    }
  }
  .ls-l.small_text {      
      &:hover{
        background: $colortheme1;
        color: $colorText1;
      }
    }
}

.ls-bottom-nav-wrapper {
  @media(max-width: 767px) {
    display: none;
  }
  .ls-bottom-slidebuttons {
    top: -40px !important;
    margin-top: 10px;
    a {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: $colortheme2;
      margin: 0 7px;
      border: 2px solid rgba(255,255,255,0.5) !important;
      &:hover,&.ls-nav-active{
        background: $colortheme1;
      }       
    }
  }
}