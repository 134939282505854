/*=============================== CSS FOR BUTTONS ======================================================*/
/*PRODUCT FUNCTIONAL BUTTON*/
.fncBtn {
	padding: 0;
	text-transform: uppercase;
	font-weight: normal;
	white-space: normal;
	position: relative;
	width: 42px;
	height: 42px;
	line-height: 42px;
	border-radius: 42px;
	background: $colortheme2;
	color: $colorText2;
	text-align: center;
	display: inline-block;
	font-size: 0;
	z-index: 100;
	min-width: auto;
	overflow: hidden;
	transition: 0.3s;
	&:before {
		color: inherit;
		width: auto;
		display: block;
	}

	&:hover {
		background:lighten($colortheme1,10%);
		color: $colorText1;
		@media (min-width: 992px) {			
			&:before {
				//padding-right: 6px;
				
			}
		}
	}
}
.fncBtn1 {	
	background: #ededed;
	border: none;
	height: 30px;
	line-height: 30px;
	width: 100%;
	&:hover{
		background: $bkgMenu;
		color: #fff;
	}
}
.functional-buttons {
	
	.addToWishlist,	.add_to_compare, .quick-view span,.ajax_add_to_cart_button{
		@extend .fncBtn;
		font-size: 0 !important;
		transition: 0.3s;
	}

	.quick-view{
		span:before {
			content: "\f002";
			font-family: "FontAweSome";
			font-size: em(12px);
			
		}
	}
	.addToWishlist ,#wishlist_button_nopop{
		font-size: 0 !important;
		&:before {
			content: "\f004";
			font-family: "FontAweSome";
			font-size: em(12px);
			
		}
		&:hover {	
			padding: 0;
			&:before {
				padding-right: 0;
				display: block;
			}	
		}
		&.checked {
			&:before {
				content: "\f21e";
				font-family: "FontAweSome";
				font-size: em(12px);
				 
			}	
		}	
	}

	.ajax_add_to_cart_button {	
		background:lighten($colortheme1,10%);;
		color: $colorText1;	
		border-color: $colortheme1;
		margin: 0 !important;		
		width: 60px;
		height: 60px;
		line-height: 60px;
		&:before {
			content: "\f07a";
			font-family: "FontAweSome"; 
			font-size: em(14px);
		}
		&:hover{
			background: $colortheme1;
		}					
	}

	.add_to_compare {
		&:before {
			content: "\f0ec";
			font-family: "FontAweSome";
			font-size: em(14px);						
		}
		&:hover {	
			padding: 0;
			&:before {
				padding-right: 0;
				display: block;
			}	
		}
		&.checked {
			&:before {
				content: "\f00c";
				font-family: "FontAweSome";
				font-size: em(14px);
			}	
		}
	}
}

/*ADD TO CART BUTTON*/
.btn-cart {
	@extend .btn-style-1;
	font-weight: normal;

	&:before {
		content: "\f07a";
		font-family: "FontAweSome";
		font-size: 18px;
		color: inherit;
		margin-right: 10px;
		display: inline-block;
	}
}

/*GENERAL BUTTON*/
.btn-style-1 {
	@include fontStyle_3();
	text-align: center;
	display: inline-block;
	text-transform: uppercase;
	background: none;
	background: $colortheme1;
	color: $colortheme2;
	font-weight: normal;
	width: auto;
	padding: 0;
	height: 40px;
	line-height: 40px;
	border-radius: 0;
	//width: 40px;
	border: 1px solid rgba(109,102,160,0.5);
	&:hover {
		color: #fff;
		background: $colortheme1;
	}
}

.btn-style-2 {
	@include fontStyle_3();
	text-align: center;
	display: inline-block;
	text-transform: uppercase;
	background: none;
	background: $bkgMenu;
	font-weight: normal;
	color: #fff;
	min-width: 100px;
	padding: 0 15px;
	height: 40px;
	line-height: 40px;
	border-radius: 5px; 
	&:hover {
		color: #fff;
		background: $colortheme;
	}
}
.btn-style-3{
	@extend .btn-style-2;
	height: 40px;
	line-height: 40px;
	border-radius: 5px;
}
.btn-style-4{
	@extend .btn-style-1;
	width: auto;
	padding: 0 20px;
	background: $colortheme6;
	font-weight: normal;
	color: $colorText2;
	border: none;
}