/*=============================== GLOBAL CSS ======================================================*/
html,body {
	font-family: $fontText;
	font-size: $baseFontSize;
	color: $colorText;

	@media (max-width: 1199px) {
		font-size: $baseFontSize*0.9;
	}

	@media (max-width: 991px) {
		font-size: $baseFontSize*0.85;
	}
}

body {
	min-width: 0;
}

a {
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
}

img {
	max-width: 100%;
	height: auto;
}

.button,
button, 
button:focus, 
button:active,
button:visited {
	outline: none;
	@extend .btn-style-3;
	border: none;	
	padding: 0 15px;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	-webkit-appearance: none;
	span {
		border: none;
		padding: 0;
	}

	[class^="icon-"] {
		display: none;
	} 
	&:hover{
		background: $colorText;
	}
}

.form-group.form-error {
	input {
		background-position: 98% 50%;
	}
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
	background: #fff;
	border: 1px solid #eee; 
	height: 35px;
	line-height: 35px;
	font-family: $fontText;
	font-size: 13px;
	color: $colorHeading;
	-webkit-appearance: none;
	border-radius: 0;

	&:focus {
		outline: none;
	}
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
input[type=number] {
	-moz-appearance:textfield;	 
}

textarea.form-control {
	border-color: #eee;
	font-family: $fontText;
	font-size: 13px;
	color: $colorHeading;
}

fieldset {
	min-width: 0;
}
/*============================================================================================================*/

/*================================================LAYOUT MODES===========================================*/
.wide-mode {
	.bc-h-boxed-mode,
	.bc-f-boxed-mode {
		max-width: 1200px;
		margin: 0 auto;
	}	
}
.boxed-mode {
	max-width: 1200px;
	margin: 0 auto;
	background: #fff;
}
/*============================================================================================================*/

/*================================================CUSTOME CONTAINER===========================================*/
.columns-container {
	padding-top: 30px;
	@media (max-width: 767px) {
		padding-top: 0;
	}
}

.container {
	// @media (max-width: 1199px) {
	// 	padding-left: 0;
	// 	padding-right: 0;
	// 	overflow: hidden;
	// }
	// @media (max-width: 767px) {
	// 	padding-left: 15px;
	// 	padding-right: 15px;
	// 	overflow: visible;
	// }
}
#columns {
	@media (max-width: 991px) {
		//padding-left: 15px;
		//padding-right: 15px;	
	}
	padding-bottom: 0;
}

.menu-opened {
	overflow: hidden;

	body.menu-opened {
		position: fixed;
		left: 0;
		top: 0;
	}
}
/*=============================================================================================================*/

/*=============================================MOBILE MENU OPEN================================================*/
.menu-opened #page {
	cursor: url(../images/close.png),pointer;
	position: relative;
	&:before {
		content:"";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: rgba(0,0,0,0.5);
		z-index: 9999;
	}
}
/*==============================================================================================================*/

/*SELECTOR*/
div.select {
	@include clearfix();
}
div.selector {
	height: 35px;
	background: none;
	border: 1px solid #eee;
	
	> span {
		background: none;
		width: 100%!important;
		height: 33px;
		position: relative;
		line-height: 33px;
		color: $colorHeading;
		font-size: 13px;
		text-align: left;

		&:after {
			content: "\f107";
			font-family: "FontAweSome";
			width: 29px;
			height: 33px;
			text-align: center;
			border-left: 1px solid #eee;
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	select {
		width: 100%;
		height: 33px;
	}

	+ span {
		margin-top: 2px;
	}
}

/*RADIO*/
div.radio {
	span {
		background: none;
		border: 1px solid #eee;
		border-radius: 50%;

		&.checked {
			position: relative;
			border-color: $bkgMenu;
			&:after {
				content:"";
				width: 5px;
				height: 5px;
				display: block;
				border-radius: 50%;
				background: $bkgMenu;
				position: absolute;
				left: 3px;
				top: 3px;
			}
		}
	}
}

/*CHECKER*/
div.checker {
	width: 10px;
	height: 10px;
	position: relative;
	min-height: 0;
	display: inline-block!important;

	+ label { 

		a {
			-webkit-transition-duration: 0s;
			-o-transition-duration: 0s;
			transition-duration: 0s;
		}

		&:hover {

			a {
				color: $bkgMenu;	
			}
		}
	}

	span {
		background: none;		
		width: 10px;
		height: 10px;
		position: relative;
		z-index: 1;
		top: 0;
		vertical-align: top;
		border: 1px solid #eee;

		&.checked {
			border-color: $bkgMenu;
			&:before {
				content: "";
				width: 4px;
				height: 4px;
				position: absolute;
				left: 50%;
				top: 50%;
				margin-left: -2px;
				margin-top: -2px;
				background: $bkgMenu;
			}
		}
	}

	input {
		width: 10px;
		height: 10px;
		min-height: 0;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
	}

	&:hover {
		span {
			border-color: $bkgMenu;
		}

		+ label {
			color: $bkgMenu;	
		}
	}
}

/*NEW LABEL*/
.new-box {
	width: em(55px);
	height: em(55px);
	border-radius: 50%;
	display: none;
	text-align: center;
	@include fontStyle_3();	
	color: #fff;
	line-height: em(55px);
	background: $bkgMenuHover;
	position: absolute;
	left: em(10px);
	top: em(15px);
	z-index: 20;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;

	&:hover {
		background: $bkgMenuHover;
		color: #fff;
	}

	@media (max-width: 991px) {
		left: 0px !important;
		top: 0px !important;
		width: 40px;
		height: 40px;
		line-height: 40px;
		font-size: 12px;
	}
	@media (max-width: 767px) {
		left: 0px !important;
		top: 0px !important;
		width: 35px;
		height: 35px;
		line-height: 35px;
		font-size: 10px;
	}
}

/*PRODUCT NAME */
.product-name {
	text-align: left;
	font-size: 14px;	
	text-transform: none;
	color: $colorHeading;
	line-height: 1;
	&:hover{
		color: $bkgMenu !important;
	}
	a {		
		font-size: 15px;
		line-height: inherit; 
		display: block;
	}
}

/*PRODUCT PRICE*/
.price.product-price, .old-price.product-price {
	color: $bkgMenu;
	@include fontStyle_3();	
	line-height: 1;
	margin: 15px 10px 0 0;	
	font-weight: normal;
	@media (max-width: 767px) {
		@include fontStyle_6();	
	}
}

.old-price.product-price {
	color: $colorText2;
	text-decoration: line-through;
}

/*PRODUCT STOCK STATUS*/
.stock-availability {
	display: block;
	color: $bkgMenu;
	font-weight: bold;
}

/*PRODUCT REDUCTION STATUS*/
.product-flags {
	.discount {
		color: $bkgMenu;
		font-weight: bold;
	}
}

/*PRICE REDUCE PERCEN*/
.price-percent-reduction {
	display: none;
}

/*PRODUCT PRICE PECENT REDUCE*/
.sale-box {
	margin-bottom: 10px;
	position: absolute;
	width: auto;
	padding: 0 5px;
	line-height: 20px;
	background: $bkgMenu;
	border: none;
	text-transform: none;
	text-align: center;
	color: #fff;
	top: em(10px);
	right: 10px;
	z-index: 20;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	font-size: 12px;
	&:hover {
		background: $bkgMenu;
	}
}

/*TIME COUNTER*/
.clock-block { 

	.clock { 
		text-align: center;
		margin-top: -40px;
	} 
	
	li {
		display: inline-block;
		width: 60px;
		height: 60px;
		background: #373737;   
		margin: 0 15px; 
		text-transform: uppercase;
		color: #fff;
		padding-top: 10px; 

		@media (max-width: 991px) {
			width: 50px;
			height: 50px;
			padding-top: 5px;
			margin: 0 5px;
		}

		span {
			display: block;  
		}
	}
}

/*SCROLL TO TOP BUTTON*/
#scroll-to-top {
	position: fixed;
	right: 10px;
	bottom: 100px;
	width: 40px;
	height: 40px;
	z-index: 400;
	font-size: 0;
	//text-align: center;
	padding-top: 10px;
	color: #eee; 
	background: #000;
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s; 
	cursor: pointer;
	padding-left: 15px;
	&:before {
		content: "\f106";
		font-family: "FontAweSome";		
		font-size: 17.5px;
	}

	&:hover {
		background: $bkgMenu;
		color: #fff;
	}
}

/*OWL SLIDER CONTROL*/
.slideNav {
	width: 42px;	
	height: 42px;
	border: 2px solid #e4e4e4;
	border-radius: 42px;
	margin-right: 0!important;
	margin-left: 6px!important;
	-webkit-transition: all 0.3s;
	-o-transition: all 0.3s;
	transition: all 0.3s;
	color: $colorText2;
	font-size: 0;
	background: #fff;

	&:hover {
		background: #e4e4e4;				
	}

	&:before {
		font-family: "FontAweSome";
		text-align: center;
		font-size: 14px;	
		line-height: 38px;
		display: block;	
		color: inherit;
		width: 100%;
		height: 100%; 
		text-align: center;
		padding: 0;
	}	
}
.slidePrev {
	&:before {
		content: "\f104";				
	}	
}
.slideNext {
	&:before {
		content: "\f105";
	}	
}
.owl-controls {

	.owl-nav {

		.owl-prev, .owl-next {
			@extend .slideNav;	
		}

		.owl-prev {
			@extend .slidePrev;
		}

		.owl-next {
			@extend .slideNext;
		}	
	}
	.owl-dots .owl-dot{
				span{
					width: 10px;
					height: 10px;
					border: 2px solid $colorText2;
					background: $colorText2;
					margin: 0 0 0 5px;
				}
				&.active{
					span{
						background:#fff;
						border: 2px solid $colortheme1;
					}
				}
			}
}

/*BLOCK TAG*/
.tags_block {

	a {
		text-transform: uppercase;
		font-size: 11px;	
		border: 1px solid $colortheme1;
		padding: 0 18px;
		float: left;
		margin-right: 10px;
		margin-bottom: 12px;
		min-height: 30px;
		padding-top: 5px; 
		margin-top: 10px;

		&:hover {
			background: $colortheme1;
			color: #fff;
		}
	}
}

/*BUTTON COMPARE SUBMIT ON CATEGORY PAGE*/
.button.button-medium.bt_compare {
	
	-webkit-box-shadow: none;
	box-shadow: none;
	opacity: 1;
	border: none;

	span {
		border: none;
	}
}

/*REVIEW STAR*/
.reviews-container {
	min-height: 16px;
	margin-bottom: 0; 
}
.comments_note {
	font-size: 0;
	.nb-comments {
		display: none;
	}
}
.star_content {
	line-height: 1;

	.star {
		float: none;
		display: inline-block; 
		margin: 0 2px;
		width: auto;
		height: auto;
		font-size: 10px;
		&.star_on {
			display: inline-block;
		}

		&:after {
			color: #ffc000 !important; 
		}
	}
}

/*PAGE HEADING*/
.page-heading {
	@extend .title-menu-home;		
	text-transform: none;
	padding: 0px 20px 20px 30px !important;
	margin-top: 0;	
	&:before{
		top: 0 !important;
		height: 30px !important;
		line-height: 30px !important;
	}
}

/*PAGE SUB-HEADING*/
.page-subheading {
	@include fontStyle_7();
	font-weight: normal;
	border: none;
	margin-bottom: 0;
	color: $colorHeading;
}

/*HISTORY PRICE*/
.history_price {
	.price {
		color: $bkgMenu;
		font-size: 16px;
		font-weight: bold;	
	}
	
}
.product_list.grid .product-container,
.product_list.list .product-container {
	&:hover {
		
		.product-image-container {
			.product_img_link:before {
				@media (min-width: 768px) {
					//@include hover-screen-show();						
				}
			}
		}
	}
	.product-image-container {
		.product_img_link:before {
			//@include hover-screen();	
		}
		.quick-view-wrapper-mobile {
			display: none;
		}
		.quick-view {			
		}
	}
}

/*==========================================TAB MOBILE==================================================*/
.type-tab {
	@media (max-width: 767px) {
		overflow: visible !important;
		position: relative !important;
		.tab-toogle--mobile {
			width: 47px;
			height: 47px;
			background: none;
			line-height: 47px;
			text-align: center;
			cursor: pointer;
			position: absolute;
			right: 0px;
			top: 0;
			&:before {
				content: "\f205";
				display: block;
				font-family: "FontAweSome";
				font-size: 14px;					
				color: $colortheme1;
			}
			&.off {
				&:before{
					content: "\f204";
				}
			}

			+ .nav-tabs {
				@media (max-width: 767px) {
					position: absolute !important;
					display: none;
					top: 50px !important;
					right: 0;
					width: 50%;
					z-index: 300;
					background: none;
					padding-top: 0 !important;

					li {
						display: block;
						width: 100%;
						text-align: right;
						margin: 0 !important;
						a {							
							padding: 0 15px !important;
							font-family: $fontHeading;							
							border-top: none !important;
						}
						&.active, &:hover {
							background: $bkgMenu;	
							border-radius:5px;
							a {
								background: transparent;
								font-weight: normal !important;
							}
						}
					}
				}
				@media (max-width: 320px) {
					width: 100%;
				}	
			}
		}
	}
}
.bc-filterproduct {
	.tab-toogle--mobile {
		top: 80px;
		right: 80px;
		background: none;
		width: 30px;
		height: 30px;
		line-height: 30px;
		&:before {
			color: $bkgMenuHover;
		}
	}	
}
.bc-bestsellerwithcategory {
	.type-tab{
		.tab-toogle--mobile {
			@media (max-width: 767px) {
				right: -1px;
				+ .nav-tabs {
					right: -1px;

				}	
			}
			
		}
	}
}
/*======================================================================================================*/

.bc-filter-product:not(.tab-pane) .product-container,
.product_list.grid .product-container
 {
	background: #fff;
	padding: 0;
	position: relative;	
	-webkit-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	z-index: auto;
	margin: 0 5px 10px;
	@media (min-width: 768px) {
		&:hover {
			z-index: 200;
			.product-image-container {			
				.product_img_link:before {
					@include hover-screen-show();	
					transform: scale(1)	;
				}			
			}
			
			.quick-view {
				
			}
		}	
	}
	.product-image-container {
		border: none !important;
		padding: 0;
		margin-bottom: 0;
		position: relative;	
		text-align: center;
		.product_img_link{
			display: block;
			position: relative;
			overflow: hidden;
			transition: 0.4s;
			img{
				transition: 0.4s;
				width: 100%;
			}
			
		}
		.product_img_link:before { 
		 	@include hover-screen();
		 	transform: scale(1)	;
			background: rgba(0,0,0,0.5);	
		}
		.quick-view-wrapper-mobile {
			display: none;
		}
		.quick-view {			
		}
		.button-container{
			.functional-buttons{
				display: block !important;
			}
			.compare,.wishlist,.quick-view{
				position: absolute;
				left: 10px;
				top: 10px;
				&.wishlist{
					top: 60px;
				}
				&.quick-view{
					top: 110px;
				}
			}
			.ajax_add_to_cart_button{
				position: absolute;
				right: 10px;
				bottom: -30px;
				z-index: 100;
			}
		}
	}
	.right-block {
		padding: 30px 20px;
		text-align: left;
		position: relative;		
		width: 100%;
		background: #fff;
		.product-desc, 
		.availability, 
		.lnk_view, 
		.product-flags,
		.price-percent-reduction {
			display: none;
		}

		h5 {
			margin: 0;			
		}

		.product-name {
			padding: 0 0 3px;
			margin-bottom: 0;
			-ms-text-overflow: ellipsis; 
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			display: block;
		}

		.content_price {
			margin: 5px 0;
		}

		.button-container {
			display: none !important;
		}
	}
	&:hover{
				img{
					//transform: scale(1.1,1.1);
				}
			}
}

/*TOP BANNER*/
#header {
	#banner_block_popup {
		margin-bottom: 0;
		text-align: center;
		padding: 10px 0;
		position: relative;
		.text_content {
			color: #fff;
			font-weight: bold;
			@include fontStyle_3();
			text-transform: uppercase;
			padding: 0 30px;
			padding-right: 70px;
			p {
				margin-bottom: 0;
			}
		}
		.ads-bar-link {
			display: inline-block;
			color: $bkgMenu;
			text-decoration: underline;
		}
		.close {
			font-size: 0;
			position: absolute;
			border: none;
			position: absolute;
			right: 0;
			top: 4px;
			width: 30px;
			height: 30px;
			line-height: 30px;
			background: url('../images/close.png') no-repeat center;
			span {
				padding: 0;
				border: none;
				display: none;
			}
		}
	}
	
}

/*COOKIE LAW*/
#cookie-popup {
	background: rgba(0,0,0,0.8); 
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 10000;
	#cookie_block_popup {
		margin-bottom: 0;
		padding: 20px 0 10px;
		@media(max-width: 767px) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}
	.text_content {
		width: 60%;
		float: left;
		@include fontStyle_3();
		color: #fff;
		@media (max-width: 370px) {
			width: 100%;
		}
	}
	.button-container {
		width: 40%;
		float: right;
		text-align: right;
		padding-top: 5px;
		@media (max-width: 370px) {
			width: 100%;
			text-align: left;
		}
	}
	.btn {
		@extend .btn-style-1;		
		padding: 0 15px;
		margin: 0 5px 10px;
		border: none;
		text-shadow: none;
		cursor: pointer;
		span {
			background: none;
			border: none;
			padding: 0;
			font-size: 12px;
		}
	}
}
.content-manufacture-block{
	@media (min-width: 767px) {
		padding:0 15px;
	}
}

/*STICKY MENU*/
@media (min-width: 991px) {
.menu-sticky {
	&.bc-stickymenu-fixed {
		position: fixed;
		left: 0;
		right: 0;
		top: 0;
		z-index: 95;
		background: $colortheme1;
		box-shadow: 0 1px 5px rgba(0,0,0,0.20);
		.container{
			position: relative;
			#search_block_top{
				right: 0;
			}
		}
	}	
}
}

/*TOOLTIP*/
.tooltip-inner {
	border-radius: 0;
	font-family: $fontText;
}

/*PRELOADER ZOOM*/
.fancybox-overlay,.layer_cart_overlay{
	background: rgba(255, 255, 255, 0.5);
}
#layer_cart{
	box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
#fancybox-loading,.zoomPreload,.product_list > p {
	@include preload();
	font-size: 0;
	opacity: 1;
	border: none;
	> div{
		background: none;
	}
}
.product_list{
	position: relative;
	> p{
	position: absolute;
	left: 50%;
	top: -50px;
	img{
		width: 0;
		font-size: 0;
	}
}
}
.block .list-block li a:hover{
	color: $colortheme1;
	&:before{
		color: $colortheme1;
	}
}
.img-responsive{
	display: inline-block;
}
.ajax_block_product .product-container {
	border: 2px solid $bordercolor;
	border-radius: 0 0 10px 10px;
	.left-block{
		position: relative;
		.button-container{
			
			.quick-view, .wishlist, .compare{
				@include fade-button();
				position: relative;
				float: none;	
				@media (max-width: 767px) {	
					display: inline-block;
				}						    	
			}
			.quick-view{
				animation-delay: 0.2s;
				@media(max-width: 767px){
					display: none !important;
				}
			}
			.wishlist{
				animation-delay: 0.3s;
			}
			.compare{
				animation-delay: 0.4s;
			}
		}
	}	
	&:hover{
		.left-block .button-container{
			.quick-view, .wishlist, .compare{
				display: inline-block;
				z-index: 100;
			}
		}
	}
}		
@media (max-width: 767px){
	#order-detail-content #cart_summary td.cart_product{
		width: 30%;
	}
	#order-detail-content #cart_summary tbody td.cart_description{
		width: 70%;
	}
	#order-detail-content #cart_summary td{
		padding: 15px 30px 15px 15px;
	}
	#order-detail-content #cart_summary td.cart_unit{
		text-align: left;
	}
	#order-detail-content #cart_summary td:before{
		margin-bottom: 5px;
	}
}
.unvisible {
    display: none ;
}
.search-block-box{
	position: fixed;
    overflow: hidden;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9990;
    display: none;
    padding: 0;
    margin: 0;
    &.search-open{
    	.search-box{
    		background: #fbfbfb;
		    border-left: 1px solid #ededed;
		    bottom: 0;
		    display: block;
		    height: 100%;
		    overflow-x: hidden;
		    overflow-y: scroll;
		    padding: 50px 30px 20px;
		    position: fixed;
		    right: -17px;
		    top: 0;
		    visibility: visible;
		    width: 300px;
		    z-index: 1000;
		    transition-duration: 1s;
		    > h1{
		    	    margin: 0 0 20px;
				    padding: 0 0 7px;
				    text-transform: uppercase;
				    letter-spacing: 1px;
				    text-align: center;
				    color: #000000;
				    font-size: 20px;
				    font-weight: 400;
				    position: relative;
		    }
		    > p{
		    	    position: relative;
				    margin: 0;
				    padding: 0;
				    display: block;
				    width: 100%;
		    }
		    .search_button {
		    	position: absolute;
			    top: 0;
			    right: 0;
			    background: none;
			    border: none;
			    padding: 0 10px;
			    min-width: 0;
			    &:before{
			    	    content: "\f002";
					    font-family: $fonticont;
					    font-size: 14px;
					    color: #333333;
			    }
			    span {
			    	display: none;
			    }
		    }
		    .search_query {
		    	background: #ffffff;
			    border: 1px solid #ededed;
			    padding: 10px;
			    height: 40px;
			    color: #333333;
			    width: 100%;
		    }
    	}
    }
}
.search-open{
	overflow-y: hidden;
	#page{
		    z-index: 9991;
		    position: relative;
		    -webkit-transform: translate(-280px,0);
		    -ms-transform: translate(-280px,0);
		    -o-transform: translate(-280px,0);
		    transform: translate(-280px,0);
		    -webkit-transition: opacity(1s),0s ease;
		    -moz-transition: opacity(1s),0s ease;
		    -o-transition: opacity(1s),0s ease;
		    transition: opacity(1s),0s ease;
		    -webkit-transition-duration: 1s;
		    -moz-transition-duration: 1s;
		    -o-transition-duration: 1s;
		    transition-duration: 1s;
		&:before{
			    background: rgba(0,0,0,0.25);
			    content: '';
			    height: 100%;
			    width: 100%;
			    z-index: 9991;
			    top: 0;
			    left: 0;
			    position: absolute;
			    cursor: pointer;
		}
	}	
}
#search_block_box{
#show_result_2 {
				position: relative;
				background: none;
				width: 100%;				
				padding: 0;
				margin: 60px 0 0;
				color: $bkgMenuHover;
				@include clearfix();
				@media (max-width: 767px) {
					width: calc(100vw - 30px);
					margin-left: -50px;
				}
				.result {
					width: 100%;
					padding: 15px 0;
					h1{
						font-size: 18px;
						font-weight: bold;
					}
					.product-description{
						display: none;
					}
				}
				#closedform {
					position: absolute;
					right: 0px;
					top: 0px;
					width: 30px;
					height: 30px;
					text-align: center;
					line-height: 30px;
					cursor: pointer;
					font-size: 14px;
					font-family: $fontHeading;
					border: 1px solid #dfdfdf;
					&:hover {
						color: #fff;
						background: $colortheme1;
					}
				}
				h1 {
					@include fontStyle_6();
					text-transform: uppercase;
					margin: 0;
					padding: 0 0 20px;
				}
				#search-products_block {
					li {
						border-bottom: 1px dotted grey;
						.products-block-image {
							float: left;
							margin-right: 10px;
						}
						h5 {
							margin-bottom: 15px;
						}
						.product-name {
							color: $colortheme;

						}
						.price-box{
							.price {
								color: $bkgMenu;
								font-size: em(14px);
								font-family: $fontHeading;
							}
						}
						&:last-child {
							border: none;
							margin-bottom: 0;
							padding-bottom: 0;
						}
					}	
				}
			}
		}