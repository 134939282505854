/*=====================================CSS FOR PRODUCT DETAIL PAGE==================================================*/
.product {
	.top-hr {
		display: none;
	}
	.pb-left-column {
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 100%;
		}
		#image-block {
			border: none;
			padding: 0;
			width: 100%;
			.zoomPad, img {
				width: 100%;
				height: auto;
			}
		}

		#views_block {
			position: relative;

			.view_scroll_spacer {
				margin: 0;
			}
			
			#thumbs_list  {
				width: 100%;
				
				li {
					text-align: center;
					float: left;
					height: auto;
					border: none;
					padding: 0 5px;
					margin: 0;
					width: 92px;
					@media (max-width: 1199px) {
						width: 101px;	
					}
					@media (max-width: 991px) {
						width: 115px;
					}
					@media (max-width: 767px) {
						img {
							width: 100%;
							display: inline-block;
						}
					}
					a{
						border: 1px solid transparent;
						display: block;
						@include clearfix();
						&.shown, &:hover {
							border-color: $bkgMenu;
						}
					}
					img {
						border: none;
						float: none;
						width: 100%;
						height: auto;
					}
				}
				ul#thumbs_list_frame {
					height: auto;
					
				}
			}

			#view_scroll_left, #view_scroll_right {
				position: absolute;
				top: 0;
				margin: 0 !important;
				z-index: 20;
			}

			#view_scroll_left {
				left: 0;			
				@extend .slideNav;
				@extend .slidePrev;
				@media (max-width: 767px) {
					left: 0px;
				}
			}

			#view_scroll_right { 
				right: 0;
				@extend .slideNav;
				@extend .slideNext;
				@media (max-width: 767px) {
					right: 0px;
				}
			}
		}


	}
	.pb-center-column {
		padding-left: 85px;
		@media (max-width: 991px) {
			width: 50%;
			padding-left: 40px;
		}
		@media (max-width: 767px) {
			width: 100%;
			padding-left: 15px;
			padding-right: 15px;
		}

		h1 {
			font-size: em(24px);
			margin-top: 0;
		}
		div.selector {
			height: 40px;
			> span, select {
				width: 100% !important;
				max-width: 100% !important;
				height: 40px;
				&:after {
					height: 40px;
					line-height: 40px;
					width: 40px;
				}
			}
			> span {
				padding-right: 55px;
				line-height: 40px;
			}
			@media (max-width: 767px) {
				width: 100% !important;
				
			}
		}
		.content_prices {
			padding-left: 0;
			padding-right: 0;
			padding-top: 0;
			position: relative;

			.our_price_display {
				display: inline-block;
				.price {
					font-family: $fontHeading;
					font-size: em(24px);
					font-weight: normal;
					margin-right: 15px;
				}
			}

			.price {
				font-family: $fontHeading;
				font-size: em(24px);
				font-weight: normal;
			}

			#reduction_percent {
				@extend .price-percent-reduction;
				line-height: 24px;
				right: 0;
				top: 0;
				display: inline-block;
				font-family: $fontHeading;
				font-weight: normal;
				display: none!important;
			}
		}

		#availability_statut {
			#availability_value {
				margin-left: 0;
				background: none;
				border: none;
				padding-left: 0;
				@extend .stock-availability;
			}
		}

		#product_comments_block_extra {
			margin-top: 0;
			padding: 0;
			background: none;
			border: none;

			.comments_advices {

				.reviews, .open-comment-form {
					@extend .btn-style-3;
					margin-right: 10px;					
					text-transform: uppercase;					
					@include fontStyle_2();
				}
				
				li {
					&:nth-child(2) {
						margin-right: 0;	
					}
					@media (max-width: 991px) {
						display: inline-block;
					}
				}
			}
		}

		#short_description_block {
			padding-bottom: 10px;
			margin-bottom: 10px;
			border-bottom: 1px solid #eee;
			display: inline-block;
			width: 100%;
			#short_description_content {
				padding-bottom: 0;
			}
		}

		.box-info-product {
			background: none;
			border: none;

			.box-cart-bottom {
				margin-bottom: 20px;
				margin-top: -65px;
				float: right;
				@media (max-width: 1199px){
					margin-top: 0;
					clear: both;
					float: none;
				}
				>div {
					display: inline-block;
					float: left;
				}
			}

			.product_attributes, .box-cart-bottom {
				-webkit-box-shadow: none;
				box-shadow: none;
				padding: 0;
				@include clearfix();

				label {
					text-align: left;
					text-transform: uppercase;
					@include fontStyle_3();
					font-weight: normal;
					margin-bottom: 20px;
					float: left;
					width: 100%;
				}

				fieldset {
					padding-bottom: 30px;
				}
			}

			#color_to_pick_list {

				li {
					width: 60px;
					height: 40px;
					border: 1px solid #eee;

					a {
						width: 100%;
						height: 100%;
					}

					&.selected {
						border: 2px solid $bkgMenu;
					}
				}
			}

			#quantity_wanted_p {
				float: left;
				position: relative;
				margin-bottom: 0;
				label {
					display: none;
				}

				.btn {
					margin-left: 0;
					border-color: #eee;
					position: absolute;
					width: 25px;
					height: 22px;
					border: none;
					
					span {
						border: none;
						background: #dddddd;
						width: 100%;
						height: 100%;
						-webkit-transition: background .3s;
						-o-transition: background .3s;
						transition: background .3s;
						i {
							color: #676767;
							font-size: 14px;
						}
					}
					&.button-minus {
						border-right: none;	
						right: 0;
						bottom: 0;	
						i:before {
							content:"\f0d7";
						}				
					}
					&.button-plus {
						border-left: none;
						right: 0;
						bottom: 22px;
						i:before {
							content: "\f0d8";
						}
					}
					&:hover {
						color: #fff;
						span {
							background: $bkgMenuHover;
							i {
								color: #fff;
							}
						}
					}
				}
				input {
					height: 45px;
					padding-right: 30px;
					background: #eee;
					text-align: center;
					line-height: 45px;
					border: 1px solid #e5e9e9 !important;
					@include fontStyle_6();
					color: $colorHeading;
				}
			}
			#add_to_cart {
				padding-left: 0;
				padding-right: 0;
				float: left;
				button {
					@extend .btn-cart;
					border: none;
					color: #fff;
					padding: 0 20px;
					border-radius: 10px;
					height: 45px;
					line-height: 45px;
					margin-right: 5px;
					&:before {
						position: relative;
						width: auto;
						height: auto;
						line-height: 1;
						border: none;
						text-shadow: none;
					}
					&:after {
						display: none;
					}
					&:hover{
						background: $colorText;
					}
				}

			}

			.functional-buttons {
				float: right;
				min-width: 90px;
				white-space: nowrap;
				font-size: 0;
				padding-top: 13px;				
				.buttons_bottom_block, .compare {
					padding: 0;
					margin-bottom: 0;
					display: inline-block;
					float: left;
				}
				#wishlist_button_nopop, .add_to_compare {
					@extend .fncBtn;
					float: none!important; 
					border: 1px solid $colortheme3;

				}

				#wishlist_button_nopop { 					
					&:before {
						content: "\f004";
						font-family: "FontAweSome";
						font-size: 12px;
						line-height: 50px;	
						background: none;
						height: auto;
					}	
					&:hover{
						padding: 0;
					}
				}
				
				#wishlist_button_nopop, .add_to_compare {
					@extend .fncBtn;
					float: left;					
					margin-right: 5px;
					width: 45px;
					height: 45px;
					&:before {
						margin-right: 0;
						font-size: 15.5px;
						line-height: 45px;
					}
					&:hover {
						
						&:before {
							padding: 0;
						}
					}
				}
				#wishlist_button_nopop{
										
				}

			} 
		}

		.socialsharing_product {
			clear: both;
			&:before {
				//content: "Share with Friends";
				text-transform: uppercase;
				@include fontStyle_3();
				color: $colorHeading;
				display: inline-block;
				margin-right: 15px;
				@media (max-width: 767px) {
					margin-right: 10px;
				}
			}
			

			button {
				background: none;
				font-size: 0;
				width: 50px;
				height: 50px;
				margin-right: 0px;
				text-align: center;
				padding: 0;
				min-width: 0;
				vertical-align: middle;
				i {
					display: inline-block;
					font-size: 14px;
					line-height: 38px;
					color: $colorHeading;
				}
				&:hover {
					i {
						color: $bkgMenu;
					}
				}
				/*&:hover {
					&.btn-twitter {
						border-color: #00aaf0;
						background: #00aaf0;
					}
					&.btn-facebook {
						border-color: #435f9f;
						background: #435f9f;
					}
					&.btn-google-plus {
						border-color: #e04b34;
						background: #e04b34;
					}
					&.btn-pinterest {
						border-color: #ce1f21;
						background: #ce1f21;
					}
				}*/
				@media (max-width: 1199px) {
					width: 30px;
					height: 30px;
					i {
						line-height: 28px;
					}
				}
				@media (max-width: 767px) {
					width: 25px;
					height: 25px;
					i {
						line-height: 23px;
					}
				}	
			}
		}
	}
	.pb-right-column {
		@media (max-width: 991px) {
			display: none;
		}
		.product-custom-content {
			h3 {
				text-transform: uppercase;
				color: $colorHeading;
				margin-top: 0;
				margin-bottom: 30px;
			}
			.feature {
				@include clearfix();
				margin-bottom: 20px;
				.icon {
					width: 55px;
					height: 55px;
					margin-right: 10px;
					float: left;
					line-height: 55px;
					border-radius: 55px;
					text-align: center;
					background-color: $bkgMenu;
					color: #fff;
					font-size: 18px;
				}
				.custom-title {
					text-transform: uppercase;
					color: $colorHeading;
					font-family: $fontHeading;
					margin-top: 5px;
					margin-bottom: 5px;
				}
			}	
		}
	}

	#more_info_block {
		@include clearfix();
		border-bottom: 1px solid $bordercolor;
		border-top: 1px solid $bordercolor;
		margin-bottom: 20px;
		#more_info_tabs {
			@media (min-width: 767px) {
				width: 25%;
				float: left;
				z-index: 2;
				position: relative;
				margin: 0;
				&:after{
					border-right: 1px solid $bordercolor;
				    position: absolute;
				    right: 0;
				    top: 0;
				    height: 100%;
				    content: '.';
				    text-indent: -5000px;
				    display: block;
				    z-index: 1;
				}
			}
			@media (max-width: 767px) {
				// border: none;
				// margin-top: -40px;
			}
			li {
				margin: 0;
				border-bottom: 1px solid $bordercolor;
				z-index: 2;
				a {
					@include fontStyle_3();
					text-transform: uppercase;				
					padding: 15px 5px 15px 10px;
					display: block;
					color: $bkgMenuHover;
					transition: 0.3s;
					position: relative;
					background: none;
					&.selected, &:hover {
						color: $colortheme1;
					}
					@media (min-width: 767px) {
						border-left: 2px solid $bordercolor;
						&.selected{
							border-left: 2px solid $colortheme1;
							background: $colortheme2;
							z-index: 2;
						}
					}
				}
				@media (max-width: 767px) {
					display: block;
					float: none;
					text-align: center;
					margin-bottom: 5px;
					a {
						display: inline-block;
						padding: 10px;
						border-bottom: 3px solid transparent;
						font-size: 16px;
						margin-right: 0;
					}
				}
			}
		}
		#more_info_sheets{
			@media (min-width: 767px) {
				float: left;
				width: 75%;
				margin-top: 35px;
				z-index: 1;
				position: relative;
				> div{
					padding-left: 30px;
				}
				&:before{
					content: '.';
				    text-indent: -5000px;
				    border-left: 1px solid $bordercolor;
				    position: absolute;
				    left: -1px;
				    top: 0;
				    z-index: 1;
				    height: 100%;
				}
			}
		}
		.content-tab {
			padding-left: 0;
			padding-top: 15px; 
		}

		.table-data-sheet {
			border-color: #eee;

			tr,td {
				border-color: #eee;
			}

			tr {
				
				&:first-child {
					
				}
			}

			td {
				&:first-child {
					text-transform:uppercase;
				}
			}
		}

		#product_comments_block_tab {
			
			#new_comment_tab_btn {
				@extend .btn-style-2;
				border: none;
				height: 40px;
				padding: 0 15px;
				line-height: 40px;
				display: inline-block;
				font-size: 12px;
				font-weight: normal;
				border-radius: 0;
				&:hover {
					span {
						background: none;
					}
				}

				span {
					border: none;
					padding: 0;
				}
			}	
		}
	}
}

.product.content_only {
	.primary_block.row {
		margin-left: 0;
		margin-right: 0;
		padding-top: 30px;
		padding-left: 15px;
		padding-right: 15px;
	}
	#scroll-to-top {
		display: none;
	}
	.pb-left-column {
		padding-bottom: 30px;
		@media (max-width: 1199px) {
			width: 40%;
		}
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			padding-left: 0;
			padding-right: 15px;
			width: 50%;
			float: left;
		}

		#views_block {
			#thumbs_list {
				li {
					@media(max-width: 1199px) {
						width: 98px;
					}
					@media(max-width: 991px) {
						width: 107px;
					}
				}
			}
			#view_scroll_right {
				@media(max-width: 1199px) {
					top: 0;
					right: 0;
				}
			}
			#view_scroll_left {
				@media(max-width: 1199px) {
					top: 0;
					left: 0;
				}
			}
		}
		
		.resetimg {
			margin-top: 10px;
		}
	}
	.pb-center-column {
		padding-left: 15px;
		@media (max-width: 1199px) {
			width: 60%;
		}
		@media (max-width: 991px) {
			width: 50%;
		}
		@media (max-width: 767px) {
			width: 50%;
			padding-right: 0;
			padding-left: 15px;
			float: left;
		}
		.box-info-product {
			
			.functional-buttons {
				@media (max-width: 320px) {
					float: left;
				}
			}

			.box-cart-bottom {
				@media(max-width: 1199px) {
					margin-top: -65px;
					float: right;
				}	
			}
				
		}	
	}
	.pb-right-column {
		@media (max-width: 1199px) {
			display: none;
		}
	}
}

#index .product {
	display: none;
}
/*PRODUCT RELATED SECTION*/
.page-product-box {
	padding-top: 30px;
	overflow: hidden;
	.page-product-heading {
		background: none;
		border: none;
		padding: 0;
		@include fontStyle_6();
		font-weight: normal;
		border-bottom: 3px solid $bkgMenu;
		padding-bottom: 10px;
		display: inline-block;
	}

	.block_content {
		margin-left: -15px;
		margin-right: -15px;
	}

	.owl-controls {
		position: absolute;
		right: 15px;
		top: 0;
		margin-top: -50px;

	}

	.item {
		width: 100%;
		padding: 0 15px;
		text-align: center;
		.product_desc{
			position: relative;
			overflow: hidden;
			a{
			}
		}
		.product-name {
			text-align: center;			
		}

		.price {
			color: $bkgMenu;
			font-family: $fontHeading;			
		}

		.product-image {
			border: none;
		}

		.ajax_add_to_cart_button {
			margin-top: 20px;
			margin-bottom: 20px;
			border-radius: 30px;
			&:before {
				content: "\f07a";
				font-family: "FontAweSome";
				color: #fff;
				font-size: 14px;
				display: inline-block;
				margin-right: 10px;
			}
			span {
				padding: 0;   
			}
		}	
	}
} 
