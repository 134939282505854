@charset "UTF-8";
/* Open Sans */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300,300italic,400italic,600,600italic,700,700italic,800,800italic&subset=latin,vietnamese,latin-ext,cyrillic-ext,cyrillic);
/* Monserrat */
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
/*Playfair Display*/
@import url(https://fonts.googleapis.com/css?family=Playfair+Display:400,400italic,700,700italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Playball&subset=latin,latin-ext);
/*================ Global | Sass Mixins ================*/
/*============================================================================
  Dependency-free breakpoint mixin
    - http://blog.grayghostvisuals.com/sass/sass-media-query-mixin/
==============================================================================*/
/*============================================================================
  THEME MIXINS
==============================================================================*/
/*HOVER SCREEN*/
/*QUICK VIEW*/
/*============================================================================
  Accent text
==============================================================================*/
@keyframes cssload-spin {
  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-spin {
  to {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-spin {
  to {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-spin {
  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes cssload-blur1 {
  to {
    transform: rotate(-30deg);
  }
}

@-o-keyframes cssload-blur1 {
  to {
    -o-transform: rotate(-30deg);
  }
}

@-ms-keyframes cssload-blur1 {
  to {
    -ms-transform: rotate(-30deg);
  }
}

@-webkit-keyframes cssload-blur1 {
  to {
    -webkit-transform: rotate(-30deg);
  }
}

@-moz-keyframes cssload-blur1 {
  to {
    -moz-transform: rotate(-30deg);
  }
}

@keyframes cssload-blur2 {
  to {
    transform: rotate(-15deg);
  }
}

@-o-keyframes cssload-blur2 {
  to {
    -o-transform: rotate(-15deg);
  }
}

@-ms-keyframes cssload-blur2 {
  to {
    -ms-transform: rotate(-15deg);
  }
}

@-webkit-keyframes cssload-blur2 {
  to {
    -webkit-transform: rotate(-15deg);
  }
}

@-moz-keyframes cssload-blur2 {
  to {
    -moz-transform: rotate(-15deg);
  }
}

/*=============================== CSS FOR BUTTONS ======================================================*/
/*PRODUCT FUNCTIONAL BUTTON*/
.fncBtn, .functional-buttons .addToWishlist, .functional-buttons .add_to_compare, .functional-buttons .quick-view span, .functional-buttons .ajax_add_to_cart_button, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span, .columns-container ul.product_list.list > li .product-container .ajax_add_to_cart_button, .product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare {
  padding: 0;
  text-transform: uppercase;
  font-weight: normal;
  white-space: normal;
  position: relative;
  width: 42px;
  height: 42px;
  line-height: 42px;
  border-radius: 42px;
  background: #fff;
  color: #888888;
  text-align: center;
  display: inline-block;
  font-size: 0;
  z-index: 100;
  min-width: auto;
  overflow: hidden;
  transition: 0.3s;
}

.fncBtn:before, .functional-buttons .addToWishlist:before, .functional-buttons .add_to_compare:before, .functional-buttons .quick-view span:before, .functional-buttons .ajax_add_to_cart_button:before, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span:before, .columns-container ul.product_list.list > li .product-container .ajax_add_to_cart_button:before, .product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:before, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare:before {
  color: inherit;
  width: auto;
  display: block;
}

.fncBtn:hover, .functional-buttons .addToWishlist:hover, .functional-buttons .add_to_compare:hover, .functional-buttons .quick-view span:hover, .functional-buttons .ajax_add_to_cart_button:hover, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span:hover, .columns-container ul.product_list.list > li .product-container .ajax_add_to_cart_button:hover, .product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:hover, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare:hover {
  background: #c9d523;
  color: #ffffff;
}

.fncBtn1 {
  background: #ededed;
  border: none;
  height: 30px;
  line-height: 30px;
  width: 100%;
}

.fncBtn1:hover {
  background: #a0a91c;
  color: #fff;
}

.functional-buttons .addToWishlist, .functional-buttons .add_to_compare, .functional-buttons .quick-view span, .functional-buttons .ajax_add_to_cart_button {
  font-size: 0 !important;
  transition: 0.3s;
}

.functional-buttons .quick-view span:before {
  content: "\f002";
  font-family: "FontAweSome";
  font-size: 0.8571428571rem;
}

.functional-buttons .addToWishlist, .functional-buttons #wishlist_button_nopop {
  font-size: 0 !important;
}

.functional-buttons .addToWishlist:before, .functional-buttons #wishlist_button_nopop:before {
  content: "\f004";
  font-family: "FontAweSome";
  font-size: 0.8571428571rem;
}

.functional-buttons .addToWishlist:hover, .functional-buttons #wishlist_button_nopop:hover {
  padding: 0;
}

.functional-buttons .addToWishlist:hover:before, .functional-buttons #wishlist_button_nopop:hover:before {
  padding-right: 0;
  display: block;
}

.functional-buttons .addToWishlist.checked:before, .functional-buttons #wishlist_button_nopop.checked:before {
  content: "\f21e";
  font-family: "FontAweSome";
  font-size: 0.8571428571rem;
}

.functional-buttons .ajax_add_to_cart_button {
  background: #c9d523;
  color: #ffffff;
  border-color: #a0a91c;
  margin: 0 !important;
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.functional-buttons .ajax_add_to_cart_button:before {
  content: "\f07a";
  font-family: "FontAweSome";
  font-size: 1rem;
}

.functional-buttons .ajax_add_to_cart_button:hover {
  background: #a0a91c;
}

.functional-buttons .add_to_compare:before {
  content: "\f0ec";
  font-family: "FontAweSome";
  font-size: 1rem;
}

.functional-buttons .add_to_compare:hover {
  padding: 0;
}

.functional-buttons .add_to_compare:hover:before {
  padding-right: 0;
  display: block;
}

.functional-buttons .add_to_compare.checked:before {
  content: "\f00c";
  font-family: "FontAweSome";
  font-size: 1rem;
}

/*ADD TO CART BUTTON*/
.btn-cart, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button, .product .pb-center-column .box-info-product #add_to_cart button {
  font-weight: normal;
}

.btn-cart:before, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button:before, .product .pb-center-column .box-info-product #add_to_cart button:before {
  content: "\f07a";
  font-family: "FontAweSome";
  font-size: 18px;
  color: inherit;
  margin-right: 10px;
  display: inline-block;
}

/*GENERAL BUTTON*/
.btn-style-1, .btn-cart, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button, .product .pb-center-column .box-info-product #add_to_cart button, .btn-style-4, #cookie-popup .btn, #authentication #login_form button#SubmitLogin, #address .footer_links .btn, #password .footer_links .button {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  background: none;
  background: #a0a91c;
  color: #fff;
  font-weight: normal;
  width: auto;
  padding: 0;
  height: 40px;
  line-height: 40px;
  border-radius: 0;
  border: 1px solid rgba(109, 102, 160, 0.5);
}

.btn-style-1:hover, .btn-cart:hover, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button:hover, .product .pb-center-column .box-info-product #add_to_cart button:hover, .btn-style-4:hover, #cookie-popup .btn:hover, #authentication #login_form button#SubmitLogin:hover, #address .footer_links .btn:hover, #password .footer_links .button:hover {
  color: #fff;
  background: #a0a91c;
}

.btn-style-2, .btn-style-3, .button,
button,
button:focus,
button:active,
button:visited, header#header .cart_block .cart-buttons #button_order_cart, .bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button, .bc-banner-top .banner-shopnow--btn, .bc-filterproduct .type-tab .nav-tabs li a, .product .pb-center-column #product_comments_block_extra .comments_advices .reviews, .product .pb-center-column #product_comments_block_extra .comments_advices .open-comment-form, .cart_navigation .standard-checkout, .cart_navigation [type="submit"], .cart_navigation .button-exclusive, #products-comparison #product_comparison .button-container a.lnk_view, #contact .contact-form-box div.uploader span.action, .module-bcblog-post .pl_comment_form .submit a, .bc-top-footer #newsletter_block_left .news_content .block_content .form-group input[type="text"], .newsletter-popup .block_content input[type="submit"], #new_comment_form #new_comment_form_footer #submitNewMessage, #layer_cart .layer_cart_cart .button-container .continue, #layer_cart .layer_cart_cart .button-container a.btn, #full-slider-wrapper .ls-slide .ls-l.medium_text, .product #more_info_block #product_comments_block_tab #new_comment_tab_btn, #authentication button#SubmitCreate {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
  background: none;
  background: #a0a91c;
  font-weight: normal;
  color: #fff;
  min-width: 100px;
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

.btn-style-2:hover, .btn-style-3:hover, .button:hover,
button:hover, header#header .cart_block .cart-buttons #button_order_cart:hover, .bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button:hover, .bc-banner-top .banner-shopnow--btn:hover, .bc-filterproduct .type-tab .nav-tabs li a:hover, .product .pb-center-column #product_comments_block_extra .comments_advices .reviews:hover, .product .pb-center-column #product_comments_block_extra .comments_advices .open-comment-form:hover, .cart_navigation .standard-checkout:hover, .cart_navigation [type="submit"]:hover, .cart_navigation .button-exclusive:hover, #products-comparison #product_comparison .button-container a.lnk_view:hover, #contact .contact-form-box div.uploader span.action:hover, .module-bcblog-post .pl_comment_form .submit a:hover, .bc-top-footer #newsletter_block_left .news_content .block_content .form-group input[type="text"]:hover, .newsletter-popup .block_content input[type="submit"]:hover, #new_comment_form #new_comment_form_footer #submitNewMessage:hover, #layer_cart .layer_cart_cart .button-container .continue:hover, #layer_cart .layer_cart_cart .button-container a.btn:hover, #full-slider-wrapper .ls-slide .ls-l.medium_text:hover, .product #more_info_block #product_comments_block_tab #new_comment_tab_btn:hover, #authentication button#SubmitCreate:hover {
  color: #fff;
  background: #333;
}

.btn-style-3, .button,
button,
button:focus,
button:active,
button:visited, header#header .cart_block .cart-buttons #button_order_cart, .bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button, .bc-banner-top .banner-shopnow--btn, .bc-filterproduct .type-tab .nav-tabs li a, .product .pb-center-column #product_comments_block_extra .comments_advices .reviews, .product .pb-center-column #product_comments_block_extra .comments_advices .open-comment-form, .cart_navigation .standard-checkout, .cart_navigation [type="submit"], .cart_navigation .button-exclusive, #products-comparison #product_comparison .button-container a.lnk_view, #contact .contact-form-box div.uploader span.action, .module-bcblog-post .pl_comment_form .submit a {
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}

.btn-style-4 {
  width: auto;
  padding: 0 20px;
  background: #f1f1f1;
  font-weight: normal;
  color: #888888;
  border: none;
}

/*=============================== GLOBAL CSS ======================================================*/
html, body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #242424;
}

@media (max-width: 1199px) {
  html, body {
    font-size: 12.6px;
  }
}

@media (max-width: 991px) {
  html, body {
    font-size: 11.9px;
  }
}

body {
  min-width: 0;
}

a {
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

img {
  max-width: 100%;
  height: auto;
}

.button,
button,
button:focus,
button:active,
button:visited {
  outline: none;
  border: none;
  padding: 0 15px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-appearance: none;
}

.button span,
button span,
button:focus span,
button:active span,
button:visited span {
  border: none;
  padding: 0;
}

.button [class^="icon-"],
button [class^="icon-"],
button:focus [class^="icon-"],
button:active [class^="icon-"],
button:visited [class^="icon-"] {
  display: none;
}

.button:hover,
button:hover,
button:focus:hover,
button:active:hover,
button:visited:hover {
  background: #242424;
}

.form-group.form-error input {
  background-position: 98% 50%;
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"] {
  background: #fff;
  border: 1px solid #eee;
  height: 35px;
  line-height: 35px;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
}

input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="tel"]:focus {
  outline: none;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

textarea.form-control {
  border-color: #eee;
  font-family: "Open Sans", sans-serif;
  font-size: 13px;
  color: #333333;
}

fieldset {
  min-width: 0;
}

/*============================================================================================================*/
/*================================================LAYOUT MODES===========================================*/
.wide-mode .bc-h-boxed-mode,
.wide-mode .bc-f-boxed-mode {
  max-width: 1200px;
  margin: 0 auto;
}

.boxed-mode {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
}

/*============================================================================================================*/
/*================================================CUSTOME CONTAINER===========================================*/
.columns-container {
  padding-top: 30px;
}

@media (max-width: 767px) {
  .columns-container {
    padding-top: 0;
  }
}

#columns {
  padding-bottom: 0;
}

.menu-opened {
  overflow: hidden;
}

.menu-opened body.menu-opened {
  position: fixed;
  left: 0;
  top: 0;
}

/*=============================================================================================================*/
/*=============================================MOBILE MENU OPEN================================================*/
.menu-opened #page {
  cursor: url(../images/close.png), pointer;
  position: relative;
}

.menu-opened #page:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

/*==============================================================================================================*/
/*SELECTOR*/
div.select {
  *zoom: 1;
}

div.select:after, div.select:before {
  content: "";
  display: table;
  clear: both;
}

div.selector {
  height: 35px;
  background: none;
  border: 1px solid #eee;
}

div.selector > span {
  background: none;
  width: 100% !important;
  height: 33px;
  position: relative;
  line-height: 33px;
  color: #333333;
  font-size: 13px;
  text-align: left;
}

div.selector > span:after {
  content: "\f107";
  font-family: "FontAweSome";
  width: 29px;
  height: 33px;
  text-align: center;
  border-left: 1px solid #eee;
  position: absolute;
  right: 0;
  top: 0;
}

div.selector select {
  width: 100%;
  height: 33px;
}

div.selector + span {
  margin-top: 2px;
}

/*RADIO*/
div.radio span {
  background: none;
  border: 1px solid #eee;
  border-radius: 50%;
}

div.radio span.checked {
  position: relative;
  border-color: #a0a91c;
}

div.radio span.checked:after {
  content: "";
  width: 5px;
  height: 5px;
  display: block;
  border-radius: 50%;
  background: #a0a91c;
  position: absolute;
  left: 3px;
  top: 3px;
}

/*CHECKER*/
div.checker {
  width: 10px;
  height: 10px;
  position: relative;
  min-height: 0;
  display: inline-block !important;
}

div.checker + label a {
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
}

div.checker + label:hover a {
  color: #a0a91c;
}

div.checker span {
  background: none;
  width: 10px;
  height: 10px;
  position: relative;
  z-index: 1;
  top: 0;
  vertical-align: top;
  border: 1px solid #eee;
}

div.checker span.checked {
  border-color: #a0a91c;
}

div.checker span.checked:before {
  content: "";
  width: 4px;
  height: 4px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2px;
  margin-top: -2px;
  background: #a0a91c;
}

div.checker input {
  width: 10px;
  height: 10px;
  min-height: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

div.checker:hover span {
  border-color: #a0a91c;
}

div.checker:hover + label {
  color: #a0a91c;
}

/*NEW LABEL*/
.new-box {
  width: 3.9285714286rem;
  height: 3.9285714286rem;
  border-radius: 50%;
  display: none;
  text-align: center;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  line-height: 3.9285714286rem;
  background: #333333;
  position: absolute;
  left: 0.7142857143rem;
  top: 1.0714285714rem;
  z-index: 20;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.new-box:hover {
  background: #333333;
  color: #fff;
}

@media (max-width: 991px) {
  .new-box {
    left: 0px !important;
    top: 0px !important;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .new-box {
    left: 0px !important;
    top: 0px !important;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 10px;
  }
}

/*PRODUCT NAME */
.product-name {
  text-align: left;
  font-size: 14px;
  text-transform: none;
  color: #333333;
  line-height: 1;
}

.product-name:hover {
  color: #a0a91c !important;
}

.product-name a {
  font-size: 15px;
  line-height: inherit;
  display: block;
}

/*PRODUCT PRICE*/
.price.product-price, .old-price.product-price {
  color: #a0a91c;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1;
  margin: 15px 10px 0 0;
  font-weight: normal;
}

@media (max-width: 767px) {
  .price.product-price, .old-price.product-price {
    font-size: 1.2857142857rem;
    font-family: "Montserrat", sans-serif;
  }
}

.old-price.product-price {
  color: #888888;
  text-decoration: line-through;
}

/*PRODUCT STOCK STATUS*/
.stock-availability, .product .pb-center-column #availability_statut #availability_value, #cart_summary tbody td.cart_avail span {
  display: block;
  color: #a0a91c;
  font-weight: bold;
}

/*PRODUCT REDUCTION STATUS*/
.product-flags .discount {
  color: #a0a91c;
  font-weight: bold;
}

/*PRICE REDUCE PERCEN*/
.price-percent-reduction, .product .pb-center-column .content_prices #reduction_percent {
  display: none;
}

/*PRODUCT PRICE PECENT REDUCE*/
.sale-box {
  margin-bottom: 10px;
  position: absolute;
  width: auto;
  padding: 0 5px;
  line-height: 20px;
  background: #a0a91c;
  border: none;
  text-transform: none;
  text-align: center;
  color: #fff;
  top: 0.7142857143rem;
  right: 10px;
  z-index: 20;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  font-size: 12px;
}

.sale-box:hover {
  background: #a0a91c;
}

/*TIME COUNTER*/
.clock-block .clock {
  text-align: center;
  margin-top: -40px;
}

.clock-block li {
  display: inline-block;
  width: 60px;
  height: 60px;
  background: #373737;
  margin: 0 15px;
  text-transform: uppercase;
  color: #fff;
  padding-top: 10px;
}

@media (max-width: 991px) {
  .clock-block li {
    width: 50px;
    height: 50px;
    padding-top: 5px;
    margin: 0 5px;
  }
}

.clock-block li span {
  display: block;
}

/*SCROLL TO TOP BUTTON*/
#scroll-to-top {
  position: fixed;
  right: 10px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  z-index: 400;
  font-size: 0;
  padding-top: 10px;
  color: #eee;
  background: #000;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  cursor: pointer;
  padding-left: 15px;
}

#scroll-to-top:before {
  content: "\f106";
  font-family: "FontAweSome";
  font-size: 17.5px;
}

#scroll-to-top:hover {
  background: #a0a91c;
  color: #fff;
}

/*OWL SLIDER CONTROL*/
.slideNav, .owl-controls .owl-nav .owl-prev, .owl-controls .owl-nav .owl-next, #layer_cart .crossseling #blockcart_list .bx-prev, #layer_cart .crossseling #blockcart_list .bx-next, .product .pb-left-column #views_block #view_scroll_left, .product .pb-left-column #views_block #view_scroll_right {
  width: 42px;
  height: 42px;
  border: 2px solid #e4e4e4;
  border-radius: 42px;
  margin-right: 0 !important;
  margin-left: 6px !important;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  color: #888888;
  font-size: 0;
  background: #fff;
}

.slideNav:hover, .owl-controls .owl-nav .owl-prev:hover, .owl-controls .owl-nav .owl-next:hover, #layer_cart .crossseling #blockcart_list .bx-prev:hover, #layer_cart .crossseling #blockcart_list .bx-next:hover, .product .pb-left-column #views_block #view_scroll_left:hover, .product .pb-left-column #views_block #view_scroll_right:hover {
  background: #e4e4e4;
}

.slideNav:before, .owl-controls .owl-nav .owl-prev:before, .owl-controls .owl-nav .owl-next:before, #layer_cart .crossseling #blockcart_list .bx-prev:before, #layer_cart .crossseling #blockcart_list .bx-next:before, .product .pb-left-column #views_block #view_scroll_left:before, .product .pb-left-column #views_block #view_scroll_right:before {
  font-family: "FontAweSome";
  text-align: center;
  font-size: 14px;
  line-height: 38px;
  display: block;
  color: inherit;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0;
}

.slidePrev:before, .owl-controls .owl-nav .owl-prev:before, #layer_cart .crossseling #blockcart_list .bx-prev:before, .product .pb-left-column #views_block #view_scroll_left:before {
  content: "\f104";
}

.slideNext:before, .owl-controls .owl-nav .owl-next:before, #layer_cart .crossseling #blockcart_list .bx-next:before, .product .pb-left-column #views_block #view_scroll_right:before {
  content: "\f105";
}

.owl-controls .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border: 2px solid #888888;
  background: #888888;
  margin: 0 0 0 5px;
}

.owl-controls .owl-dots .owl-dot.active span {
  background: #fff;
  border: 2px solid #a0a91c;
}

/*BLOCK TAG*/
.tags_block a {
  text-transform: uppercase;
  font-size: 11px;
  border: 1px solid #a0a91c;
  padding: 0 18px;
  float: left;
  margin-right: 10px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-top: 5px;
  margin-top: 10px;
}

.tags_block a:hover {
  background: #a0a91c;
  color: #fff;
}

/*BUTTON COMPARE SUBMIT ON CATEGORY PAGE*/
.button.button-medium.bt_compare {
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 1;
  border: none;
}

.button.button-medium.bt_compare span {
  border: none;
}

/*REVIEW STAR*/
.reviews-container {
  min-height: 16px;
  margin-bottom: 0;
}

.comments_note {
  font-size: 0;
}

.comments_note .nb-comments {
  display: none;
}

.star_content {
  line-height: 1;
}

.star_content .star {
  float: none;
  display: inline-block;
  margin: 0 2px;
  width: auto;
  height: auto;
  font-size: 10px;
}

.star_content .star.star_on {
  display: inline-block;
}

.star_content .star:after {
  color: #ffc000 !important;
}

/*PAGE HEADING*/
.page-heading {
  text-transform: none;
  padding: 0px 20px 20px 30px !important;
  margin-top: 0;
}

.page-heading:before {
  top: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
}

/*PAGE SUB-HEADING*/
.page-subheading {
  font-size: 1.1428571429rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  border: none;
  margin-bottom: 0;
  color: #333333;
}

/*HISTORY PRICE*/
.history_price .price {
  color: #a0a91c;
  font-size: 16px;
  font-weight: bold;
}

.product_list.grid .product-container .product-image-container .quick-view-wrapper-mobile,
.product_list.list .product-container .product-image-container .quick-view-wrapper-mobile {
  display: none;
}

/*==========================================TAB MOBILE==================================================*/
@media (max-width: 767px) {
  .type-tab {
    overflow: visible !important;
    position: relative !important;
  }
  .type-tab .tab-toogle--mobile {
    width: 47px;
    height: 47px;
    background: none;
    line-height: 47px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 0;
  }
  .type-tab .tab-toogle--mobile:before {
    content: "\f205";
    display: block;
    font-family: "FontAweSome";
    font-size: 14px;
    color: #a0a91c;
  }
  .type-tab .tab-toogle--mobile.off:before {
    content: "\f204";
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .type-tab .tab-toogle--mobile + .nav-tabs {
    position: absolute !important;
    display: none;
    top: 50px !important;
    right: 0;
    width: 50%;
    z-index: 300;
    background: none;
    padding-top: 0 !important;
  }
  .type-tab .tab-toogle--mobile + .nav-tabs li {
    display: block;
    width: 100%;
    text-align: right;
    margin: 0 !important;
  }
  .type-tab .tab-toogle--mobile + .nav-tabs li a {
    padding: 0 15px !important;
    font-family: "Montserrat", sans-serif;
    border-top: none !important;
  }
  .type-tab .tab-toogle--mobile + .nav-tabs li.active, .type-tab .tab-toogle--mobile + .nav-tabs li:hover {
    background: #a0a91c;
    border-radius: 5px;
  }
  .type-tab .tab-toogle--mobile + .nav-tabs li.active a, .type-tab .tab-toogle--mobile + .nav-tabs li:hover a {
    background: transparent;
    font-weight: normal !important;
  }
}

@media (max-width: 767px) and (max-width: 320px) {
  .type-tab .tab-toogle--mobile + .nav-tabs {
    width: 100%;
  }
}

.bc-filterproduct .tab-toogle--mobile {
  top: 80px;
  right: 80px;
  background: none;
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.bc-filterproduct .tab-toogle--mobile:before {
  color: #333333;
}

@media (max-width: 767px) {
  .bc-bestsellerwithcategory .type-tab .tab-toogle--mobile {
    right: -1px;
  }
  .bc-bestsellerwithcategory .type-tab .tab-toogle--mobile + .nav-tabs {
    right: -1px;
  }
}

/*======================================================================================================*/
.bc-filter-product:not(.tab-pane) .product-container,
.product_list.grid .product-container {
  background: #fff;
  padding: 0;
  position: relative;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  z-index: auto;
  margin: 0 5px 10px;
}

@media (min-width: 768px) {
  .bc-filter-product:not(.tab-pane) .product-container:hover,
  .product_list.grid .product-container:hover {
    z-index: 200;
  }
  .bc-filter-product:not(.tab-pane) .product-container:hover .product-image-container .product_img_link:before,
  .product_list.grid .product-container:hover .product-image-container .product_img_link:before {
    -webkit-transform: scale(1, 1);
    -ms-transform: scale(1, 1);
    -o-transform: scale(1, 1);
    transform: scale(1, 1);
    opacity: 0.5;
    visibility: visible;
    transform: scale(1);
  }
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container,
.product_list.grid .product-container .product-image-container {
  border: none !important;
  padding: 0;
  margin-bottom: 0;
  position: relative;
  text-align: center;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .product_img_link,
.product_list.grid .product-container .product-image-container .product_img_link {
  display: block;
  position: relative;
  overflow: hidden;
  transition: 0.4s;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .product_img_link img,
.product_list.grid .product-container .product-image-container .product_img_link img {
  transition: 0.4s;
  width: 100%;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .product_img_link:before,
.product_list.grid .product-container .product-image-container .product_img_link:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  opacity: 0;
  transform: scale(1);
  background: rgba(0, 0, 0, 0.5);
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .quick-view-wrapper-mobile,
.product_list.grid .product-container .product-image-container .quick-view-wrapper-mobile {
  display: none;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .functional-buttons,
.product_list.grid .product-container .product-image-container .button-container .functional-buttons {
  display: block !important;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .compare, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .wishlist, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .quick-view,
.product_list.grid .product-container .product-image-container .button-container .compare,
.product_list.grid .product-container .product-image-container .button-container .wishlist,
.product_list.grid .product-container .product-image-container .button-container .quick-view {
  position: absolute;
  left: 10px;
  top: 10px;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .compare.wishlist, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .wishlist.wishlist, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .quick-view.wishlist,
.product_list.grid .product-container .product-image-container .button-container .compare.wishlist,
.product_list.grid .product-container .product-image-container .button-container .wishlist.wishlist,
.product_list.grid .product-container .product-image-container .button-container .quick-view.wishlist {
  top: 60px;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .compare.quick-view, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .wishlist.quick-view, .bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .quick-view.quick-view,
.product_list.grid .product-container .product-image-container .button-container .compare.quick-view,
.product_list.grid .product-container .product-image-container .button-container .wishlist.quick-view,
.product_list.grid .product-container .product-image-container .button-container .quick-view.quick-view {
  top: 110px;
}

.bc-filter-product:not(.tab-pane) .product-container .product-image-container .button-container .ajax_add_to_cart_button,
.product_list.grid .product-container .product-image-container .button-container .ajax_add_to_cart_button {
  position: absolute;
  right: 10px;
  bottom: -30px;
  z-index: 100;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block,
.product_list.grid .product-container .right-block {
  padding: 30px 20px;
  text-align: left;
  position: relative;
  width: 100%;
  background: #fff;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block .product-desc,
.bc-filter-product:not(.tab-pane) .product-container .right-block .availability,
.bc-filter-product:not(.tab-pane) .product-container .right-block .lnk_view,
.bc-filter-product:not(.tab-pane) .product-container .right-block .product-flags,
.bc-filter-product:not(.tab-pane) .product-container .right-block .price-percent-reduction,
.bc-filter-product:not(.tab-pane) .product-container .right-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices
.bc-filter-product:not(.tab-pane) .product-container .right-block #reduction_percent,
.product_list.grid .product-container .right-block .product-desc,
.product_list.grid .product-container .right-block .availability,
.product_list.grid .product-container .right-block .lnk_view,
.product_list.grid .product-container .right-block .product-flags,
.product_list.grid .product-container .right-block .price-percent-reduction,
.product_list.grid .product-container .right-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices
.product_list.grid .product-container .right-block #reduction_percent {
  display: none;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block h5, .bc-filter-product:not(.tab-pane) .product-container .right-block .h5,
.product_list.grid .product-container .right-block h5,
.product_list.grid .product-container .right-block .h5 {
  margin: 0;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block .product-name,
.product_list.grid .product-container .right-block .product-name {
  padding: 0 0 3px;
  margin-bottom: 0;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block .content_price,
.product_list.grid .product-container .right-block .content_price {
  margin: 5px 0;
}

.bc-filter-product:not(.tab-pane) .product-container .right-block .button-container,
.product_list.grid .product-container .right-block .button-container {
  display: none !important;
}

/*TOP BANNER*/
#header #banner_block_popup {
  margin-bottom: 0;
  text-align: center;
  padding: 10px 0;
  position: relative;
}

#header #banner_block_popup .text_content {
  color: #fff;
  font-weight: bold;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 0 30px;
  padding-right: 70px;
}

#header #banner_block_popup .text_content p {
  margin-bottom: 0;
}

#header #banner_block_popup .ads-bar-link {
  display: inline-block;
  color: #a0a91c;
  text-decoration: underline;
}

#header #banner_block_popup .close {
  font-size: 0;
  position: absolute;
  border: none;
  position: absolute;
  right: 0;
  top: 4px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: url("../images/close.png") no-repeat center;
}

#header #banner_block_popup .close span {
  padding: 0;
  border: none;
  display: none;
}

/*COOKIE LAW*/
#cookie-popup {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}

#cookie-popup #cookie_block_popup {
  margin-bottom: 0;
  padding: 20px 0 10px;
}

@media (max-width: 767px) {
  #cookie-popup #cookie_block_popup {
    padding-left: 15px;
    padding-right: 15px;
  }
}

#cookie-popup .text_content {
  width: 60%;
  float: left;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
}

@media (max-width: 370px) {
  #cookie-popup .text_content {
    width: 100%;
  }
}

#cookie-popup .button-container {
  width: 40%;
  float: right;
  text-align: right;
  padding-top: 5px;
}

@media (max-width: 370px) {
  #cookie-popup .button-container {
    width: 100%;
    text-align: left;
  }
}

#cookie-popup .btn {
  padding: 0 15px;
  margin: 0 5px 10px;
  border: none;
  text-shadow: none;
  cursor: pointer;
}

#cookie-popup .btn span {
  background: none;
  border: none;
  padding: 0;
  font-size: 12px;
}

@media (min-width: 767px) {
  .content-manufacture-block {
    padding: 0 15px;
  }
}

/*STICKY MENU*/
@media (min-width: 991px) {
  .menu-sticky.bc-stickymenu-fixed {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 95;
    background: #a0a91c;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  }
  .menu-sticky.bc-stickymenu-fixed .container {
    position: relative;
  }
  .menu-sticky.bc-stickymenu-fixed .container #search_block_top {
    right: 0;
  }
}

/*TOOLTIP*/
.tooltip-inner {
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
}

/*PRELOADER ZOOM*/
.fancybox-overlay, .layer_cart_overlay {
  background: rgba(255, 255, 255, 0.5);
}

#layer_cart {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

#fancybox-loading, .zoomPreload, .product_list > p {
  position: fixed;
  width: 53px;
  height: 53px;
  left: 46%;
  top: 50%;
  margin: 0 auto;
  display: block;
  background: cyan;
  animation: 1.15s cssload-spin infinite;
  -o-animation: 1.15s cssload-spin infinite;
  -ms-animation: 1.15s cssload-spin infinite;
  -webkit-animation: 1.15s cssload-spin infinite;
  -moz-animation: 1.15s cssload-spin infinite;
  font-size: 0;
  opacity: 1;
  border: none;
}

#fancybox-loading:before, #fancybox-loading:after, .zoomPreload:before, .zoomPreload:after, .product_list > p:before, .product_list > p:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation: 0.58s infinite alternate linear;
  -o-animation: 0.58s infinite alternate linear;
  -ms-animation: 0.58s infinite alternate linear;
  -webkit-animation: 0.58s infinite alternate linear;
}

#fancybox-loading:before, .zoomPreload:before, .product_list > p:before {
  background: #d100cb;
  animation-name: cssload-blur1;
  -o-animation-name: cssload-blur1;
  -ms-animation-name: cssload-blur1;
  -webkit-animation-name: cssload-blur1;
  -moz-animation-name: cssload-blur1;
}

#fancybox-loading:after, .zoomPreload:after, .product_list > p:after {
  background: #fff;
  animation-name: cssload-blur2;
  -o-animation-name: cssload-blur2;
  -ms-animation-name: cssload-blur2;
  -webkit-animation-name: cssload-blur2;
  -moz-animation-name: cssload-blur2;
}

#fancybox-loading > div, .zoomPreload > div, .product_list > p > div {
  background: none;
}

.product_list {
  position: relative;
}

.product_list > p {
  position: absolute;
  left: 50%;
  top: -50px;
}

.product_list > p img {
  width: 0;
  font-size: 0;
}

.block .list-block li a:hover {
  color: #a0a91c;
}

.block .list-block li a:hover:before {
  color: #a0a91c;
}

.img-responsive {
  display: inline-block;
}

.ajax_block_product .product-container {
  border: 2px solid #f4f4f4;
  border-radius: 0 0 10px 10px;
}

.ajax_block_product .product-container .left-block {
  position: relative;
}

.ajax_block_product .product-container .left-block .button-container .quick-view, .ajax_block_product .product-container .left-block .button-container .wishlist, .ajax_block_product .product-container .left-block .button-container .compare {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  display: none;
  margin: 0;
  position: relative;
  float: none;
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .left-block .button-container .quick-view, .ajax_block_product .product-container .left-block .button-container .wishlist, .ajax_block_product .product-container .left-block .button-container .compare {
    display: inline-block;
  }
}

.ajax_block_product .product-container .left-block .button-container .quick-view {
  animation-delay: 0.2s;
}

@media (max-width: 767px) {
  .ajax_block_product .product-container .left-block .button-container .quick-view {
    display: none !important;
  }
}

.ajax_block_product .product-container .left-block .button-container .wishlist {
  animation-delay: 0.3s;
}

.ajax_block_product .product-container .left-block .button-container .compare {
  animation-delay: 0.4s;
}

.ajax_block_product .product-container:hover .left-block .button-container .quick-view, .ajax_block_product .product-container:hover .left-block .button-container .wishlist, .ajax_block_product .product-container:hover .left-block .button-container .compare {
  display: inline-block;
  z-index: 100;
}

@media (max-width: 767px) {
  #order-detail-content #cart_summary td.cart_product {
    width: 30%;
  }
  #order-detail-content #cart_summary tbody td.cart_description {
    width: 70%;
  }
  #order-detail-content #cart_summary td {
    padding: 15px 30px 15px 15px;
  }
  #order-detail-content #cart_summary td.cart_unit {
    text-align: left;
  }
  #order-detail-content #cart_summary td:before {
    margin-bottom: 5px;
  }
}

.unvisible {
  display: none;
}

.search-block-box {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9990;
  display: none;
  padding: 0;
  margin: 0;
}

.search-block-box.search-open .search-box {
  background: #fbfbfb;
  border-left: 1px solid #ededed;
  bottom: 0;
  display: block;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 50px 30px 20px;
  position: fixed;
  right: -17px;
  top: 0;
  visibility: visible;
  width: 300px;
  z-index: 1000;
  transition-duration: 1s;
}

.search-block-box.search-open .search-box > h1, .search-block-box.search-open .search-box > .h1 {
  margin: 0 0 20px;
  padding: 0 0 7px;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  position: relative;
}

.search-block-box.search-open .search-box > p {
  position: relative;
  margin: 0;
  padding: 0;
  display: block;
  width: 100%;
}

.search-block-box.search-open .search-box .search_button {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  padding: 0 10px;
  min-width: 0;
}

.search-block-box.search-open .search-box .search_button:before {
  content: "\f002";
  font-family: "FontAweSome";
  font-size: 14px;
  color: #333333;
}

.search-block-box.search-open .search-box .search_button span {
  display: none;
}

.search-block-box.search-open .search-box .search_query {
  background: #ffffff;
  border: 1px solid #ededed;
  padding: 10px;
  height: 40px;
  color: #333333;
  width: 100%;
}

.search-open {
  overflow-y: hidden;
}

.search-open #page {
  z-index: 9991;
  position: relative;
  -webkit-transform: translate(-280px, 0);
  -ms-transform: translate(-280px, 0);
  -o-transform: translate(-280px, 0);
  transform: translate(-280px, 0);
  -webkit-transition: opacity(1s), 0s ease;
  -moz-transition: opacity(1s), 0s ease;
  -o-transition: opacity(1s), 0s ease;
  transition: opacity(1s), 0s ease;
  -webkit-transition-duration: 1s;
  -moz-transition-duration: 1s;
  -o-transition-duration: 1s;
  transition-duration: 1s;
}

.search-open #page:before {
  background: rgba(0, 0, 0, 0.25);
  content: '';
  height: 100%;
  width: 100%;
  z-index: 9991;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
}

#search_block_box #show_result_2 {
  position: relative;
  background: none;
  width: 100%;
  padding: 0;
  margin: 60px 0 0;
  color: #333333;
  *zoom: 1;
}

#search_block_box #show_result_2:after, #search_block_box #show_result_2:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  #search_block_box #show_result_2 {
    width: calc(100vw - 30px);
    margin-left: -50px;
  }
}

#search_block_box #show_result_2 .result {
  width: 100%;
  padding: 15px 0;
}

#search_block_box #show_result_2 .result h1, #search_block_box #show_result_2 .result .h1 {
  font-size: 18px;
  font-weight: bold;
}

#search_block_box #show_result_2 .result .product-description {
  display: none;
}

#search_block_box #show_result_2 #closedform {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  border: 1px solid #dfdfdf;
}

#search_block_box #show_result_2 #closedform:hover {
  color: #fff;
  background: #a0a91c;
}

#search_block_box #show_result_2 h1, #search_block_box #show_result_2 .h1 {
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 20px;
}

#search_block_box #show_result_2 #search-products_block li {
  border-bottom: 1px dotted grey;
}

#search_block_box #show_result_2 #search-products_block li .products-block-image {
  float: left;
  margin-right: 10px;
}

#search_block_box #show_result_2 #search-products_block li h5, #search_block_box #show_result_2 #search-products_block li .h5 {
  margin-bottom: 15px;
}

#search_block_box #show_result_2 #search-products_block li .product-name {
  color: #333;
}

#search_block_box #show_result_2 #search-products_block li .price-box .price {
  color: #a0a91c;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

#search_block_box #show_result_2 #search-products_block li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

/*=============================== TYPOGRAPHY ======================================================*/
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  font-family: "Montserrat", sans-serif;
  line-height: 1;
}

h1, .h1 {
  font-size: 2.1428571429rem;
}

h2, .h2 {
  font-size: 1.7142857143rem;
}

h3, .h3 {
  font-size: 1.2857142857rem;
}

h4, .h4 {
  font-size: 1rem;
}

h5, .h5 {
  font-size: 0.9285714286rem;
}

ul, ol {
  list-style: none;
}

ul li, ol li {
  outline: none;
}

a {
  color: inherit;
}

a:active, a:focus {
  outline: none;
  text-decoration: none;
}

a:hover {
  background: none;
}

/*=========================================== CSS FOR HEADER ===========================================================*/
@media (max-width: 767px) {
  .header-container {
    padding-bottom: 69px;
  }
}

header {
  /*cart block*/
}

header .container {
  overflow: visible;
}

header .nav {
  background: none;
  color: #888888;
}

@media (max-width: 767px) {
  header .nav .row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

header .nav nav {
  min-height: 50px;
}

@media (max-width: 767px) {
  header .nav nav {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
  }
}

header .nav #languages-block-top, header .nav #currencies-block-top, header .nav .header_links {
  border: none;
  max-height: 100%;
}

@media (max-width: 767px) {
  header .nav #languages-block-top, header .nav #currencies-block-top, header .nav .header_links {
    padding: 0px;
    border: none;
    float: none;
    display: inline-block;
  }
}

header .nav #languages-block-top ul, header .nav #currencies-block-top ul, header .nav .header_links ul {
  z-index: 100;
}

header .nav #languages-block-top .current, header .nav #currencies-block-top .current, header .nav .header_links .current {
  line-height: 50px;
  padding: 0 10px;
  color: #888888;
  cursor: pointer;
}

@media (max-width: 767px) {
  header .nav #languages-block-top .current, header .nav #currencies-block-top .current, header .nav .header_links .current {
    padding: 10px;
    line-height: normal;
  }
}

header .nav #languages-block-top .current::after, header .nav #currencies-block-top .current::after, header .nav .header_links .current::after {
  content: "\f107";
  font-size: 14px;
  padding-left: 3px;
  vertical-align: 0px;
  text-shadow: none;
  font-family: "FontAweSome";
  color: #a0a91c;
}

header .nav #languages-block-top .current span, header .nav #languages-block-top .current strong, header .nav #currencies-block-top .current span, header .nav #currencies-block-top .current strong, header .nav .header_links .current span, header .nav .header_links .current strong {
  text-shadow: none;
  font-weight: normal;
  font-size: 14px;
}

header .nav #languages-block-top .current:hover, header .nav #languages-block-top .current.active, header .nav #currencies-block-top .current:hover, header .nav #currencies-block-top .current.active, header .nav .header_links .current:hover, header .nav .header_links .current.active {
  background: none;
}

header .nav #languages-block-top .current:hover:after, header .nav #languages-block-top .current:hover span, header .nav #languages-block-top .current:hover strong, header .nav #languages-block-top .current.active:after, header .nav #languages-block-top .current.active span, header .nav #languages-block-top .current.active strong, header .nav #currencies-block-top .current:hover:after, header .nav #currencies-block-top .current:hover span, header .nav #currencies-block-top .current:hover strong, header .nav #currencies-block-top .current.active:after, header .nav #currencies-block-top .current.active span, header .nav #currencies-block-top .current.active strong, header .nav .header_links .current:hover:after, header .nav .header_links .current:hover span, header .nav .header_links .current:hover strong, header .nav .header_links .current.active:after, header .nav .header_links .current.active span, header .nav .header_links .current.active strong {
  color: #a0a91c;
}

@media (max-width: 767px) {
  header .nav #languages-block-top .current span, header .nav #languages-block-top .current strong, header .nav #currencies-block-top .current span, header .nav #currencies-block-top .current strong, header .nav .header_links .current span, header .nav .header_links .current strong {
    font-size: 12px;
  }
  header .nav #languages-block-top .current::after, header .nav #currencies-block-top .current::after, header .nav .header_links .current::after {
    font-size: 12px;
  }
}

header .nav #languages-block-top ul li.selected, header .nav #languages-block-top ul li:hover, header .nav #currencies-block-top ul li.selected, header .nav #currencies-block-top ul li:hover, header .nav .header_links ul li.selected, header .nav .header_links ul li:hover {
  background: #a0a91c;
}

header .nav #languages-block-top ul li.selected a, header .nav #languages-block-top ul li:hover a, header .nav #currencies-block-top ul li.selected a, header .nav #currencies-block-top ul li:hover a, header .nav .header_links ul li.selected a, header .nav .header_links ul li:hover a {
  background: #a0a91c;
}

@media (max-width: 767px) {
  header .nav #languages-block-top ul, header .nav #currencies-block-top ul, header .nav .header_links ul {
    right: 0;
    left: auto;
    z-index: 101;
  }
}

header .nav .social-header {
  position: relative;
  right: auto;
  float: right;
  text-align: right;
}

@media (max-width: 991px) {
  header .nav .social-header {
    width: auto;
  }
}

@media (max-width: 767px) {
  header .nav .social-header {
    float: right;
    padding-right: 0;
  }
}

header .nav .social-header ul {
  line-height: 50px;
}

header .nav .social-header ul li {
  font-family: "FontAwesome";
  color: #333;
  cursor: pointer;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

@media (max-width: 767px) {
  header .nav .social-header ul li {
    padding-right: 0;
    padding-left: 8px;
  }
}

header .nav .social-header ul li a {
  font-size: 0;
  display: inline-block;
}

header .nav .social-header ul li a:before {
  color: #a0a91c;
}

header .nav .social-header ul li a:hover {
  color: #a0a91c;
}

@media (max-width: 767px) {
  header .nav .social-header ul li a {
    padding-right: 8px;
  }
}

header .nav .social-header ul li.facebook a:before {
  content: "\f09a";
  font-size: 10px;
}

header .nav .social-header ul li.twitter a:before {
  content: "\f099";
  font-size: 10px;
}

header .nav .social-header ul li.google-plus a:before {
  content: "\f0d5";
  font-size: 10px;
}

header .nav .social-header ul li.dribbble a:before {
  content: "\f17d";
  font-size: 10px;
}

header .nav .social-header ul li.linkedin a:before {
  content: "\f0e1";
  font-size: 10px;
}

header .nav .header_user_info {
  border: none;
  padding-left: 20px;
  line-height: 50px;
  float: left;
  font-size: 14px;
}

@media (max-width: 767px) {
  header .nav .header_user_info {
    *zoom: 1;
    clear: both;
    float: none;
    padding: 0 15px;
    text-align: center;
    display: none;
  }
  header .nav .header_user_info:after, header .nav .header_user_info:before {
    content: "";
    display: table;
    clear: both;
  }
  header .nav .header_user_info span, header .nav .header_user_info a {
    float: none !important;
    display: inline-block;
  }
}

header .nav .header_user_info span {
  float: left;
  padding-right: 5px;
}

header .nav .header_user_info a {
  padding: 0;
  color: #242424;
  float: left;
  line-height: 50px;
}

header .nav .header_user_info a:hover {
  background: none;
  color: #a0a91c;
}

@media (max-width: 991px) {
  header .nav .header_user_info {
    display: none;
  }
}

header .nav .header_links {
  *zoom: 1;
  display: inline-block;
  float: left;
  position: relative;
  border: none;
}

header .nav .header_links:after, header .nav .header_links:before {
  content: "";
  display: table;
  clear: both;
}

header .nav .header_links .dropdown-toggle {
  display: none;
}

header .nav .header_links #header_links {
  margin: 0;
  z-index: 100;
}

header .nav .header_links #header_links li {
  border-left: none;
  color: #888888;
  float: none;
  display: inline-block;
  line-height: 50px;
}

header .nav .header_links #header_links li a {
  padding: 0 10px;
  font-size: 14px;
}

header .nav .header_links #header_links li a:hover {
  background: none;
  color: #a0a91c;
  text-decoration: none;
}

header .nav .header_links #header_links li .icon {
  margin-right: 8px;
  color: #a0a91c;
}

header .nav .header_links #header_links li:hover {
  background: none;
}

header .nav .header_links #header_links li:hover a {
  background: none;
}

@media (max-width: 767px) {
  header .nav .header_links #header_links li {
    line-height: normal;
    padding: 10px 0;
  }
  header .nav .header_links #header_links li a {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  header .nav .header_links {
    margin: 0 auto;
    display: inline-block;
    float: none;
  }
  header .nav .header_links ul#header_links {
    float: none;
    left: auto;
    text-align: left;
  }
}

header .shopping_cart {
  *zoom: 1;
  font-family: "Open Sans", sans-serif;
  padding-top: 0;
  z-index: 10;
  text-align: center;
  width: auto;
  -webkit-border-radius: 50px;
  position: absolute !important;
  top: 30%;
  right: 0;
  float: none;
}

header .shopping_cart:after, header .shopping_cart:before {
  content: "";
  display: table;
  clear: both;
}

header .shopping_cart > a:first-child {
  background: transparent;
  text-shadow: none;
  color: #333333;
  padding: 0 0 0 60px;
  position: relative;
  overflow: visible;
}

header .shopping_cart > a:first-child:before {
  color: #a0a91c;
  width: 60px;
  height: 50px;
  line-height: 50px;
  padding-right: 10px;
  font-size: 45px;
  text-align: center;
  content: "\f07a";
  font-family: "FontAweSome";
  font-weight: normal;
  position: absolute;
  left: 0;
  top: 0;
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child:before {
    margin-right: 0;
    margin-bottom: 0;
    top: 0;
    color: #fff;
    position: relative;
    font-size: 20px;
  }
}

header .shopping_cart > a:first-child:after {
  content: "\f107";
  float: none;
  font-size: 11px;
  margin-left: 5px;
  display: none !important;
}

header .shopping_cart > a:first-child:hover:after {
  content: "\f106";
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child {
    padding: 0;
  }
}

header .shopping_cart > a:first-child .ajax_cart_quantity {
  display: block !important;
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  top: -5px;
  left: 20px;
  background: #c9d523;
  color: #ffffff;
  text-align: center;
  padding-right: 0 !important;
  line-height: 20px;
  font-weight: normal;
  font-size: 12px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.22);
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child .ajax_cart_quantity {
    left: 28px;
    top: 5px;
  }
}

header .shopping_cart > a:first-child b, header .shopping_cart > a:first-child span {
  font-weight: normal;
  color: #242424;
  font-size: 16px;
}

header .shopping_cart > a:first-child .ajax_cart_product_txt,
header .shopping_cart > a:first-child .ajax_cart_product_txt_s {
  display: none !important;
}

header .shopping_cart > a:first-child .ajax_cart_no_product {
  min-width: 50%;
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child .ajax_cart_no_product {
    display: none !important;
  }
}

header .shopping_cart > a:first-child .ajax_cart_total {
  font-weight: bold;
  font-size: 25px;
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child .ajax_cart_total {
    display: none !important;
  }
}

header .shopping_cart > a:first-child b {
  color: #242424;
  text-shadow: none;
  font-size: 14px;
  display: block;
}

@media (max-width: 767px) {
  header .shopping_cart > a:first-child b {
    display: none !important;
  }
}

@media (max-width: 767px) {
  header .shopping_cart {
    border: none;
    padding: 0 10px 0 0;
    float: none !important;
    top: 0;
  }
}

header .header-middle {
  background-image: url("../images/bgpt1.png");
  padding: 50px 0 80px;
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
}

@media (max-width: 767px) {
  header .header-middle {
    padding: 0;
  }
  header .header-middle .contact-header {
    display: none;
  }
  header .header-middle .col-sm-6 {
    width: calc(100% - 112px);
    position: absolute;
  }
  header .header-middle .col-sm-6 #search_block_top {
    width: 100%;
    top: 5px;
  }
  header .header-middle .row .col-xs-12:nth-child(3) {
    height: 50px;
    background: #a0a91c;
  }
}

header .header-middle .container {
  padding-left: 15px;
  padding-right: 15px;
}

header .header-middle .contact-header {
  display: table;
  width: 100%;
  margin: 25px 0 0;
}

header .header-middle .contact-header .icon-mailicon {
  float: left;
  margin-right: 15px;
}

header .header-middle .contact-header .icon-mailicon img {
  vertical-align: middle;
}

header .header-middle .contact-header span {
  display: block;
  line-height: 20px;
  color: #333;
  font-weight: bold;
}

header .header-middle .contact-header span i {
  padding-right: 10px;
  color: #a0a91c;
  font-weight: normal;
  width: 24px;
}

@media (max-width: 767px) {
  header .header-middle .contact-header {
    display: none;
  }
}

header .header-middle #header_logo {
  padding-left: 0;
  padding-top: 0;
  z-index: 10;
}

header .header-middle #header_logo .img-responsive {
  display: inline-block;
}

@media (max-width: 991px) {
  header .header-middle #header_logo {
    padding: 0 15px;
  }
  header .header-middle #header_logo img {
    max-width: 200px;
  }
  header .header-middle #header_logo > a {
    display: inline-block;
  }
}

@media (max-width: 767px) {
  header .header-middle #header_logo {
    padding: 20px 0;
    text-align: center;
  }
}

header .header-middle #search_block_top {
  padding-top: 0;
  position: relative;
  z-index: 100;
}

header .header-middle #search_block_top form {
  position: relative;
}

@media (max-width: 767px) {
  header .header-middle #search_block_top {
    width: calc(100% - 130px);
    padding: 0;
    clear: both;
    display: inline-block;
    margin-left: 60px;
  }
}

header .header-middle #search_block_top .search_query {
  width: 100%;
  height: 52px;
  padding: 0 73px 0 20px;
  border-radius: 0;
  background: #fff;
  border: 2px solid #e1e876;
}

@media (max-width: 767px) {
  header .header-middle #search_block_top .search_query {
    height: 40px;
    padding-right: 63px;
  }
}

header .header-middle #search_block_top .button-search {
  width: 50px;
  height: 48px;
  min-width: 0;
  position: absolute;
  background: none;
  right: 0;
  top: 2px;
  border: none;
  padding: 0;
  border-radius: 0;
  color: #333333;
}

@media (max-width: 767px) {
  header .header-middle #search_block_top .button-search {
    height: 38px;
    width: 38px;
    right: 0;
  }
}

header .header-middle #search_block_top .button-search:before {
  font-size: 16px;
}

header .header-middle #search_block_top .button-search:hover {
  color: #fff;
  background: #a0a91c;
}

header .header-middle #search_block_top #show_result {
  position: absolute;
  background-color: #e6ec8c;
  width: 100%;
  left: 0;
  top: 100%;
  z-index: 100;
  color: #242424;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 767px) {
  header .header-middle #search_block_top #show_result {
    width: calc(100vw - 30px);
    margin-left: -50px;
  }
}

header .header-middle #search_block_top #show_result .result {
  width: 100%;
  padding: 15px;
}

header .header-middle #search_block_top #show_result #closedform {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
}

header .header-middle #search_block_top #show_result #closedform:hover {
  color: #a0a91c;
}

header .header-middle #search_block_top #show_result h1, header .header-middle #search_block_top #show_result .h1 {
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding: 0 0 20px;
}

header .header-middle #search_block_top #show_result #search-products_block li {
  border-bottom: 1px dashed #a0a91c;
}

header .header-middle #search_block_top #show_result #search-products_block li .products-block-image {
  float: left;
  margin-right: 10px;
}

header .header-middle #search_block_top #show_result #search-products_block li h5, header .header-middle #search_block_top #show_result #search-products_block li .h5 {
  margin-bottom: 0;
}

header .header-middle #search_block_top #show_result #search-products_block li .product-name {
  color: #242424;
}

header .header-middle #search_block_top #show_result #search-products_block li .price-box .price {
  color: #a0a91c;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

header .header-middle #search_block_top #show_result #search-products_block li:last-child {
  border: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

header#header .cart_block {
  top: 100%;
  width: 290px;
  background: #fff;
  border: 1px solid #eee;
  color: #333333;
  -webkit-box-shadow: 0 5px 10px rgba(238, 238, 238, 0.4);
  box-shadow: 0 5px 10px rgba(238, 238, 238, 0.4);
}

header#header .cart_block .products {
  padding: 20px 20px 0;
  margin: 0;
}

header#header .cart_block .cart_block_list .remove_link {
  right: 0;
  top: 0;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link {
  color: #333333;
  width: 20px;
  height: 20px;
  text-align: right;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link:hover {
  color: #a0a91c;
}

header#header .cart_block .cart_block_list .remove_link .ajax_cart_block_remove_link:before {
  content: "\f1f8";
  font-size: 13px;
}

header#header .cart_block dt {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  padding: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 0;
  margin-bottom: 20px;
}

header#header .cart_block dt.last_item {
  border: none;
  margin-bottom: 0;
}

header#header .cart_block .cart-prices {
  background: none;
}

header#header .cart_block .cart-buttons {
  background: none;
}

header#header .cart_block .cart-info .product-name .cart_block_product_name {
  color: #333333;
}

header#header .cart_block .cart-info .product-name .quantity-formated .quantity {
  font-size: 0.8571428571rem;
}

header#header .cart_block .product-atributes a {
  color: #333333;
}

header#header .cart_block .cart-prices .cart-prices-line {
  border-color: #eee;
}

header#header .cart_block .price {
  color: #a0a91c;
  font-size: 1rem;
  padding-left: 10px;
}

header#header .cart_block .cart-buttons {
  padding-top: 0;
}

header#header .cart_block .cart-buttons #button_order_cart {
  padding: 0;
}

header#header .cart_block .cart-buttons #button_order_cart span {
  border: none;
  background: none;
  padding: 0;
}

header#header .banner {
  background: #a0a91c;
}

header#header .banner a {
  max-width: 100%;
}

header#header .banner a img {
  max-width: 100%;
}

header#header .banner #banner_block_popup .text_content {
  color: #ffffff;
}

header#header .banner #banner_block_popup .text_content .ads-bar-link {
  color: #ffffff;
}

#search_block_top {
  padding: 0;
  position: absolute;
  top: 0;
  line-height: 65px;
  color: #ffffff;
  font-size: 18px;
  right: 20px;
  width: 60px;
  text-align: center;
  cursor: pointer;
}

@media (max-width: 767px) {
  #search_block_top {
    font-size: 20px;
    top: 17px;
    right: 80px;
    line-height: 50px;
  }
}

#search_block_top:hover {
  color: #333;
}

/*=========================================== CSS FOR FOOTER ===========================================================*/
.bc-top-footer {
  *zoom: 1;
  background: #a0a91c;
  text-align: center;
  padding: 80px 0;
  color: #ffffff;
}

.bc-top-footer:after, .bc-top-footer:before {
  content: "";
  display: table;
  clear: both;
}

.bc-top-footer ul {
  margin: 0;
}

.bc-top-footer ul li {
  padding: 48px 43px;
  color: #fff;
}

@media (max-width: 1199px) {
  .bc-top-footer ul li {
    padding: 43px 40px;
  }
}

.bc-top-footer ul li:nth-child(odd) {
  background: #a0a91c;
}

.bc-top-footer ul li:nth-child(even) {
  background: #919719;
}

.bc-top-footer ul li .icon {
  font-size: 1.7142857143rem;
  float: left;
  margin-right: 20px;
  margin-top: 5px;
}

.bc-top-footer ul li h4, .bc-top-footer ul li .h4 {
  text-transform: uppercase;
  margin-bottom: 8px;
}

.bc-top-footer ul li p {
  font-family: "Playfair Display", serif;
  font-style: italic;
  color: #ffffff;
}

@media (max-width: 1199px) {
  .bc-top-footer ul li {
    padding: 38px 30px;
    white-space: nowrap;
  }
  .bc-top-footer ul li h4, .bc-top-footer ul li .h4 {
    margin-bottom: 5px;
  }
}

@media (max-width: 991px) {
  .bc-top-footer ul li {
    padding: 20px 15px;
    white-space: nowrap;
  }
  .bc-top-footer ul li h4, .bc-top-footer ul li .h4 {
    margin-bottom: 3px;
  }
  .bc-top-footer ul li .icon {
    margin-right: 10px;
  }
}

.bc-top-footer #newsletter_block_left {
  margin: 0;
}

.bc-top-footer #newsletter_block_left .news_content {
  width: 42%;
  margin: 0 auto;
}

@media (min-width: 767px) {
  .bc-top-footer #newsletter_block_left .news_content {
    width: 60%;
  }
}

@media (min-width: 991px) {
  .bc-top-footer #newsletter_block_left .news_content {
    width: 42%;
  }
}

@media (max-width: 767px) {
  .bc-top-footer #newsletter_block_left .news_content {
    width: 100%;
  }
}

.bc-top-footer #newsletter_block_left .news_content h4, .bc-top-footer #newsletter_block_left .news_content .h4 {
  background: none;
  font-size: 24px;
  border: none;
  color: #ffffff;
  margin: 0;
  padding: 0 0 30px;
}

.bc-top-footer #newsletter_block_left .news_content .block_content {
  margin: 30px 0 0;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group {
  position: relative;
  margin: 0;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group input[type="text"] {
  background: #fff;
  color: #888888;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  padding: 0 30px;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group input[type="text"]:hover {
  background: #fff;
  color: #888888;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group input[type="text"]:focus {
  border-color: #494276;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #544d84;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button {
  position: absolute;
  top: 5px;
  right: 10px;
  width: 40px;
  font-size: 0;
  text-align: center;
  min-width: 0;
  padding: 0;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button span {
  display: inline-block;
  width: 40px;
  line-height: 40px;
  text-align: center;
}

.bc-top-footer #newsletter_block_left .news_content .block_content .form-group .button span:before {
  font-family: "FontAweSome";
  content: "\f1d8";
  font-size: 18px;
}

.footer-container {
  background-image: url("../images/bgpt1.png");
  background-repeat: repeat;
  color: #888888;
  padding: 80px 0;
  background-color: #FFF;
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
}

@media (max-width: 767px) {
  .footer-container {
    padding: 30px 0;
  }
}

.footer-container .container {
  padding: 0;
}

.footer-container #footer .product-name a {
  color: #ffffff;
  font-size: 14px;
}

.footer-container #footer h4, .footer-container #footer .h4 {
  color: #242424;
  margin: 0 0 30px;
  position: relative;
  font-family: "Playball", cursive;
  text-transform: none;
  font-size: 25px;
  padding: 0;
}

@media (max-width: 767px) {
  .footer-container #footer h4, .footer-container #footer .h4 {
    margin: 0;
  }
}

.footer-container #footer h4:after, .footer-container #footer .h4:after {
  font-size: 18px;
  top: 5px;
}

.footer-container #footer .footer_product {
  margin-top: 30px;
}

.footer-container #footer ul {
  margin: 0;
  padding: 0;
}

.footer-container #footer ul li a {
  line-height: 35px;
  font-size: 14px !important;
  padding: 0 !important;
  text-transform: none !important;
}

.footer-container #footer ul li a:hover {
  color: #a0a91c;
}

@media (max-width: 991px) {
  .footer-container #footer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.footer-container #footer .store-footer .news_logo {
  margin-bottom: 20px;
}

.footer-container #footer .store-footer .news_content {
  line-height: 24px;
}

@media (max-width: 767px) {
  .footer-container #footer .store-footer {
    text-align: center;
  }
}

.footer-container #footer #newsletter_block_left {
  display: inline-block;
  float: none;
  margin-top: 30px;
  padding: 0 15px !important;
}

.footer-container #footer #newsletter_block_left .news_logo {
  width: 33.333333%;
  float: left;
  padding-right: 15px;
  padding-top: 55px;
}

@media (max-width: 767px) {
  .footer-container #footer #newsletter_block_left .news_logo {
    width: 100%;
    padding: 0;
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-container #footer #newsletter_block_left h4, .footer-container #footer #newsletter_block_left .h4 {
  float: none !important;
}

@media (min-width: 767px) {
  .footer-container #footer #newsletter_block_left h4, .footer-container #footer #newsletter_block_left .h4 {
    display: none;
  }
}

.footer-container #footer #newsletter_block_left h4:after, .footer-container #footer #newsletter_block_left .h4:after {
  display: block;
  content: "\f055";
  font-family: "FontAwesome";
  position: absolute;
  right: 0;
  width: auto;
}

.footer-container #footer #newsletter_block_left h4.active:after, .footer-container #footer #newsletter_block_left .active.h4:after {
  content: "\f056";
}

@media (max-width: 991px) {
  .footer-container #footer #newsletter_block_left .news_content {
    padding: 30px 0;
  }
}

@media (max-width: 767px) {
  .footer-container #footer #newsletter_block_left .news_content {
    width: 100%;
  }
}

.footer-container #footer #newsletter_block_left .news_content h4, .footer-container #footer #newsletter_block_left .news_content .h4 {
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  text-transform: uppercase;
  line-height: 1.4;
  width: 21%;
  padding: 0;
  padding-right: 10px;
  margin: -3px 0 0;
}

.footer-container #footer #newsletter_block_left .news_content h4 span, .footer-container #footer #newsletter_block_left .news_content .h4 span {
  font-family: "Playfair Display", serif;
  font-size: 1.4285714286rem;
  color: #333333;
  font-style: italic;
  text-transform: none;
}

@media (max-width: 991px) {
  .footer-container #footer #newsletter_block_left .news_content h4, .footer-container #footer #newsletter_block_left .news_content .h4 {
    width: 100%;
  }
  .footer-container #footer #newsletter_block_left .news_content h4 span, .footer-container #footer #newsletter_block_left .news_content .h4 span {
    display: block;
  }
}

@media (max-width: 767px) {
  .footer-container #footer #newsletter_block_left .news_content h4, .footer-container #footer #newsletter_block_left .news_content .h4 {
    width: 100%;
    padding-bottom: 15px;
    text-align: center;
  }
  .footer-container #footer #newsletter_block_left .news_content h4 span, .footer-container #footer #newsletter_block_left .news_content .h4 span {
    display: block;
  }
}

@media (max-width: 991px) {
  .footer-container #footer #newsletter_block_left .news_content .block_content {
    clear: both;
    margin-bottom: 0;
  }
}

.footer-container #footer #newsletter_block_left .news_content .form-group .button-small {
  color: #fff;
  font-size: 0.7142857143rem;
  font-family: "Montserrat", sans-serif;
  min-width: 0;
  border-radius: 0;
  line-height: 35px;
  border: 1px solid #a0a91c;
  width: calc(100% - 68%);
}

.footer-container #footer #newsletter_block_left .news_content .form-group .button-small:hover {
  background: #d7e14a;
}

.footer-container #footer #newsletter_block_left .form-group .form-control {
  border-color: #a0a91c;
  background: none;
  margin-right: 0;
}

@media (max-width: 767px) {
  .footer-container #footer #newsletter_block_left {
    padding: 0 !important;
  }
}

.footer-container #footer #social_block {
  float: left !important;
  padding: 15px 0 0 15px !important;
}

.footer-container #footer #social_block ul {
  float: left !important;
  *zoom: 1;
}

.footer-container #footer #social_block ul:after, .footer-container #footer #social_block ul:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .footer-container #footer #social_block ul {
    text-align: center;
  }
}

.footer-container #footer #social_block h4, .footer-container #footer #social_block .h4 {
  display: none;
}

.footer-container #footer #social_block ul li {
  width: 30px;
  height: 30px;
}

.footer-container #footer #social_block ul li:hover {
  background: none;
}

.footer-container #footer #social_block ul li a {
  display: block;
  width: 100%;
  height: 100%;
  color: #a0a91c;
  padding-top: 6px;
  line-height: 20px !important;
  font-size: 16px !important;
}

.footer-container #footer #social_block ul li a:before {
  vertical-align: 0;
}

@media (max-width: 991px) {
  .footer-container #footer #social_block ul li a {
    padding-top: 2px;
  }
}

@media (max-width: 767px) {
  .footer-container #footer #social_block ul li a {
    padding-top: 6px;
  }
}

.footer-container #footer #social_block ul li a:hover {
  color: #888888;
}

@media (max-width: 767px) {
  .footer-container #footer #social_block {
    margin-top: 10px;
  }
}

.footer-container #footer .footer-block .comments_note {
  text-align: left;
}

.footer-container #footer .footer-block .comments_note .star_content .star:after {
  color: #a0a91c !important;
}

.footer-container #footer .footer-block .links_footer_left, .footer-container #footer .footer-block .links_footer_right {
  width: 50%;
  float: left;
}

.footer-container #footer .footer-block .links_footer_left li:last-child a, .footer-container #footer .footer-block .links_footer_right li:last-child a {
  padding-bottom: 0;
}

.footer-container #footer .footer-block .links_footer_left li a, .footer-container #footer .footer-block .links_footer_right li a {
  font-weight: normal;
}

.footer-container #footer .footer-block .links_footer_left li a:hover, .footer-container #footer .footer-block .links_footer_right li a:hover {
  color: #a0a91c;
}

.footer-container #footer .footer-block#block_various_links_footer a {
  text-transform: uppercase;
  font-size: 0.7857142857rem;
  padding-bottom: 17px;
  display: block;
}

@media (max-width: 767px) {
  .footer-container #footer .bc-filterproduct {
    clear: both;
  }
}

.footer-container #footer .bc-filterproduct h4, .footer-container #footer .bc-filterproduct .h4 {
  text-transform: uppercase;
  color: #333333;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 42px;
}

.footer-container #footer .bc-filterproduct .product-container {
  *zoom: 1;
  margin-bottom: 22px;
}

.footer-container #footer .bc-filterproduct .product-container:after, .footer-container #footer .bc-filterproduct .product-container:before {
  content: "";
  display: table;
  clear: both;
}

.footer-container #footer .bc-filterproduct .product-container .left-block {
  width: 70px;
  float: left;
  margin-right: 20px;
  position: relative;
}

.footer-container #footer .bc-filterproduct .product-container .left-block .product_img_link:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  opacity: 0;
  background: rgba(109, 102, 160, 0.9);
}

.footer-container #footer .bc-filterproduct .product-container .left-block img {
  width: 100%;
}

.footer-container #footer .bc-filterproduct .product-container .right-block {
  width: calc(100% - 90px);
  float: left;
}

.footer-container #footer .bc-filterproduct .product-container .product-name {
  text-align: left;
  margin-top: 0;
  margin-bottom: 5px;
}

.footer-container #footer .bc-filterproduct .product-container .content_price .product-price {
  color: #a0a91c;
}

.footer-container #footer .bc-filterproduct .product-container .stock-availability, .footer-container #footer .bc-filterproduct .product-container .product .pb-center-column #availability_statut #availability_value, .product .pb-center-column #availability_statut .footer-container #footer .bc-filterproduct .product-container #availability_value, .footer-container #footer .bc-filterproduct .product-container #cart_summary tbody td.cart_avail span, #cart_summary tbody td.cart_avail .footer-container #footer .bc-filterproduct .product-container span {
  display: none;
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view-wrapper-mobile {
  display: none;
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view {
  z-index: 20;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span {
  font-size: 14px;
  color: #fff;
  visibility: hidden;
  width: auto;
  height: 30px;
  display: block;
  padding: 0 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.16) 0 2px 8px;
  line-height: 30px;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span:before {
  display: block;
  font-family: "FontAweSome";
  font-size: 15px;
  color: inherit;
}

@media (max-width: 991px) {
  .footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span {
    height: 30px;
    line-height: 30px;
  }
}

@media (max-width: 767px) {
  .footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span {
    display: none;
    height: 25px;
    line-height: 25px;
  }
  .footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span:before {
    font-size: 10px;
  }
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 0;
  background: none;
  box-shadow: none;
  padding: 0;
}

.footer-container #footer .bc-filterproduct .product-container .functional-buttons .quick-view span:before {
  content: "\f002";
}

.footer-container #footer .bc-filterproduct .product-container:hover .left-block .product_img_link:before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  transform: scale(1, 1);
  opacity: 0.5;
  visibility: visible;
}

.footer-container #footer .bc-filterproduct .product-container:hover .functional-buttons .quick-view span {
  visibility: visible !important;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.footer-container #footer .bc_tags_block h4, .footer-container #footer .bc_tags_block .h4 {
  text-transform: uppercase;
  color: #333333;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 42px;
}

.footer-container #footer .bc_tags_block a {
  text-transform: uppercase;
  font-size: 0.7857142857rem;
  border: 1px solid #ededed;
  padding: 0 18px;
  float: left;
  margin-right: 10px;
  margin-bottom: 12px;
  min-height: 30px;
  padding-top: 5px;
}

@media (max-width: 991px) {
  .footer-container #footer .bc_tags_block a {
    padding-right: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.footer-container #footer .bc_tags_block a:hover {
  background: #333;
  color: #fff;
}

.footer-container #footer #block_contact_infos .news_logo {
  margin: 0 0 50px;
}

.footer-container #footer #block_contact_infos > div ul li {
  padding-left: 0 !important;
  color: #888888;
  font-size: 1rem;
}

.footer-container #footer #block_contact_infos > div ul li span {
  font-size: 0;
  font-family: "Montserrat", sans-serif;
  color: #888888 !important;
  padding-right: 5px;
  float: left;
}

.footer-container #footer #block_contact_infos > div ul li i {
  font-size: 16px;
  text-align: center;
  color: #a0a91c;
  vertical-align: middle;
  float: none;
  width: auto;
}

.footer-container #footer #block_contact_infos > div ul li a {
  line-height: 0 !important;
}

@media (max-width: 767px) {
  .footer-container #footer #block_contact_infos {
    clear: both;
  }
}

.bc-bottom-footer {
  background: #fff;
  padding: 30px 0;
  color: #888888;
}

@media (max-width: 991px) {
  .bc-bottom-footer .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.bc-bottom-footer .copy-right {
  text-transform: none;
  color: #888888;
}

@media (max-width: 776px) {
  .bc-bottom-footer .copy-right {
    text-align: center;
    padding-bottom: 20px;
  }
}

.bc-bottom-footer .bc-bewaer-ft {
  text-align: right;
}

.bc-bottom-footer .bc-bewaer-ft a {
  display: inline-block;
}

@media (max-width: 776px) {
  .bc-bottom-footer .bc-bewaer-ft {
    text-align: center;
  }
}

/*=============================== CSS FOR MODAL / POPUP / LIGHTBOX ======================================================*/
.fancybox-close, #layer_cart .cross {
  width: 42px;
  height: 42px;
  font-size: 0;
  top: 0px;
  right: 0px;
  background: none;
  background: #555454 url("../images/close.png") no-repeat center;
  text-align: center;
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}

.fancybox-close:hover, #layer_cart .cross:hover {
  background: url("../images/close.png") no-repeat center #a0a91c;
}

.fancybox-skin {
  border-radius: 0;
}

/*Newsletter Popup*/
.newsletter-popup #newsletter-popup {
  height: 100%;
  overflow: hidden;
  background-size: cover !important;
}

@media (max-width: 767px) {
  .newsletter-popup #newsletter-popup {
    background-position: left center;
  }
}

.newsletter-popup .fancybox-skin {
  padding: 0 !important;
  overflow: hidden;
}

.newsletter-popup .fancybox-inner {
  overflow: visible !important;
}

.newsletter-popup .popup-image {
  float: left;
  position: absolute;
}

@media (max-width: 767px) {
  .newsletter-popup .popup-image {
    display: none;
  }
}

.newsletter-popup .title {
  background: none;
  border: none;
  padding: 0;
  margin: 0 0 20px;
  text-transform: none;
  font-size: 2.8571428571rem;
  line-height: normal;
  font-family: "Playball", cursive;
}

@media (max-width: 767px) {
  .newsletter-popup .title {
    text-align: center;
  }
}

.newsletter-popup .popup-mail-content {
  position: relative;
  z-index: 10;
  text-align: left;
  padding: 20px 40px;
  font-family: "Playball", cursive;
}

.newsletter-popup .popup-mail-content span {
  font-size: 20px;
}

@media (min-width: 767px) {
  .newsletter-popup .popup-mail-content {
    padding: 50px 0 0px 0;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
  }
}

@media (max-width: 767px) {
  .newsletter-popup .popup-mail-content {
    padding: 20px 30px;
    font-size: 13px;
  }
  .newsletter-popup .popup-mail-content .title:after {
    display: none;
  }
}

.newsletter-popup .block_content {
  padding: 0 20px;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
}

@media (min-width: 767px) {
  .newsletter-popup .block_content {
    padding: 30px 0 0;
    width: 469px;
    margin: 0 auto;
  }
}

.newsletter-popup .block_content form {
  position: relative;
}

.newsletter-popup .block_content .inputNew {
  min-height: 45px;
  padding: 0 10px;
  display: block;
  width: 100%;
  background: none;
  border: 1px solid #555454;
}

@media (min-width: 991px) {
  .newsletter-popup .block_content .inputNew {
    width: 370px;
  }
}

.newsletter-popup .block_content input[type="submit"] {
  height: 45px;
  color: #ffffff;
  background: #555454;
  line-height: 45px;
  padding: 0 10px;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.4s;
  border: none;
}

.newsletter-popup .block_content input[type="submit"]:hover {
  background: #a0a91c;
  color: #ffffff;
}

/*New Comment Popup*/
#new_comment_form .page-subheading {
  margin-top: 0;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  border-bottom: 2px solid #a0a91c;
}

#new_comment_form .product img {
  width: 70px;
  height: auto;
  float: left;
  border: none;
  margin-right: 10px;
}

#new_comment_form .product .product_desc .product_name {
  padding-top: 0;
  margin-bottom: 0;
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  text-transform: uppercase;
}

#new_comment_form .new_comment_form_content {
  background: none;
  padding-left: 0;
  padding-right: 15px;
}

@media (max-width: 767px) {
  #new_comment_form .new_comment_form_content {
    padding-left: 15px;
  }
}

#new_comment_form .new_comment_form_content label {
  text-transform: uppercase;
  font-size: 0.7142857143rem;
  font-family: "Montserrat", sans-serif;
}

#new_comment_form .new_comment_form_content #criterions_list {
  padding-bottom: 0;
}

#new_comment_form .new_comment_form_content .star_content {
  font-size: 0;
  float: none;
  line-height: 1;
}

#new_comment_form .new_comment_form_content .star_content .cancel {
  float: none;
  display: inline-block;
}

#new_comment_form .new_comment_form_content .star_content .star {
  vertical-align: bottom;
  line-height: 16px;
}

#new_comment_form #new_comment_form_footer {
  padding-top: 15px;
}

#new_comment_form #new_comment_form_footer .fr {
  margin-top: -8px;
}

#new_comment_form #new_comment_form_footer #submitNewMessage {
  border: none;
  height: 35px;
  padding: 0 10px;
  line-height: 35px;
}

#new_comment_form #new_comment_form_footer #submitNewMessage span {
  border: none;
}

#new_comment_form #new_comment_form_footer #submitNewMessage:hover span {
  background: none;
}

/*Layer Cart Popup*/
.layer_cart_overlay {
  z-index: 100;
  opacity: 0.8;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
}

#layer_cart {
  border-radius: 0;
  z-index: 102;
}

#layer_cart > div {
  overflow: hidden;
}

#layer_cart .cross:before {
  display: none;
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_product {
    padding: 20px;
  }
}

#layer_cart .layer_cart_product .title {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 1.2857142857rem;
  line-height: 1.4;
  color: #a0a91c;
}

#layer_cart .layer_cart_product .title i {
  margin-bottom: 20px;
}

#layer_cart .layer_cart_product .product-image-container {
  padding: 0;
  border: none;
}

#layer_cart .layer_cart_product .layer_cart_product_info {
  padding-top: 20px;
}

#layer_cart .layer_cart_product .layer_cart_product_info .product-name {
  text-align: left;
  font-size: 1.1428571429rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  margin-bottom: 0;
}

#layer_cart .layer_cart_product .layer_cart_product_info #layer_cart_product_price {
  font-weight: bold;
  color: #a0a91c;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  padding-left: 10px;
}

#layer_cart .layer_cart_cart {
  border-color: #eee;
  background: #fff;
  min-height: 0;
  position: relative;
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_cart {
    padding-left: 20px;
    padding-right: 20px;
  }
}

#layer_cart .layer_cart_cart .title {
  text-transform: uppercase;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  border-color: #eee;
}

#layer_cart .layer_cart_cart .button-container {
  padding: 30px;
}

#layer_cart .layer_cart_cart .button-container .continue {
  border: none;
  text-shadow: none;
  color: #fff;
  padding: 0 15px;
}

#layer_cart .layer_cart_cart .button-container .continue i {
  display: none;
}

#layer_cart .layer_cart_cart .button-container .continue span {
  background: none;
  padding: 0;
  border: none;
  font-size: 1rem;
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_cart .button-container .continue {
    width: 100%;
  }
}

#layer_cart .layer_cart_cart .button-container a.btn {
  float: right;
  border: none;
  padding: 0 15px;
}

#layer_cart .layer_cart_cart .button-container a.btn i {
  display: none;
}

#layer_cart .layer_cart_cart .button-container a.btn span {
  border: none;
  padding: 0;
}

@media (max-width: 1199px) and (min-width: 992px) {
  #layer_cart .layer_cart_cart .button-container a.btn {
    clear: both;
    float: left;
  }
}

@media (max-width: 767px) {
  #layer_cart .layer_cart_cart .button-container a.btn {
    float: left;
    width: 100%;
  }
}

#layer_cart .layer_cart_cart .layer_cart_row > span {
  font-weight: bold;
  color: #333333;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2857142857rem;
  padding-left: 10px;
}

#layer_cart .layer_cart_cart .layer_cart_row > span.ajax_block_cart_total {
  color: #a0a91c;
}

#layer_cart .layer_cart_cart .layer_cart_row strong {
  text-transform: uppercase;
  min-width: 130px;
  display: inline-block !important;
}

#layer_cart .crossseling h2, #layer_cart .crossseling .h2 {
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2857142857rem;
  margin-top: 0;
}

#layer_cart .crossseling .crossseling-content {
  border-color: #eee;
}

#layer_cart .crossseling #blockcart_list {
  width: 100%;
  max-width: 100%;
  overflow: visible;
}

#layer_cart .crossseling #blockcart_list .bx-wrapper {
  border: 1px solid #eee;
}

#layer_cart .crossseling #blockcart_list ul {
  *zoom: 1;
  margin-bottom: 0;
}

#layer_cart .crossseling #blockcart_list ul:after, #layer_cart .crossseling #blockcart_list ul:before {
  content: "";
  display: table;
  clear: both;
}

#layer_cart .crossseling #blockcart_list ul li {
  padding-bottom: 20px;
  text-align: center;
  border-right: 1px solid #eee;
}

#layer_cart .crossseling #blockcart_list ul li .product-image-container {
  border: none;
}

#layer_cart .crossseling #blockcart_list ul li .product-name {
  margin-bottom: 0;
  text-align: center;
}

#layer_cart .crossseling #blockcart_list ul li .price {
  font-size: 0.7142857143rem;
  font-family: "Montserrat", sans-serif;
  color: #a0a91c;
}

#layer_cart .crossseling #blockcart_list .bx-prev, #layer_cart .crossseling #blockcart_list .bx-next {
  display: block !important;
  width: 30px !important;
  height: 30px !important;
  text-indent: 0 !important;
  top: 50%;
  margin-top: -15px !important;
}

#layer_cart .crossseling #blockcart_list .bx-prev {
  left: -15px !important;
  margin-left: 0 !important;
}

#layer_cart .crossseling #blockcart_list .bx-prev:before {
  color: #eee;
}

#layer_cart .crossseling #blockcart_list .bx-prev:after {
  display: none !important;
}

#layer_cart .crossseling #blockcart_list .bx-next {
  right: -15px !important;
  margin-left: 0 !important;
}

#layer_cart .crossseling #blockcart_list .bx-next:before {
  color: #eee;
}

#layer_cart .crossseling #blockcart_list .bx-next:after {
  display: none !important;
}

/*Error Popup*/
.fancybox-error {
  padding-top: 20px;
  padding-bottom: 20px;
  white-space: normal;
}

/* CSS FOR MEGAMENU */
@media (max-width: 991px) {
  .container > .bc_menu_collapse {
    margin: 0 -15px;
  }
}

#header > .mega-menu {
  position: relative;
  background: none;
  margin-top: -35px;
}

@media (max-width: 991px) {
  #header > .mega-menu .menu-sticky {
    background: #a0a91c;
    border-radius: 10px;
  }
}

@media (max-width: 991px) {
  #header > .mega-menu .container {
    max-width: 720px;
    overflow: visible;
  }
}

@media (max-width: 767px) {
  #header > .mega-menu {
    height: 0;
    margin-top: -69px;
    padding: 0;
  }
  #header > .mega-menu .container {
    padding: 0 15px;
  }
  #header > .mega-menu nav {
    display: none;
  }
}

.bc-menu-container .title-menu-mobile {
  display: none;
  width: 40px;
  height: 40px;
  background: none;
  font-size: 0;
  text-align: center;
  line-height: 40px;
  color: #fff;
  cursor: pointer;
}

.bc-menu-container .title-menu-mobile:hover {
  background: #a0a91c;
}

.bc-menu-container .title-menu-mobile:before {
  content: "\f0c9";
  font-family: "FontAweSome";
  font-size: 20px;
}

@media (max-width: 767px) {
  .bc-menu-container .title-menu-mobile {
    display: inline-block;
    position: absolute;
    left: 15px;
    top: 22px;
  }
}

.bc-menu-container .menu-content {
  height: 65px;
  margin-bottom: 0;
}

.bc-menu-container .menu-content img {
  width: 100%;
}

.bc-menu-container .menu-content > li {
  display: inline-block;
  float: left;
  color: #fff;
  text-transform: uppercase;
  height: 100%;
  font-size: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  position: relative;
}

.bc-menu-container .menu-content > li a {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  display: block;
  font-family: "Open Sans", sans-serif;
}

.bc-menu-container .menu-content > li > a {
  height: 100%;
  line-height: 65px;
  padding: 0 30px;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  pointer-events: auto;
  color: #ffffff;
  font-weight: 600;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-content > li > a {
    padding: 0 20px;
  }
}

.bc-menu-container .menu-content > li:hover {
  background: #c1cc22;
  border-radius: 10px;
}

.bc-menu-container .menu-content > li:hover > a {
  color: #ffffff;
}

.bc-menu-container {
  /*Share properties between horizontal menu and vertical menu*/
  /*Horizontal Menu unique props*/
  /*Vertical menu unique props*/
}

.bc-menu-container .dropdown {
  display: none;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
}

.bc-menu-container .content-drop {
  *zoom: 1;
  padding-left: 0;
  padding-right: 0;
}

.bc-menu-container .content-drop:after, .bc-menu-container .content-drop:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .menu-column {
  padding: 0 25px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-column {
    padding: 0 20px;
  }
}

.bc-menu-container .column-item {
  *zoom: 1;
  color: #333333;
}

.bc-menu-container .column-item:after, .bc-menu-container .column-item:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container .column-item li:hover > .icon-drop-mobile {
  color: #a0a91c;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bc-menu-container .column-item li:hover > .column-item {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bc-menu-container .column-item a {
  padding: 10px 0;
  line-height: normal;
  text-transform: none;
  font-family: "Open Sans", sans-serif;
}

.bc-menu-container .column-item a:hover {
  color: #a0a91c;
}

.bc-menu-container .column-item h3, .bc-menu-container .column-item .h3 {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  margin-top: 0;
  margin-bottom: 15px;
}

.bc-menu-container .column-item .parent {
  position: relative;
}

.bc-menu-container .column-item .parent .icon-drop-mobile {
  position: absolute;
  right: 0;
  top: 12px;
  text-align: center;
}

.bc-menu-container .column-item .parent .icon-drop-mobile:before {
  content: "\f105";
  display: block;
  font-size: 15px;
}

.bc-menu-container .column-item .parent .column-item {
  position: absolute;
  left: calc(100% + 25px);
  top: 0;
  min-width: 195px;
  background: #fff;
  padding: 0 25px;
  visibility: hidden;
  opacity: 0;
  -webkit-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.bc-menu-container .column-item .parent .column-item:before {
  content: "";
  display: block;
  width: 30px;
  height: 100%;
  position: absolute;
  left: -30px;
  top: 0;
}

@media (max-width: 991px) {
  .bc-menu-container .column-item .parent .column-item {
    min-width: 140px;
    left: calc(100% + 20px);
  }
}

@media (max-width: 767px) {
  .bc-menu-container .column-item .parent .column-item {
    position: relative;
    left: 0;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
    box-shadow: none;
    background: none;
    transition: none !important;
  }
}

.bc-menu-container .menu-item:hover .dropdown {
  display: block;
}

.bc-menu-container .menu-item h3, .bc-menu-container .menu-item .h3 {
  padding-bottom: 10px;
  border-bottom: 1px dotted #333333;
}

.bc-menu-container .menu-item .ajax_block_product .left-block .product_img_link {
  padding: 0;
}

.bc-menu-container .menu-item .ajax_block_product .right-block .product-name {
  margin-bottom: 0;
  margin-top: 0;
}

.bc-menu-container .menu-item .ajax_block_product .right-block .product-name a {
  text-transform: uppercase;
  font-size: 0.8571428571rem;
}

.bc-menu-container .menu-item .ajax_block_product .right-block .content_price .product-price {
  font-size: 0.7142857143rem;
}

.bc-menu-container .menu-item.menu-v1 .content-drop {
  overflow: hidden;
}

.bc-menu-container .menu-item.menu-v1 .column-item {
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  border-right: 1px dotted #333333;
  padding-right: 25px;
}

.bc-menu-container .menu-item.menu-v1 .menu-column {
  padding-right: 0;
}

.bc-menu-container .menu-item.menu-v1 .menu-column:last-child .column-item {
  border: none;
}

.bc-menu-container .menu-item.menu-v2 .ajax_block_product .left-block {
  width: 100%;
  margin: 0;
}

.bc-menu-container .menu-item.menu-v2 .ajax_block_product .right-block {
  width: 100%;
}

.bc-menu-container .menu-item.menu-v2 .parent .icon-drop-mobile {
  display: none;
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item {
  position: relative;
  left: 0;
  top: 0;
  visibility: visible;
  opacity: 1;
  padding-left: 0;
  min-width: 0;
  white-space: nowrap;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item li a {
  padding: 5px 0;
  font-size: 0.8571428571rem;
  padding-left: 10px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-item.menu-v2 .parent .column-item li a {
    padding-left: 0;
  }
}

.bc-menu-container .menu-item.menu-v2 .parent .column-item li a:hover {
  color: #a0a91c;
  padding-left: 15px;
}

@media (max-width: 991px) {
  .bc-menu-container .menu-item.menu-v2 .parent .column-item li a:hover {
    padding-left: 0;
  }
}

.bc-menu-container .menu-item.menu-v3 img {
  margin-bottom: 20px;
}

.bc-menu-container .menu-item.menu-v4 .content-drop {
  overflow: hidden;
}

.bc-menu-container .menu-item.menu-v4 .column-item {
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  padding-right: 25px;
  border-right: 1px dotted #333333;
}

.bc-menu-container .menu-item.menu-v4 .menu-column {
  padding-right: 0;
}

.bc-menu-container .menu-item.menu-v4 .menu-column:last-child .column-item {
  border: none;
}

.bc-menu-container.megamenu_top {
  /*grid*/
  background: #a0a91c;
  border-radius: 10px;
}

.bc-menu-container.megamenu_top .col-sm-12 {
  width: 1170px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-12 {
    width: 970px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-12 {
    width: 720px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-12 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-11 {
  width: 1072.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-11 {
    width: 889.166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-11 {
    width: 660px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-11 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-10 {
  width: 975px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-10 {
    width: 808.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-10 {
    width: 600px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-10 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-9 {
  width: 877.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-9 {
    width: 727.5px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-9 {
    width: 540px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-9 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-8 {
  width: 780px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-8 {
    width: 646.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-8 {
    width: 480px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-8 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-7 {
  width: 682.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-7 {
    width: 565.833333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-7 {
    width: 420px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-7 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-6 {
  width: 585px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-6 {
    width: 485px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-6 {
    width: 360px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-6 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-5 {
  width: 487.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-5 {
    width: 404.166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-5 {
    width: 300px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-5 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-4 {
  width: 390px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-4 {
    width: 323.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-4 {
    width: 240px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-4 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-3 {
  width: 292.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-3 {
    width: 242.5px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-3 {
    width: 180px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-3 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-2 {
  width: 195px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-2 {
    width: 161.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-2 {
    width: 120px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-2 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .col-sm-1 {
  width: 97.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.megamenu_top .col-sm-1 {
    width: 80.8333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.megamenu_top .col-sm-1 {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.megamenu_top .col-sm-1 {
    width: 100%;
  }
}

.bc-menu-container.megamenu_top .dropdown {
  position: absolute;
  top: 100%;
  width: auto;
  left: 0;
  z-index: 100;
}

.bc-menu-container.megamenu_top .menu-item.menu-parent:hover:after {
  color: #a0a91c;
}

.bc-menu-container.menu_vertical {
  /*grid*/
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .container {
    overflow: visible;
    position: relative;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-menu-container.menu_vertical .mega-menu {
    padding: 0 !important;
  }
  .bc-menu-container.menu_vertical .title-menu {
    display: none;
  }
  .bc-menu-container.menu_vertical .menu-content > li > a {
    height: 33px;
    line-height: 33px;
    padding: 0 10px 0 0px !important;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical {
    display: none;
  }
}

.bc-menu-container.menu_vertical .col-sm-12 {
  width: 887px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-12 {
    width: 742px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-12 {
    width: 555px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-12 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-11 {
  width: 813.083333333px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-11 {
    width: 680.166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-11 {
    width: 508.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-11 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-10 {
  width: 739.166666667px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-10 {
    width: 618.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-10 {
    width: 462.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-10 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-9 {
  width: 665.25px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-9 {
    width: 556.5px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-9 {
    width: 416.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-9 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-8 {
  width: 591.333333333px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-8 {
    width: 494.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-8 {
    width: 370px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-8 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-7 {
  width: 517.416666667px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-7 {
    width: 432.833333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-7 {
    width: 323.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-7 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-6 {
  width: 443.5px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-6 {
    width: 371px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-6 {
    width: 277.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-6 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-5 {
  width: 369.583333333px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-5 {
    width: 309.166666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-5 {
    width: 231.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-5 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-4 {
  width: 295.666666667px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-4 {
    width: 247.333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-4 {
    width: 185px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-4 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-3 {
  width: 221.75px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-3 {
    width: 185.5px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-3 {
    width: 138.75px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-3 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-2 {
  width: 147.833333333px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-2 {
    width: 123.666666667px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-2 {
    width: 92.5px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-2 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .col-sm-1 {
  width: 73.9166666667px;
}

@media (max-width: 1199px) {
  .bc-menu-container.menu_vertical .col-sm-1 {
    width: 61.8333333333px;
  }
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .col-sm-1 {
    width: 46.25px;
  }
}

@media (max-width: 767px) {
  .bc-menu-container.menu_vertical .col-sm-1 {
    width: 100%;
  }
}

.bc-menu-container.menu_vertical .menu-content .menu-item {
  position: relative !important;
}

.bc-menu-container.menu_vertical .menu-content .menu-item:hover .dropdown {
  display: block;
}

.bc-menu-container.menu_vertical .menu-content .menu-item.menu-v2, .bc-menu-container.menu_vertical .menu-content .menu-item.menu-v3, .bc-menu-container.menu_vertical .menu-content .menu-item.menu-v4 {
  position: static;
}

.bc-menu-container.menu_vertical .menu-content .menu-item:before {
  display: inline-block;
  font-family: "FontAweSome";
  font-size: 14px;
  position: absolute;
  left: 2px;
  top: 10px;
  color: #a0a91c;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .menu-content .menu-item:before {
    line-height: 20px;
  }
}

.bc-menu-container.menu_vertical .dropdown {
  position: absolute;
  right: 100%;
  top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  z-index: 100;
  margin-left: 0 !important;
  -webkit-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
}

/*Mobile menu*/
.mobile-version.visible-xs.visible-sm {
  /*temporary hide mobile menu on tablet layout*/
}

@media (min-width: 768px) {
  .mobile-version.visible-xs.visible-sm {
    display: none !important;
  }
}

.menu_mobile {
  width: 280px;
  height: 100%;
  background: #333333;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  overflow: auto;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-transform: translate(-280px, 0);
  -ms-transform: translate(-280px, 0);
  -o-transform: translate(-280px, 0);
  transform: translate(-280px, 0);
}

.menu_mobile.opened {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.menu_mobile .container {
  padding-left: 0;
  padding-right: 0;
}

.menu_mobile .menu-content {
  height: auto;
  *zoom: 1;
  padding: 0 30px;
}

.menu_mobile .menu-content:after, .menu_mobile .menu-content:before {
  content: "";
  display: table;
  clear: both;
}

.menu_mobile .menu-content > li {
  display: block;
  width: 100%;
  border-bottom: 1px dotted grey;
  color: #fff;
}

.menu_mobile .menu-content > li .dropdown {
  clear: both;
  padding-top: 0;
  background: transparent;
  box-shadow: none;
}

.menu_mobile .menu-content > li .menu-column {
  padding: 0;
}

.menu_mobile .menu-content > li .column-item {
  position: relative;
  left: 0;
  top: 0;
  opacity: 1;
  padding: 0;
  visibility: visible;
  min-width: 0;
  padding-left: 10px;
  -webkit-transition-duration: 0s;
  -o-transition-duration: 0s;
  transition-duration: 0s;
  color: #fff;
  background: transparent;
  box-shadow: none;
}

.menu_mobile .menu-content > li .column-item li .column-item {
  display: none;
}

.menu_mobile .menu-content > li .icon-drop-mobile {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 6px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  z-index: 10;
}

.menu_mobile .menu-content > li .icon-drop-mobile:before {
  content: "\f0d7";
  font-family: "FontAweSome";
  font-size: 13px;
  color: #fff;
}

.menu_mobile .menu-content > li .icon-drop-mobile.opened:before {
  content: "\f0d8";
}

.menu_mobile .menu-content > li a {
  color: inherit;
}

.menu_mobile .menu-content > li a:hover {
  color: #a0a91c;
}

.menu_mobile .menu-content > li > a {
  line-height: 40px;
  display: block;
  float: left;
  width: 100%;
  padding: 0;
}

.menu_mobile .menu-content > li:last-child {
  border: none;
}

.menu_mobile .menu-content > li:hover .dropdown {
  display: none;
}

.menu_mobile .menu-content > li:hover {
  background: none;
  border-radius: 0;
}

.menu_mobile .title-menu {
  background: #a0a91c;
  text-align: center;
  border-bottom: none;
}

.menu_mobile .title-menu span {
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-transform: uppercase;
  padding: 10px 15px;
  display: block;
}

.menu_mobile nav {
  border-top: none;
  margin-left: 0;
  margin-right: 0;
}

.social-header {
  position: absolute;
  top: 0;
  right: 15px;
  width: auto;
}

.social-header ul {
  margin: 0;
  padding: 0;
  line-height: 60px;
}

.social-header ul li {
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 0;
}

.social-header ul li a {
  display: inline-block;
  font-size: 0;
}

.social-header ul li a:before {
  display: inline-block;
  font-family: "FontAweSome";
  color: #ffffff;
  border: none;
  width: 20px;
  height: 20px;
  line-height: 28px;
  font-size: 14px !important;
  text-align: center;
  font-weight: normal;
}

.social-header ul li a label {
  font-size: 0;
}

.social-header ul li a:hover:before {
  background: #a0a91c;
  color: #ffffff;
  border-color: #a0a91c;
}

.social-header ul .facebook a:before {
  content: "\f09a";
}

.social-header ul .twitter a:before {
  content: "\f099";
}

.social-header ul .google-plus a:before {
  content: "\f0d5";
}

.social-header ul .dribbble a:before {
  content: "\f17d";
}

.social-header ul .linkedin a:before {
  content: "\f0e1";
}

@media (max-width: 767px) {
  .social-header {
    display: none;
  }
}

#slider_row {
  margin-top: 20px;
}

#slider_row .col-sm-12, #slider_row .col-xs-12 {
  padding: 0;
}

@media (max-width: 767px) {
  #slider_row .col-sm-12, #slider_row .col-xs-12 {
    padding: 0 15px;
  }
}

#slider_row .ls-l.very_big_white {
  color: #a0a91c !important;
}

@media (max-width: 767px) {
  #slider_row .ls-l.very_big_white {
    letter-spacing: 0 !important;
  }
}

.ls-slide .ls-bg {
  border-radius: 10px !important;
}

.ls-active, .ls-animating {
  border-radius: 10px;
}

#full-slider-wrapper {
  padding-bottom: 15px;
}

#full-slider-wrapper .ls-slide .ls-l.big_black {
  color: #333;
  font-size: 30px;
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  letter-spacing: 0;
}

#full-slider-wrapper .ls-slide .ls-l.big_black:after, #full-slider-wrapper .ls-slide .ls-l.big_black:before {
  display: none;
}

#full-slider-wrapper .ls-slide .ls-l.large_text {
  text-transform: uppercase;
  font-size: 36px;
  text-shadow: none;
}

#full-slider-wrapper .ls-slide .ls-l.very_big_white {
  font-size: 60px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
}

#full-slider-wrapper .ls-slide .ls-l.small_text {
  font-size: 13px;
  color: #000;
  text-transform: none;
  text-align: left;
}

@media (max-width: 380px) {
  #full-slider-wrapper .ls-slide .ls-l.small_text {
    display: none !important;
  }
}

#full-slider-wrapper .ls-slide .ls-l.small_text:hover {
  background: #a0a91c;
  color: #ffffff;
}

#full-slider-wrapper .ls-slide .ls-l.medium_text {
  padding: 0px 30px;
  display: block;
  font-size: 100% !important;
  background: #a0a91c;
}

@media (min-width: 991px) {
  #full-slider-wrapper .ls-slide .ls-l.medium_text {
    height: 50px !important;
    line-height: 50px !important;
  }
}

@media (max-width: 991px) {
  #full-slider-wrapper .ls-slide .ls-l.medium_text {
    min-width: 0;
    padding: 0 15px;
  }
  #full-slider-wrapper .ls-slide .ls-l.medium_text span {
    font-size: 14px !important;
  }
}

@media (max-width: 380px) {
  #full-slider-wrapper .ls-slide .ls-l.medium_text {
    padding: 0 10px !important;
    line-height: 25px !important;
    height: 25px !important;
  }
}

#full-slider-wrapper .ls-slide .ls-l.medium_text:hover {
  background: #333;
}

@media (max-width: 767px) {
  #full-slider-wrapper .ls-slide .ls-l.small_thin_grey {
    font-size: inherit;
  }
}

@media (max-width: 380px) {
  #full-slider-wrapper .ls-slide .ls-l.small_thin_grey {
    display: none !important;
  }
}

#full-slider-wrapper .ls-slide .ls-l.big_orange {
  text-transform: none;
  background-color: transparent;
}

@media (max-width: 991px) {
  #full-slider-wrapper .ls-slide .ls-l.big_orange {
    font-size: 25px;
    line-height: 1;
  }
}

@media (max-width: 320px) {
  #full-slider-wrapper .ls-slide .ls-l.big_orange {
    font-size: 20px;
    text-align: center;
  }
}

@media (max-width: 1199px) {
  #full-slider-wrapper .ls-slide:nth-child(2) .ls-l.small_text {
    margin-top: 10px !important;
  }
}

@media (max-width: 991px) {
  #full-slider-wrapper .ls-slide:nth-child(2) .ls-l.small_text {
    font-size: 13px;
    line-height: 1;
    width: 60%;
    white-space: normal;
  }
}

@media (max-width: 767px) {
  #full-slider-wrapper .ls-slide:nth-child(2) .ls-l.small_text {
    display: none !important;
  }
}

.ls-nav-prev,
.ls-nav-next {
  display: none !important;
  position: absolute;
  background: #fff;
  display: block;
  width: 75px;
  height: 75px;
  border-radius: 20px;
  line-height: 75px !important;
  z-index: 3;
  top: 50%;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
  -webkit-transition: all .35s !important;
  -o-transition: all .35s !important;
  transition: all .35s !important;
}

.ls-nav-prev:before,
.ls-nav-next:before {
  content: "";
  font-family: "FontAweSome";
  display: block;
  font-size: 48px;
  color: #a0a91c;
}

.ls-nav-prev:hover,
.ls-nav-next:hover {
  background: #a0a91c;
}

.ls-nav-prev:hover:before,
.ls-nav-next:hover:before {
  color: #fff;
}

@media (max-width: 767px) {
  .ls-nav-prev:before,
  .ls-nav-next:before {
    font-size: 14px;
  }
}

.ls-nav-prev {
  left: 2%;
  margin-left: 30%;
}

.ls-nav-prev:before {
  content: "\f104";
}

@media (max-width: 991px) {
  .ls-nav-prev {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
}

@media (max-width: 767px) {
  .ls-nav-prev {
    width: 30px;
    height: 30px;
    line-height: 30px !important;
  }
}

.ls-nav-next {
  right: 2%;
  margin-right: 30%;
}

.ls-nav-next:before {
  content: "\f105";
}

@media (max-width: 991px) {
  .ls-nav-next {
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
    width: 40px;
    height: 40px;
    line-height: 40px !important;
  }
}

@media (max-width: 767px) {
  .ls-nav-next {
    width: 30px;
    height: 30px;
    line-height: 30px !important;
  }
}

#layerslider:hover .ls-nav-prev,
#layerslider:hover .ls-nav-next {
  opacity: 1;
  margin-left: 0;
  margin-right: 0;
}

#layerslider .ls-l.small_text:hover {
  background: #a0a91c;
  color: #ffffff;
}

@media (max-width: 767px) {
  .ls-bottom-nav-wrapper {
    display: none;
  }
}

.ls-bottom-nav-wrapper .ls-bottom-slidebuttons {
  top: -40px !important;
  margin-top: 10px;
}

.ls-bottom-nav-wrapper .ls-bottom-slidebuttons a {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #fff;
  margin: 0 7px;
  border: 2px solid rgba(255, 255, 255, 0.5) !important;
}

.ls-bottom-nav-wrapper .ls-bottom-slidebuttons a:hover, .ls-bottom-nav-wrapper .ls-bottom-slidebuttons a.ls-nav-active {
  background: #a0a91c;
}

.title-menu-home, .page-heading, .bc-bottom-home .block-title h3, .bc-bottom-home .block-title .h3, .bc-bottom-home .block-title span, .bc-bottom-home .bc-lastest-post .title_block h3, .bc-bottom-home .bc-lastest-post .title_block .h3, .bc-bottom-home .bc-lastest-post .title_block span, .bc-footer-home .bc-lastest-post .title_block, .bc-menu-container.menu_vertical .title-menu, .bc-productwithcategory .title_block h2, .bc-productwithcategory .title_block .h2, #left_column .title_block {
  text-transform: normal;
  color: #242424;
  padding: 0 20px 0 35px;
  position: relative;
  font-family: "Playball", cursive;
  font-size: 30px;
}

.title-menu-home:before, .page-heading:before, .bc-bottom-home .block-title h3:before, .bc-bottom-home .block-title .h3:before, .bc-bottom-home .block-title span:before, .bc-bottom-home .bc-lastest-post .title_block h3:before, .bc-bottom-home .bc-lastest-post .title_block .h3:before, .bc-bottom-home .bc-lastest-post .title_block span:before, .bc-footer-home .bc-lastest-post .title_block:before, .bc-menu-container.menu_vertical .title-menu:before, .bc-productwithcategory .title_block h2:before, .bc-productwithcategory .title_block .h2:before, #left_column .title_block:before {
  content: "";
  position: absolute;
  top: 15px;
  left: 0;
  width: 25px;
  height: 50px;
  line-height: 50px;
  border: none;
  background: url(../images/icon-clover.png) left center no-repeat;
  z-index: 10;
}

@media (max-width: 991px) and (min-width: 768px) {
  .title-menu-home, .page-heading, .bc-bottom-home .block-title h3, .bc-bottom-home .block-title .h3, .bc-bottom-home .block-title span, .bc-bottom-home .bc-lastest-post .title_block h3, .bc-bottom-home .bc-lastest-post .title_block .h3, .bc-bottom-home .bc-lastest-post .title_block span, .bc-footer-home .bc-lastest-post .title_block, .bc-menu-container.menu_vertical .title-menu, .bc-productwithcategory .title_block h2, .bc-productwithcategory .title_block .h2, #left_column .title_block {
    font-size: 25px;
  }
}

.title_block, .block-title {
  text-align: left;
}

.title_block h3, .title_block .h3, .block-title h3, .block-title .h3 {
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 30px;
  padding-bottom: 20px;
  margin: 10px 0 40px;
  background: url("../images/bg-title-block.png") no-repeat center bottom;
}

.title_block span, .block-title span {
  color: #888888;
  font-style: italic;
}

@media (max-width: 767px) {
  .title_block, .block-title {
    float: none;
    padding: 0;
    position: relative;
  }
  .title_block h3, .title_block .h3, .block-title h3, .block-title .h3 {
    font-size: 20px;
  }
}

#page .columns-container {
  padding-top: 0;
}

#index .tab-content {
  margin: 0;
}

.block-title {
  text-align: center;
  margin: 0 0 50px;
}

.block-title h3, .block-title .h3 {
  margin: 10px 0 30px;
  padding: 0 0 20px;
}

.block-title p {
  color: #888888;
  font-style: italic;
}

.bc-center-home .bc-banner-tophome {
  padding-top: 10px;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul {
  *zoom: 1;
  margin-bottom: 0;
  overflow: hidden;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul:after, .bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul:before {
  content: "";
  display: table;
  clear: both;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper figure {
  position: relative;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper figure > a > img {
  width: 100%;
  transition: 1s;
  border-radius: 8px;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper figure > a:after {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  background: #a0a91c;
  opacity: 0.9;
  position: absolute;
  left: 0;
  z-index: 1;
  transition: 0.3s;
  border-radius: 8px;
  transform: scaleY(1);
  transition: all 0.7s ease-in-out;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper figure figcaption {
  position: absolute;
  top: 0;
  text-align: center;
  width: 100%;
  left: 0;
  text-transform: uppercase;
  z-index: 2;
  transform: scaleY(1);
  transition: all 0.7s ease-in-out;
}

@media (max-width: 767px) {
  .bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper {
    margin-bottom: 20px;
  }
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper:hover figure > a:after {
  transform: scale(10);
  opacity: 0;
}

.bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul li .banner-wraper:hover figure figcaption {
  transform: scale(10);
  opacity: 0;
}

@media (max-width: 767px) {
  .bc-center-home .bc-banner-tophome .banner-tophome-wrapper ul {
    height: auto;
    overflow: visible;
  }
}

.bc-center-home .new-arrival {
  margin-top: 80px;
}

.bc-top-center {
  background: #f9f9f9;
  padding: 40px 0 60px;
}

.bc-top-center .block-content .out-prod-filter {
  background: #fff;
  border: 1px solid #eee;
  margin: 0 15px;
  *zoom: 1;
}

.bc-top-center .block-content .out-prod-filter:after, .bc-top-center .block-content .out-prod-filter:before {
  content: "";
  display: table;
  clear: both;
}

.bc-top-center .block-content .out-prod-filter .bc-filter-product {
  *zoom: 1;
}

.bc-top-center .block-content .out-prod-filter .bc-filter-product .tab-content {
  border: none;
}

@media (max-width: 767px) {
  .bc-top-center .block-content .out-prod-filter .bc-filter-product .tab-content {
    border: 1px solid #eee;
  }
}

.bc-top-center .block-content .out-prod-filter .bc-filter-product:after, .bc-top-center .block-content .out-prod-filter .bc-filter-product:before {
  content: "";
  display: table;
  clear: both;
}

.bc-top-center .block-content .out-prod-filter .bc-filter-product .item {
  float: left;
  width: 33.3%;
}

@media (max-width: 767px) {
  .bc-top-center .block-content .out-prod-filter .bc-filter-product .item {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .bc-top-center .block-content .out-prod-filter .bc-filter-product .item {
    width: 100%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-top-center .block-content .out-prod-filter .bc-filter-product .item {
    width: 50%;
  }
}

.bc-top-center .block-content .out-prod-filter .bc-item {
  background: #fff;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container {
  border: none;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block {
  padding: 0 30px 30px;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .product-name a {
  font-size: 18px;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .price.product-price, .bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .old-price.product-price {
  font-size: 15px;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .comments_note {
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 40px;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .button-container {
  margin: 30px 0 0;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .button-container .button span {
  font-weight: normal;
  font-size: 12px;
}

.bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .button-container .button:before {
  content: "\f07a";
  font-family: "FontAweSome";
  display: inline-block;
  padding-right: 6px;
}

@media (max-width: 767px) {
  .bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block {
    padding: 0 0 30px;
  }
  .bc-top-center .block-content .out-prod-filter .bc-item .item .product-container .left-block .comments_note {
    right: 15px;
  }
}

@media (max-width: 767px) {
  .bc-top-center .block-content .out-prod-filter .bc-item .item .product-container {
    padding: 0 15px;
    border: 1px solid #eee;
  }
}

@media (max-width: 767px) {
  .bc-top-center .block-content .out-prod-filter {
    background: none;
    margin: 0;
    border: none;
  }
}

.bc-center-home {
  margin: 0;
}

.bc-center-home .bc-productwithcategory .tab-content {
  border: none;
}

.bc-center-home .bc-productwithcategory .tab-content .item {
  border: none;
}

@media (max-width: 767px) {
  .bc-center-home .bc-productwithcategory .tab-content .item {
    float: none;
    display: block;
  }
}

.bc-center-home .bc-productwithcategory .tab-content .owl-item {
  border: 1px solid #eee;
  border-right: none;
}

.bc-center-home .bc-productwithcategory .tab-content .owl-item.last {
  border-right: 1px solid #eee;
}

.bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav {
  text-align: left;
}

.bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-prev, .bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  left: -15px;
  margin: 0 !important;
}

.bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-next {
  left: auto;
  right: -15px;
}

@media (max-width: 767px) {
  .bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-next {
    right: -10px;
  }
  .bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-prev {
    left: -10px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-next {
    right: 0px;
  }
  .bc-center-home .bc-productwithcategory .tab-content .owl-controls .owl-nav .owl-prev {
    left: 0;
  }
}

.bc-bottom-home {
  margin: 60px 0;
}

.bc-bottom-home .bc-productwithcategory {
  padding: 0;
}

.bc-bottom-home .col-sm-3 .product_list {
  border: 2px solid #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.bc-bottom-home .bc-banner-topcenter, .bc-bottom-home .bc-lastest-post {
  text-align: center;
  border: 2px solid #f4f4f4;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.bc-bottom-home .bc-banner-topcenter .comment-item .avatar img, .bc-bottom-home .bc-lastest-post .comment-item .avatar img {
  width: auto;
  text-align: center;
  display: inline-block;
}

.bc-bottom-home .bc-banner-topcenter .comment-item .right-item, .bc-bottom-home .bc-lastest-post .comment-item .right-item {
  margin: 20px 0 0;
}

.bc-bottom-home .bc-banner-topcenter .comment-item .right-item .name, .bc-bottom-home .bc-lastest-post .comment-item .right-item .name {
  color: #a0a91c;
  font-family: "Playball", cursive;
}

.bc-bottom-home .bc-banner-topcenter .comment-item .right-item .sub-name, .bc-bottom-home .bc-lastest-post .comment-item .right-item .sub-name {
  margin-bottom: 10px;
}

.bc-bottom-home .bc-banner-topcenter .comment-item .right-item .content, .bc-bottom-home .bc-lastest-post .comment-item .right-item .content {
  color: #888888;
  line-height: 24px;
  margin-bottom: 10px;
}

.bc-bottom-home .bc-lastest-post .bt-view-more-post {
  text-transform: capitalize;
  color: #a0a91c;
  display: none;
}

.bc-bottom-home .bc-lastest-post .bc-item {
  border-bottom: 1px solid #f4f4f4;
  padding: 0 0 15px;
  margin: 0 0 15px;
  text-align: left;
  *zoom: 1;
}

.bc-bottom-home .bc-lastest-post .bc-item:after, .bc-bottom-home .bc-lastest-post .bc-item:before {
  content: "";
  display: table;
  clear: both;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-img {
  display: block;
  float: left;
  width: 30%;
  margin-right: 5%;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content {
  float: left;
  width: 65%;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content h3, .bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .h3 {
  margin: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  line-height: 18px;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content h3 a:hover, .bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .h3 a:hover {
  color: #a0a91c;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .post_description {
  display: none;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment {
  color: #888888;
  font-family: "Open Sans", sans-serif;
  margin-top: 5px;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p {
  display: inline-block;
  float: left;
  font-size: 14px;
  margin: 0;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p:after {
  content: "|";
  padding-left: 2px;
  padding-right: 2px;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p.comment-count {
  display: none;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p.author:after {
  content: none;
  padding: 0;
}

.bc-bottom-home .bc-lastest-post .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p.author span:first-child {
  display: none;
}

.bc-bottom-home .bc-lastest-post .bc-item:last-child {
  border: none;
  padding: 0;
}

.bc-bottom-home .bc-banner-top {
  margin: 10px 0 30px;
  border: 2px solid #f4f4f4;
  padding: 0;
  border-radius: 10px;
  position: relative;
}

.bc-bottom-home .bc-banner-top img {
  border-radius: 10px;
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-banner-top img {
    display: none;
  }
}

.bc-bottom-home .bc-banner-top .wraper-inner {
  text-align: center;
}

@media (min-width: 991px) {
  .bc-bottom-home .bc-banner-top .wraper-inner {
    width: 60%;
    position: absolute;
    top: 50px;
    left: 50px;
  }
}

.bc-bottom-home .bc-banner-top .wraper-inner h3, .bc-bottom-home .bc-banner-top .wraper-inner .h3 {
  color: #a0a91c;
  font-size: 35px;
  font-family: "Playball", cursive;
  margin: 0;
}

.bc-bottom-home .bc-banner-top .wraper-inner p {
  color: #888888;
  line-height: 24px;
}

.bc-bottom-home .bc-banner-top .wraper-inner a {
  border-radius: 10px;
  background: #d7e14a;
  color: #ffffff;
  line-height: 40px;
  padding: 0 30px;
  display: inline-block;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

.bc-bottom-home .bc-banner-top .wraper-inner a:hover {
  background: #a0a91c;
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-banner-top {
    padding: 30px !important;
  }
}

.bc-bottom-home .bc-banner-bottom-home {
  background: none;
  *zoom: 1;
  padding-left: 0;
  margin-bottom: 0;
}

.bc-bottom-home .bc-banner-bottom-home:after, .bc-bottom-home .bc-banner-bottom-home:before {
  content: "";
  display: table;
  clear: both;
}

.bc-bottom-home .bc-banner-bottom-home .wraper-inner {
  float: right;
  right: 50px;
  left: auto;
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-banner-bottom-home .wraper-inner {
    float: none;
  }
}

.bc-bottom-home .bc-banner-bottom-home .wraper-inner h3, .bc-bottom-home .bc-banner-bottom-home .wraper-inner .h3 {
  color: #333333;
  margin-bottom: 20px;
}

.bc-bottom-home .block-title, .bc-bottom-home .bc-lastest-post .title_block {
  margin-bottom: 30px;
  margin-top: 0;
}

.bc-bottom-home .block-title h3, .bc-bottom-home .block-title .h3, .bc-bottom-home .block-title span, .bc-bottom-home .bc-lastest-post .title_block h3, .bc-bottom-home .bc-lastest-post .title_block .h3, .bc-bottom-home .bc-lastest-post .title_block span {
  text-transform: none;
  font-weight: normal;
  margin: 0;
  text-align: left;
  background: none;
}

.bc-bottom-home .block-title h3:before, .bc-bottom-home .block-title .h3:before, .bc-bottom-home .block-title span:before, .bc-bottom-home .bc-lastest-post .title_block h3:before, .bc-bottom-home .bc-lastest-post .title_block .h3:before, .bc-bottom-home .bc-lastest-post .title_block span:before {
  top: 0;
  height: 30px;
  line-height: 30px;
}

.bc-bottom-home .bc-bestsellerproduct .bc-filter-product .product-container {
  margin: 0 0 10px;
  border: none;
}

.bc-bottom-home .bc-bestsellerproduct .bc-filter-product .product-container .right-block {
  text-align: center;
  padding: 30px 0 0;
}

.bc-bottom-home .bc-bestsellerproduct .bc-filter-product .product-container .right-block .product-name {
  text-align: center;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container {
  margin: 0 0 15px;
  padding: 0 0 15px;
  border: none;
  border-bottom: 1px solid #f4f4f4;
  *zoom: 1;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container:after, .bc-bottom-home .bc-specialproduct .bc-filter-product .product-container:before {
  content: "";
  display: table;
  clear: both;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container .left-block {
  float: left;
  width: 30%;
  margin-right: 5%;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container .left-block .button-container {
  display: none;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container .right-block {
  text-align: left;
  float: left;
  width: 65%;
  padding: 10px 0 0;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container .right-block .comments_note {
  text-align: left;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container .right-block .product-name {
  text-align: left;
}

.bc-bottom-home .bc-specialproduct .bc-filter-product .product-container:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-specialproduct {
    margin-bottom: 50px;
  }
}

.bc-bottom-home .bc-productwithcategory .tab-content .item {
  border: none;
  display: block;
  width: 33.3%;
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .item {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .item {
    width: 100%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .item {
    width: 33.3%;
  }
  .bc-bottom-home .bc-productwithcategory .tab-content .item:nth-child(3n+1) {
    clear: both;
  }
  .bc-bottom-home .bc-productwithcategory .tab-content .item:nth-child(4n+1) {
    clear: none;
  }
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container {
  background: none;
  *zoom: 1;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container:after, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container:before {
  content: "";
  display: table;
  clear: both;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .product-image-container {
  padding: 0;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .product-image-container .sale-box {
  display: none;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  z-index: 20;
  background: none;
  transform: translate(-50%, -50%);
  padding: 0;
  min-width: auto;
  height: auto;
  line-height: 0;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span {
  padding: 0;
  box-shadow: none;
  font-size: 0;
  text-align: center;
  display: inline-block;
  visibility: hidden;
  backface-visibility: visible !important;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span:before {
  content: "\f06e";
  font-family: "FontAweSome";
  line-height: 30px;
  color: #a0a91c;
  display: inline-block;
  font-size: 14px;
  line-height: 40px;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .lnk_view span:hover {
  padding: 0;
  background: #fff;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .functional-buttons .addToWishlist, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .functional-buttons .ajax_add_to_cart_button, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .functional-buttons .add_to_compare, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block .button-container .functional-buttons .quick-view span {
  transition: none;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .product-name {
  text-align: left;
  font-size: 14px;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .comments_note {
  text-align: left;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container {
  text-align: left;
  margin: 0;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container .functional-buttons {
  display: block;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container .functional-buttons .ajax_add_to_cart_button {
  width: auto;
  padding: 0 15px;
  margin: 0;
  font-size: 10px !important;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container .functional-buttons .ajax_add_to_cart_button:before {
  display: inline-block;
  padding-right: 6px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container .functional-buttons .ajax_add_to_cart_button {
    padding: 0 5px;
    height: 25px;
    line-height: 25px;
  }
  .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .button-container .functional-buttons .ajax_add_to_cart_button:before {
    line-height: inherit;
  }
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .price.product-price, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block .old-price.product-price {
  font-size: 14px;
}

.bc-bottom-home .bc-productwithcategory .tab-content .item .product-container:hover .lnk_view span {
  animation-name: fadeInDown;
  visibility: visible !important;
  animation-duration: .5s;
  animation-delay: 0s;
  animation-fill-mode: both;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .left-block, .bc-bottom-home .bc-productwithcategory .tab-content .item .product-container .right-block {
    float: none;
    width: 100%;
    margin: 0;
  }
}

.bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev, .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-next {
  position: absolute;
  top: -90px;
  right: 0;
  background: #f9f9f9;
}

.bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev:hover, .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-next:hover {
  background: #a0a91c;
}

.bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev {
  right: 40px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev, .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-next {
    top: -76px;
    width: 20px;
    height: 20px;
  }
  .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev:before, .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-next:before {
    line-height: 19px;
  }
  .bc-bottom-home .bc-productwithcategory .tab-content .owl-controls .owl-prev {
    right: 20px;
  }
}

.bc-bottom-home .col-sm-9 .bc-filterproduct .owl-controls {
  position: absolute;
  top: -65px;
  right: 0;
}

.bc-bottom-home .bc-banner-bottomhome .item-wrapper {
  background: #a0a91c;
  color: #ffffff;
  text-align: center;
  padding: 30px 20px;
  border-radius: 10px;
  position: relative;
  font-size: 15px;
}

.bc-bottom-home .bc-banner-bottomhome .item-wrapper b {
  display: block;
  font-size: 30px;
  font-family: "Playball", cursive;
  font-weight: normal;
  margin: 0 0 10px;
}

.bc-bottom-home .bc-banner-bottomhome .item-wrapper span {
  display: block;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  margin-top: 10px;
  overflow: hidden;
  color: #e1e876;
}

.bc-bottom-home .bc-banner-bottomhome .item-wrapper:before {
  content: "\f18c";
  font-family: "FontAweSome";
  position: absolute;
  top: 20px;
  left: 0px;
  font-size: 150px;
  color: #ffffff;
  opacity: 0.2;
  z-index: 2;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-bottom-home .bc-banner-bottomhome .item-wrapper {
    padding: 60px 20px;
  }
}

@media (max-width: 767px) {
  .bc-bottom-home .bc-banner-bottomhome {
    margin-bottom: 50px;
  }
  .bc-bottom-home .bc-banner-bottomhome .container {
    padding: 0;
  }
}

.bc-footer-home {
  margin-bottom: 30px;
}

.bc-footer-home .manufacturer-container {
  padding-top: 40px;
}

.bc-footer-home .bc-banner-bottom-wraper .owl-controls {
  position: absolute;
  bottom: 30px;
  left: 65px;
}

@media (max-width: 767px) {
  .bc-footer-home .bc-banner-bottom-wraper .owl-controls {
    left: 40%;
  }
}

.bc-footer-home .bc-banner-bottom-wraper .owl-controls .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border: 2px solid #888888;
  background: #888888;
  margin: 0 0 0 5px;
}

.bc-footer-home .bc-banner-bottom-wraper .owl-controls .owl-dots .owl-dot.active span {
  background: #fff;
  border: 2px solid #a0a91c;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item {
  border: 2px solid #eee;
  padding: 60px 0;
  *zoom: 1;
  background: url(../images/bgcustomer.jpg) right bottom no-repeat;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item:after, .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item:before {
  content: "";
  display: table;
  clear: both;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .avatar {
  float: left;
  width: 20%;
  margin-right: 2%;
  text-align: center;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .avatar img {
  width: auto;
  display: inline-block;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item {
  float: left;
  width: 78%;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  padding-right: 250px;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item .name {
  font-family: "Playball", cursive;
  font-size: 20px;
  color: #a0a91c;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item .sub-name {
  margin: 5px 0 10px;
}

.bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item .content {
  color: #888888;
  line-height: 18px;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item {
    padding: 60px 0 60px 20px;
  }
  .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item {
    padding-right: 50px;
  }
}

@media (max-width: 767px) {
  .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item {
    background: none;
  }
  .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item, .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .avatar {
    float: none;
    width: auto;
    margin: 0;
    text-align: center;
  }
  .bc-footer-home .bc-banner-bottom-wraper ul li.comment-item .right-item {
    margin-top: 30px;
    padding: 0 20px;
  }
}

.bc-footer-home .bc-lastest-post {
  margin: 60px 0;
}

.bc-footer-home .bc-lastest-post .title_block {
  text-align: left;
  margin: 0 0 30px;
  padding: 0 30px;
}

.bc-footer-home .bc-lastest-post .title_block:before {
  top: 0;
  height: 30px;
}

.bc-footer-home .bc-lastest-post .title_block span {
  color: #242424;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content {
  *zoom: 1;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content:after, .bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content:before {
  content: "";
  display: table;
  clear: both;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item {
  float: left;
  width: 33.3%;
  padding: 0 15px;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-img {
  position: relative;
  overflow: hidden;
  display: block;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-img:before {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  border: 2px solid #fff;
  opacity: 0;
  z-index: 1;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-img img {
  transition: 1s;
  transform: scale3d(1.1, 1.1, 1);
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content h3, .bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .h3 {
  font-size: 16px;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p {
  display: inline-block;
  color: #888888;
  font-size: 14px;
  margin-top: 5px;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p:after {
  content: "|";
  padding-left: 5px;
  color: #d8d8d8;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p:last-child:after {
  content: none;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .post_description {
  display: none;
}

@media (max-width: 767px) {
  .bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item {
    width: 100%;
    margin-bottom: 20px;
  }
  .bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item .bc-bloglastest-right-content .frame-date-author-comment > p {
    font-size: 12px;
  }
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item:hover .bc-bloglastest-img:before {
  opacity: 038;
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .bc-lastest-post-content .bc-item:hover .bc-bloglastest-img img {
  transform: scale(1);
}

.bc-footer-home .bc-lastest-post .bc-3-items-aline .lastest-post-button {
  display: none;
}

.bc-footer-home .manufacturer-container {
  border-top: 2px solid #eee;
}

.bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-prev, .bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-next {
  width: 42px;
  height: 42px;
  background: #fff;
  color: #888888;
  border: 2px solid #eee;
  line-height: 42px;
}

.bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-prev:before, .bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-next:before {
  line-height: 38px;
  font-size: 16px;
}

.bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-prev:hover, .bc-footer-home .manufacturer-container .owl-controls .owl-nav .owl-next:hover {
  background: #eee;
}

.bc-footer-home .owl-controls .owl-prev, .bc-footer-home .owl-controls .owl-next {
  position: absolute;
  top: 35%;
  left: -10px;
  margin: 0 !important;
}

.bc-footer-home .owl-controls .owl-next {
  right: -10px;
  left: auto;
}

@media (max-width: 767px) {
  .bc-footer-home .owl-controls .owl-prev, .bc-footer-home .owl-controls .owl-next {
    top: 30%;
  }
  .bc-footer-home .owl-controls .owl-next {
    right: 0px;
  }
  .bc-footer-home .owl-controls .owl-prev {
    border-bottom-left-radius: -10px;
    left: 0px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-footer-home .owl-controls .owl-prev {
    left: 0;
  }
  .bc-footer-home .owl-controls .owl-next {
    right: 0;
  }
}

@media (max-width: 767px) {
  .bc-footer-home .content-manufacture-block {
    padding: 0 15px;
  }
}

.bc-home .new-arrival {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 767px) {
  .bc-home > .container {
    padding: 0;
  }
}

.bc-banner-top img {
  width: 100%;
  height: auto;
}

.bc-banner-top .bc-services {
  text-align: right;
  color: #242424;
  position: absolute;
  z-index: 101;
  right: 30px;
  top: 48%;
  padding: 0;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  -o-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
}

.bc-banner-top .bc-services p, .bc-banner-top .bc-services h3, .bc-banner-top .bc-services .h3 {
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  margin: 0 0 20px;
}

.bc-banner-top .bc-services p {
  font-family: "Playfair Display", serif;
  font-size: 2.1428571429rem;
  font-style: italic;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-banner-top .bc-services p {
    font-size: 10px;
  }
}

.bc-banner-top .bc-services h3, .bc-banner-top .bc-services .h3 {
  text-transform: uppercase;
  line-height: 24px;
  color: #a0a91c;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-banner-top .bc-services h3, .bc-banner-top .bc-services .h3 {
    font-size: 14px;
  }
}

.bc-banner-top .banner-shopnow--btn {
  padding: 0 20px;
}

/*Vertical Menu*/
.bc-menu-container.menu_vertical {
  padding-left: 0;
}

.bc-menu-container.menu_vertical .title-menu {
  background: #fff;
  line-height: 60px;
  padding: 0 30px;
  margin: 0 20px;
}

.bc-menu-container.menu_vertical .title-menu:before {
  line-height: 60px;
  height: 60px;
  top: 0;
}

.bc-menu-container.menu_vertical .mega-menu {
  max-height: inherit;
  padding: 0 0 15px;
  background: #fff;
  position: relative;
  border-radius: 10px;
  border: 2px solid #f4f4f4;
}

.bc-menu-container.menu_vertical .menu-content {
  height: auto;
  padding: 0;
  position: relative;
  *zoom: 1;
}

.bc-menu-container.menu_vertical .menu-content:after, .bc-menu-container.menu_vertical .menu-content:before {
  content: "";
  display: table;
  clear: both;
}

.bc-menu-container.menu_vertical .menu-content > li {
  display: block;
  width: 100%;
  line-height: 0;
  background: #fff;
  color: #888888;
  position: relative;
  padding: 0 20px;
}

.bc-menu-container.menu_vertical .menu-content > li > a {
  padding: 0 0 0 20px;
  line-height: 45px;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  position: static;
  color: #888888;
  position: relative;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 10px;
  text-transform: none;
  border-bottom: 1px solid #f4f4f4;
  font-weight: normal;
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .menu-content > li > a {
    height: 33px;
    line-height: 33px;
    padding: 0 10px;
  }
}

.bc-menu-container.menu_vertical .menu-content > li > .icon-drop-mobile {
  display: inline-block;
  width: 40px;
  line-height: 45px;
  text-align: right;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

@media (max-width: 991px) {
  .bc-menu-container.menu_vertical .menu-content > li > .icon-drop-mobile {
    width: 30px;
    line-height: 40px;
    height: 40px;
    padding-right: 10px;
  }
}

.bc-menu-container.menu_vertical .menu-content > li > .icon-drop-mobile::after {
  content: "\f104";
  font-family: "FontAweSome";
  font-size: 18px;
  display: block;
}

.bc-menu-container.menu_vertical .menu-content > li:hover {
  background: none;
}

.bc-menu-container.menu_vertical .menu-content > li:hover > a, .bc-menu-container.menu_vertical .menu-content > li:hover > .icon-drop-mobile {
  color: #a0a91c;
}

.bc-menu-container.menu_vertical .menu-content > li:hover > a:after, .bc-menu-container.menu_vertical .menu-content > li:hover > .icon-drop-mobile:after {
  color: #a0a91c;
}

.bc-menu-container.menu_vertical .menu-content > li:last-child a {
  border: none;
}

.bc-menu-container.menu_vertical .menu-content > li .type-category > li > a {
  padding-left: 10px;
}

.bc-menu-container.menu_vertical .menu-content > li .type-category > li .icon-drop-mobile {
  left: -10px;
  right: auto;
  text-align: left;
}

.bc-menu-container.menu_vertical .menu-content > li .type-category > li .icon-drop-mobile:before {
  content: "\f104";
}

.bc-menu-container.menu_vertical .menu-content > li .type-category > li.parent .column-item {
  left: auto;
  right: calc(100% + 25px);
}

.index #slider-wrapper {
  padding-top: 0;
  padding-left: 15px;
  overflow: hidden;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .index #slider-wrapper {
    padding: 0;
  }
}

/*General tab content css*/
.tab-content .button.ajax_add_to_cart_button {
  vertical-align: top;
}

.bc-filterproduct {
  position: relative;
}

.bc-filterproduct .type-tab .nav-tabs {
  text-align: center;
  margin-bottom: 50px;
  border-bottom: none;
}

@media (max-width: 767px) {
  .bc-filterproduct .type-tab .nav-tabs {
    padding-right: 0;
    padding-bottom: 42px;
  }
}

.bc-filterproduct .type-tab .nav-tabs li {
  float: none;
  display: inline-block;
  text-align: center;
  background: transparent;
  margin-right: 20px;
}

.bc-filterproduct .type-tab .nav-tabs li a {
  text-transform: uppercase;
  background: #fff;
  color: #242424;
  border: 1px solid #f1f1f1;
  padding: 0 20px;
}

.bc-filterproduct .type-tab .nav-tabs li a:hover {
  background: #a0a91c;
}

.bc-filterproduct .type-tab .nav-tabs li:hover a, .bc-filterproduct .type-tab .nav-tabs li.active a {
  background: #a0a91c;
  color: #ffffff;
}

.bc-filterproduct .tab-content {
  margin: 0 !important;
  *zoom: 1;
}

.bc-filterproduct .tab-content:after, .bc-filterproduct .tab-content:before {
  content: "";
  display: table;
  clear: both;
}

.bc-filterproduct .item .product-container {
  position: relative;
  top: 0;
  z-index: auto;
}

@media (max-width: 767px) {
  .bc-filterproduct .item .product-container {
    margin: 0 0 40px;
  }
}

.bc-filterproduct .tab-pane .item {
  visibility: hidden;
}

.bc-filterproduct .tab-pane.active .item {
  visibility: visible;
  -webkit-backface-visibility: visible !important;
  -ms-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.bc-filterproduct .owl-controls .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  border: 2px solid #888888;
  background: #888888;
  margin: 0 0 0 5px;
}

.bc-filterproduct .owl-controls .owl-dots .owl-dot.active span {
  background: #fff;
  border: 2px solid #a0a91c;
}

@media (max-width: 767px) {
  .bc-filterproduct .owl-controls {
    right: 0;
  }
}

.bc-productwithcategory {
  margin: 0;
  padding: 0 15px;
}

.bc-productwithcategory .out-prod-filter {
  *zoom: 1;
}

.bc-productwithcategory .out-prod-filter:after, .bc-productwithcategory .out-prod-filter:before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory .block-content {
  position: relative;
}

.bc-productwithcategory .title_block {
  display: inline-block;
  float: left;
}

.bc-productwithcategory .title_block h2, .bc-productwithcategory .title_block .h2 {
  margin: 0;
  padding: 10px 30px;
}

.bc-productwithcategory .title_block h2:before, .bc-productwithcategory .title_block .h2:before {
  top: 0;
}

.bc-productwithcategory .type-tab {
  position: relative;
}

.bc-productwithcategory .type-tab .nav-tabs {
  float: right;
  border: none;
  margin-bottom: 30px;
}

.bc-productwithcategory .type-tab .nav-tabs > li {
  margin-bottom: 0;
  margin-left: 10px;
}

.bc-productwithcategory .type-tab .nav-tabs > li a {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  color: #242424;
  padding: 0 15px;
  line-height: 35px;
  background: #fff;
  border: 2px solid #e4e4e4;
  margin-right: 0;
  text-align: center;
  border-radius: 5px;
}

.bc-productwithcategory .type-tab .nav-tabs > li.active a {
  background: #a0a91c;
  border-color: #a0a91c;
  color: #ffffff;
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-productwithcategory .type-tab .nav-tabs > li {
    margin-left: 5px;
  }
  .bc-productwithcategory .type-tab .nav-tabs > li a {
    padding: 0 5px;
  }
}

.bc-productwithcategory .tab-content {
  margin-top: 0;
  border-top: none;
  *zoom: 1;
}

.bc-productwithcategory .tab-content:after, .bc-productwithcategory .tab-content:before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory .tab-content .item {
  display: inline-block;
  float: left;
  position: relative;
  border: none;
  width: 25%;
}

.bc-productwithcategory .tab-content .item:nth-child(3n+1) {
  clear: both;
}

.bc-productwithcategory .tab-content .item:nth-child(3n) {
  border-right: none;
}

.bc-productwithcategory .tab-content .item img {
  width: 100%;
}

@media (max-width: 767px) {
  .bc-productwithcategory .tab-content .item {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .bc-productwithcategory .tab-content .item {
    width: 100%;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .bc-productwithcategory .tab-content .item {
    width: 33.3%;
  }
  .bc-productwithcategory .tab-content .item:nth-child(3n+1) {
    clear: both;
  }
  .bc-productwithcategory .tab-content .item:nth-child(4n+1) {
    clear: none;
  }
}

.bc-productwithcategory .tab-content .bc_banner ul {
  *zoom: 1;
  margin: 0;
}

.bc-productwithcategory .tab-content .bc_banner ul:after, .bc-productwithcategory .tab-content .bc_banner ul:before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory .tab-content > .bc-filter-product {
  margin: 0 -5px;
  *zoom: 1;
}

.bc-productwithcategory .tab-content > .bc-filter-product:after, .bc-productwithcategory .tab-content > .bc-filter-product:before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory.bc-prowithbig .bc-filter-product {
  padding-right: 0;
  *zoom: 1;
}

.bc-productwithcategory.bc-prowithbig .bc-filter-product:after, .bc-productwithcategory.bc-prowithbig .bc-filter-product:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbig .bc-filter-product {
    padding-right: 7.5px;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .item {
  width: 33.333333%;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .item {
    width: 50%;
  }
  .bc-productwithcategory.bc-prowithbig .tab-content .item:nth-child(3n+1) {
    clear: none;
  }
  .bc-productwithcategory.bc-prowithbig .tab-content .item:nth-child(3n) {
    border-right: 1px solid #eee;
  }
  .bc-productwithcategory.bc-prowithbig .tab-content .item:nth-child(2n+1) {
    clear: both;
  }
  .bc-productwithcategory.bc-prowithbig .tab-content .item:nth-child(2n) {
    border-right: none;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product {
  padding-left: 0;
  border-left: 1px solid #eee;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  *zoom: 1;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product:after, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product {
    padding-bottom: 0;
    padding-left: 14px;
    padding-right: 7.5px;
    margin-bottom: 0;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .left-block {
  padding: 0 30px;
  *zoom: 1;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .left-block:after, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .left-block:before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .item {
  width: 100%;
  border-right: none;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .item .product-container {
  padding-bottom: 0;
  *zoom: 1;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .item .product-container:after, .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .item .product-container:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .item {
    padding-bottom: 30px;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .product-name {
  text-align: left;
  margin-bottom: 20px;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .product-name a {
  font-size: 1.2857142857rem;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .product-name {
    margin-bottom: 5px;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .comments_note {
  clear: both;
  text-align: left;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .comments_note + .button-container {
  margin-top: -45px;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .product-price {
  margin-left: 0;
  margin-right: 10px;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .content_price {
  text-align: left;
  display: inline-block;
  float: left;
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .content_price .price.product-price,
.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .content_price .old-price.product-price {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  display: inline-block;
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button-container {
    clear: both;
    float: left;
    margin-top: 10px !important;
  }
}

.bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button {
  border: none;
  color: #fff;
  padding: 20px 30px;
  height: auto;
  line-height: normal;
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-prowithbig .tab-content .bc_big_product .button.ajax_add_to_cart_button {
    padding: 15px 20px;
  }
}

.bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item {
  width: 25%;
  clear: none;
}

.bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(4n+1) {
  clear: both;
}

.bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(3n) {
  border-right: 1px solid #eee;
}

.bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(4n) {
  border-right: none;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item {
    width: 50%;
  }
  .bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(4n+1) {
    clear: none;
  }
  .bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(2n+1) {
    clear: both;
  }
  .bc-productwithcategory.bc-prowithbanner .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(2n) {
    border-right: none;
  }
}

.bc-productwithcategory.bc-prowithbanner .bc-filter-product {
  padding-left: 0;
  *zoom: 1;
}

.bc-productwithcategory.bc-prowithbanner .bc-filter-product:after, .bc-productwithcategory.bc-prowithbanner .bc-filter-product:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbanner .bc-filter-product {
    padding-left: 7.5px;
  }
}

.bc-productwithcategory.bc-prowithbanner .bc_banner {
  padding-right: 0;
  border-right: 1px solid #eee;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-prowithbanner .bc_banner {
    padding-bottom: 0;
    margin-bottom: 0;
    display: none;
  }
}

.bc-productwithcategory.bc-prowithbanner .bc_banner .bc-content {
  padding: 35px 35px;
}

@media (max-width: 1199px) {
  .bc-productwithcategory.bc-prowithbanner .bc_banner .bc-content {
    padding: 40px 30px;
  }
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-prowithbanner .bc_banner .bc-content {
    padding: 30px 20px;
  }
}

.bc-productwithcategory.bc-prowithbanner .bc_banner .item {
  width: 100%;
  padding: 0;
  margin-bottom: 33px;
  border: none;
}

@media (max-width: 1199px) {
  .bc-productwithcategory.bc-prowithbanner .bc_banner .item {
    margin-bottom: 41px;
  }
}

.bc-productwithcategory.bc-prowithbanner .bc_banner .item:last-child {
  margin-bottom: 0;
}

.bc-productwithcategory.bc-prowithbanner .bc_banner .item img {
  width: 100%;
  height: auto;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab {
  border: 1px solid #eee;
  overflow: hidden;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab {
    border-top: none;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs {
  position: relative;
  padding-right: 0;
  padding-top: 0;
  border-right: 1px solid #eee;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
  top: 0;
  background-color: #f9f9f9;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li {
  width: 100%;
  float: left;
  padding: 19px 12px;
  border-bottom: 1px solid #eee;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:last-child {
  border-bottom: none;
}

@media (max-width: 1199px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li {
    padding: 12px 12px;
  }
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li {
    padding: 20px 12px;
  }
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li {
    padding: 0;
    border: none;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li a {
  color: #333333;
  padding-top: 25px;
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li a {
    padding-top: 9px;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:before {
  width: 64px;
  height: 64px;
  display: inline-block;
  float: left;
  color: #fff;
  font-size: 35px;
  text-align: center;
  line-height: 64px;
}

@media (max-width: 991px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:before {
    width: 30px;
    height: 30px;
    line-height: 30px;
    padding-top: px;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:before {
    display: none;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:nth-child(1):before {
  background: #f87f2f;
  margin-right: 14px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:nth-child(2):before {
  background: #ff3837;
  margin-right: 14px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:nth-child(3):before {
  background: #115da9;
  margin-right: 14px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:nth-child(4):before {
  background: #32004b;
  margin-right: 14px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:nth-child(5):before {
  background: #11a95d;
  margin-right: 14px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li:hover a, .bc-productwithcategory.bc-bestsellerwithcategory .type-tab .nav-tabs > li.active a {
  color: #a0a91c;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content {
  padding-left: 0;
  padding-right: 0;
  border: none;
  margin-bottom: 0 !important;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc_banner {
  display: inline-block;
  width: 25%;
  float: left;
  background: #fefefe;
  border-right: 1px solid #eee;
  padding-top: 20px;
  padding-bottom: 1000px;
  margin-bottom: -1000px;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc_banner {
    padding-bottom: 0;
    margin-bottom: 0;
    display: none;
  }
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .tab-pane:before {
  display: none;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) {
  width: 75%;
  display: inline-block;
  float: left;
  *zoom: 1;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane):after, .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane):before {
  content: "";
  display: table;
  clear: both;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item {
  width: 33.333333%;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item .reviews-container,
.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item .button-container {
  display: none;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item .product-container {
  padding-bottom: 55px;
}

.bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item .right-block {
  padding-top: 10px;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) {
    width: 100%;
  }
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item {
    width: 50%;
  }
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(2n) {
    border-right: none;
  }
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(2n+1) {
    clear: both;
    border-right: 1px solid #eee;
  }
  .bc-productwithcategory.bc-bestsellerwithcategory .tab-content .bc-filter-product:not(.tab-pane) .item:nth-child(3n+1) {
    clear: none;
  }
}

/*CSS FOR HOVER PRODUCT ITEM ON HOMEPAGE*/
@media (max-width: 767px) {
  .new-arrival .type-tab .nav-tabs {
    border: none;
    padding: 0;
    height: auto;
  }
}

.new-arrival .type-tab .nav-tabs li {
  margin-top: -1px;
}

@media (max-width: 767px) {
  .new-arrival .type-tab .nav-tabs li {
    text-align: left;
    margin-top: 0;
  }
  .new-arrival .type-tab .nav-tabs li a {
    min-height: 0;
    border: none;
    color: #ffffff !important;
    background: #242424;
    border-bottom: 1px solid #f1f1f1;
    display: block;
    border-radius: 0;
    border: none;
    padding: 0 !important;
    margin: 0 !important;
  }
  .new-arrival .type-tab .nav-tabs li.active a {
    color: #ffffff !important;
  }
}

.new-arrival .product-container > a {
  display: block;
  margin: -20px -20px 0 -20px;
  padding: 20px 20px 0 20px;
  position: relative;
}

.new-arrival .product-container > a:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  opacity: 0;
}

.bc-filter-product > .item {
  position: relative;
}

.bc-filter-product > .item .product-container > a {
  display: block;
  margin: -14px -10px 0 -10px;
  padding: 14px 10px 0 10px;
  position: relative;
}

.bc-filter-product > .item .product-container > a:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  visibility: hidden;
  -webkit-transform: scale(0.5, 0.5);
  -ms-transform: scale(0.5, 0.5);
  -o-transform: scale(0.5, 0.5);
  transform: scale(0.5, 0.5);
  opacity: 0;
}

/*==============================CSS FOR CATEGORY PAGE==============================*/
/* BREADCRUMB */
@media (max-width: 767px) {
  .top-breadcrumb .container {
    padding-left: 0;
    padding-right: 0;
  }
}

.top-breadcrumb .breadcrumb-title {
  *zoom: 1;
  padding: 50px 30px;
  background: url("../images/bg-promotion.png") no-repeat center;
  background-color: rgba(0, 0, 0, 0.9);
  background-size: cover;
  margin: 10px 0 0;
  border-radius: 10px;
}

.top-breadcrumb .breadcrumb-title:after, .top-breadcrumb .breadcrumb-title:before {
  content: "";
  display: table;
  clear: both;
}

@media (max-width: 991px) {
  .top-breadcrumb .breadcrumb-title {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .top-breadcrumb .breadcrumb-title {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

.top-breadcrumb .breadcrumb-title h1, .top-breadcrumb .breadcrumb-title .h1 {
  display: inline-block;
  float: left;
  width: 50%;
  text-transform: uppercase;
  color: #fff;
  font-weight: normal;
  margin: 0;
  line-height: 1;
  font-family: "Playball", cursive;
}

@media (max-width: 991px) {
  .top-breadcrumb .breadcrumb-title h1, .top-breadcrumb .breadcrumb-title .h1 {
    width: 100%;
    text-align: center;
  }
}

.top-breadcrumb .breadcrumb-title .breadcrumb {
  display: inline-block;
  float: right;
  width: 50%;
  margin: 0;
  border: none;
  border-radius: 0;
  background-color: transparent;
  text-align: right;
  text-shadow: none;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-family: "Playball", cursive;
  font-weight: bold;
  color: #fff;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a {
  color: #fff;
}

@media (max-width: 991px) {
  .top-breadcrumb .breadcrumb-title .breadcrumb {
    width: 100%;
    padding-top: 5px;
    text-align: center;
  }
}

.top-breadcrumb .breadcrumb-title .breadcrumb > span a {
  padding-right: 10px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation-pipe {
  width: auto;
  line-height: 1;
  display: inline-block;
  position: relative;
  top: 5px;
  vertical-align: top;
  font-size: 0;
  text-align: center;
  text-indent: 0;
  padding: 0 10px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation-pipe:before {
  content: "\f105";
  font-family: "FontAweSome";
  display: inline-block;
  font-size: 14px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page {
  display: inline;
  margin-left: -5px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page span {
  display: inline-block;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page a {
  margin-left: 0;
  padding-right: 10px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb .navigation_page .navigation-pipe {
  padding-right: 10px;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a {
  background: transparent;
  width: auto;
  font-weight: normal;
  margin-right: 0;
  padding: 0;
  display: inline-block;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a:before, .top-breadcrumb .breadcrumb-title .breadcrumb a:after {
  display: none;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a:hover {
  color: #a0a91c;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home {
  text-align: center;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home:before {
  font-family: "FontAweSome";
  font-size: 1rem;
  color: #fff;
  position: relative;
  left: 0;
  top: 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: all .35s;
  -o-transition: all .35s;
  transition: all .35s;
  text-indent: 0;
  margin-right: 0;
}

.top-breadcrumb .breadcrumb-title .breadcrumb a.home:hover:before {
  color: #a0a91c;
}

.top-breadcrumb .breadcrumb-title .breadcrumb > a, .top-breadcrumb .breadcrumb-title .breadcrumb > span {
  vertical-align: top;
  display: inline-block;
  color: inherit;
}

/* CATEGORY BLOCK LEFT */
#left_column .content_left {
  background: #fefefe;
}

@media (max-width: 767px) {
  #left_column .content_left {
    border: none;
    background: none;
    padding: 20px 0;
  }
}

#left_column div.checker {
  top: -1px;
}

#left_column .layered_filter {
  border: none;
}

#left_column .layered_price > ul {
  overflow: visible;
}

#left_column .color-group li {
  position: relative;
}

#left_column .color-group li:after {
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  display: block;
  position: absolute;
  top: 7px;
  left: 2px;
}

#left_column .color-group li .color-option {
  margin-right: 5px;
}

#left_column .title_block {
  border: none;
  font-size: 25px;
  position: relative;
  text-align: left;
  font-family: "Playball", cursive;
  text-transform: capitalize;
  background: none;
  margin: 20px 20px 0;
}

#left_column .title_block:before {
  top: 0;
  line-height: 25px;
  height: 25px;
}

@media (max-width: 767px) {
  #left_column .title_block {
    margin: 0 0 20px;
    cursor: pointer;
    color: #a0a91c;
    padding: 0;
    line-height: 30px;
    text-align: left;
  }
  #left_column .title_block:before {
    content: "\f205";
    font-family: "FontAweSome";
    font-size: 14px;
    display: block;
    position: absolute;
    right: 0;
    text-align: right;
    left: auto;
    top: 3px;
    color: inherit;
    background: none;
  }
  #left_column .title_block:after {
    content: none;
  }
  #left_column .title_block.active:before {
    content: "\f204";
  }
  #left_column .title_block:hover, #left_column .title_block.active {
    color: #333;
    background: none;
  }
  #left_column .title_block:hover:after, #left_column .title_block.active:after {
    background: #a0a91c;
  }
  #left_column .title_block:hover a, #left_column .title_block.active a {
    color: #a0a91c;
  }
}

#left_column .block_content {
  padding: 10px 15px;
}

#left_column .block_content ul li.last {
  border-bottom: none;
}

#left_column .block_content ul li.last a {
  border-bottom: none;
  padding-bottom: 0;
}

#left_column .block_content ul li.last .frame-date-author {
  border-bottom: none;
}

#left_column .list-block li {
  border-color: #f2f2f2;
  padding: 10px 0;
}

#left_column #categories_block_left .title_block {
  padding-top: 0;
}

#left_column #categories_block_left ul {
  border: none;
}

#left_column #categories_block_left li a {
  padding: 10px 0 10px 0;
  color: #333333;
  font-weight: normal;
  border-color: #eee;
  line-height: 20px;
}

#left_column #categories_block_left li a:hover, #left_column #categories_block_left li a.selected {
  background: none;
  color: #a0a91c;
}

#left_column #categories_block_left li li a {
  padding-left: 15px;
}

#left_column #categories_block_left li li a:before {
  line-height: 1;
}

#left_column #categories_block_left li span.grower {
  background: none;
  font-size: 18px;
  top: 8px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: right;
}

#left_column #categories_block_left li span.grower:before {
  content: "\f138";
  display: inline-block;
  color: #d2d2d2;
  text-align: right;
  width: 18px;
  height: 18px;
  line-height: normal;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  -webkit-transform-origin: 50% 50%;
  -moz-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  -o-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
}

#left_column #categories_block_left li span.grower.OPEN:before {
  content: "\f138";
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  color: #a0a91c;
}

#left_column #categories_block_left li span.grower.OPEN + a {
  color: #a0a91c;
}

#left_column #categories_block_left li span.grower:hover:before {
  content: "\f138";
  color: #a0a91c;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

#left_column #categories_block_left li span.grower:hover + a {
  background: none;
  color: #a0a91c;
}

@media (min-width: 767px) {
  #left_column .block {
    border: 2px solid #f4f4f4;
    border-radius: 10px;
  }
}

#left_column #layered_block_left .title_block {
  display: none;
}

@media (max-width: 767px) {
  #left_column #layered_block_left .title_block {
    display: block;
  }
}

#left_column #layered_block_left #enabled_filters {
  padding: 0;
  border: none;
  background: none;
}

#left_column #layered_block_left #enabled_filters ul {
  padding-top: 0;
}

#left_column #layered_block_left .layered_subtitle {
  font-weight: normal;
  padding: 15px 0 5px;
  line-height: 1;
  position: relative;
  color: #333333;
  text-transform: none;
  margin-bottom: 20px;
  font-size: 20px;
  font-family: "Playball", cursive;
}

#left_column #layered_block_left .layered_subtitle:after {
  content: "";
  display: block;
  height: 2px;
  width: 50px;
  background: #a0a91c;
  position: absolute;
  left: 0;
  bottom: 0;
}

#left_column .blog_lastest_posts h3, #left_column .blog_lastest_posts .h3 {
  font-size: 0.8571428571rem;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 15px;
}

#left_column .blog_comments h3, #left_column .blog_comments .h3 {
  font-size: 0.8571428571rem;
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 9px;
}

#left_column .blog_comments .comment-content {
  font-size: 0.8571428571rem;
  margin: 5px 0 10px;
}

/* CATEGORY CENTER BLOCK*/
.category #center_column .content_scene_cat {
  border: none;
}

.category #center_column .content_scene_cat_bg {
  padding: 0;
  background-color: transparent !important;
}

.category #center_column .content_scene_cat_bg img {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px;
}

.category #center_column .content_scene_cat_bg .category-name {
  float: left;
  width: 100%;
  color: #333333;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
}

.category #center_column .content_scene_cat_bg .cat_desc {
  color: #242424;
}

.category #center_column .page-heading {
  display: none;
}

.category #center_column #subcategories {
  border: none;
  position: relative;
  margin-bottom: 60px;
  margin-left: -15px;
  margin-right: -15px;
}

@media (max-width: 767px) {
  .category #center_column #subcategories {
    margin-bottom: 30px;
  }
}

.category #center_column #subcategories .owl-controls {
  position: absolute;
  right: 15px;
  top: -45px;
}

.category #center_column #subcategories .subcategory-name {
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 30px;
  text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
}

.category #center_column #subcategories .subcategory-heading {
  font-size: 1.1428571429rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  padding: 0 15px;
  font-size: 15px;
  text-transform: uppercase;
}

.category #center_column #subcategories ul {
  margin: 0;
}

.category #center_column #subcategories ul li {
  margin: 0;
  height: auto;
  padding: 0px 15px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.category #center_column #subcategories ul li h5, .category #center_column #subcategories ul li .h5 {
  position: absolute;
  width: 100%;
  left: 0;
  top: 30%;
  padding: 0 15px;
  z-index: 100;
  transition: all 0.3s ease-in-out 0.1s;
  transform: scale(10);
  opacity: 0;
}

.category #center_column #subcategories ul li h5 a, .category #center_column #subcategories ul li .h5 a {
  color: #fff;
  text-transform: uppercase;
  display: block;
  background: none;
  padding: 10px 0;
}

.category #center_column #subcategories ul li:hover .subcategory-image a:before {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=50);
  opacity: 0.5;
  transition-delay: 0s;
}

.category #center_column #subcategories ul li:hover .subcategory-image img {
  transition-delay: 0s;
}

.category #center_column #subcategories ul li:hover h5, .category #center_column #subcategories ul li:hover .h5 {
  transition-delay: 0.1s;
  transform: scale(1);
  opacity: 1;
}

.category #center_column #subcategories .subcategory-image {
  padding: 0;
  position: relative;
  overflow: hidden;
}

.category #center_column #subcategories .subcategory-image a {
  border: none;
  padding: 0;
}

.category #center_column #subcategories .subcategory-image a:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #a0a91c;
  transition: all 0.3s ease-in 0.4s;
  opacity: 0;
  z-index: 1;
}

.category #center_column #subcategories .subcategory-image img {
  width: 100%;
  height: auto;
  transition: all 0.4s ease-in-out 0.5s;
  opacity: 1;
  display: block;
  position: relative;
}

/* LAYER SLIDER */
.layered_slider_container {
  width: 89%;
  position: relative;
}

@media (max-width: 1199px) {
  .layered_slider_container {
    left: -3px;
  }
}

@media (max-width: 991px) {
  .layered_slider_container {
    left: -8px;
  }
}

.layered_slider_container .layered_slider {
  background: #eee;
  border: none;
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-radius: 20px;
}

.layered_slider_container .ui-widget-header {
  background: none;
  background-color: #a0a91c;
  margin-top: -1px;
}

.layered_slider_container .ui-slider-handle {
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  background: url("../images/thumb-slider.png") no-repeat center;
  top: 50%;
  margin-top: -12px;
}

/* COLOR OPTION */
#layered_form div div ul {
  max-height: none;
  overflow-x: visible;
}

#layered_form div div ul li .color-option {
  border-color: #eee;
  width: 12px;
  height: 12px;
  position: relative;
  float: left;
  margin-right: 10px !important;
  margin-top: 3px;
}

/* CONTENT SORT FILTER BAR */
.content_sortPagiBar {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .content_sortPagiBar {
    margin-bottom: 10px;
  }
}

.content_sortPagiBar div.selector {
  display: inline-block;
  width: 150px !important;
}

.content_sortPagiBar #productsSortForm, .content_sortPagiBar .nbrItemPage {
  margin-right: 20px;
  display: inline-block;
  float: left;
}

@media (max-width: 767px) {
  .content_sortPagiBar #productsSortForm, .content_sortPagiBar .nbrItemPage {
    width: 50%;
    margin-right: 0 !important;
  }
  .content_sortPagiBar #productsSortForm .selector, .content_sortPagiBar .nbrItemPage .selector {
    width: 100% !important;
  }
  .content_sortPagiBar #productsSortForm .selector select, .content_sortPagiBar .nbrItemPage .selector select {
    width: 100%;
    max-width: 100% !important;
  }
}

@media (max-width: 767px) {
  .content_sortPagiBar #productsSortForm {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .content_sortPagiBar .nbrItemPage {
    padding-left: 15px;
  }
  .content_sortPagiBar .nbrItemPage .clearfix > span {
    padding-left: 0 !important;
  }
}

.content_sortPagiBar .nbrItemPage .clearfix > span {
  padding-left: 12px;
}

.content_sortPagiBar .sortPagiBar {
  border: none;
}

.content_sortPagiBar label {
  text-transform: uppercase;
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  padding-top: 8px;
  padding-right: 10px;
  font-weight: normal;
}

@media (max-width: 767px) {
  .content_sortPagiBar label {
    width: 100%;
    text-align: left;
  }
}

.content_sortPagiBar .icon-grid-list {
  float: right;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list {
    float: none;
    width: 100%;
    padding-top: 10px;
    text-align: left;
  }
}

.content_sortPagiBar .icon-grid-list ul {
  margin: 0;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list ul {
    display: block !important;
    float: none;
  }
}

.content_sortPagiBar .icon-grid-list .display-title {
  text-transform: uppercase;
  margin-top: 5px;
  font-weight: normal !important;
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 767px) {
  .content_sortPagiBar .icon-grid-list .display-title {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }
}

.content_sortPagiBar .icon-grid-list li {
  text-transform: uppercase;
}

.content_sortPagiBar .icon-grid-list li a {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  color: #333333;
}

.content_sortPagiBar .icon-grid-list li a i {
  display: inline-block;
  font-size: 14px;
  color: #333333;
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.content_sortPagiBar .icon-grid-list #grid, .content_sortPagiBar .icon-grid-list #list {
  height: 35px;
  padding: 0 10px;
  border: 1px solid #eee;
  cursor: pointer;
  padding-top: 4px;
}

.content_sortPagiBar .icon-grid-list #grid:hover, .content_sortPagiBar .icon-grid-list #grid.selected, .content_sortPagiBar .icon-grid-list #list:hover, .content_sortPagiBar .icon-grid-list #list.selected {
  border-color: #a0a91c;
  background: #a0a91c;
}

.content_sortPagiBar .icon-grid-list #grid:hover a, .content_sortPagiBar .icon-grid-list #grid.selected a, .content_sortPagiBar .icon-grid-list #list:hover a, .content_sortPagiBar .icon-grid-list #list.selected a {
  color: #fff;
}

.content_sortPagiBar .icon-grid-list #grid:hover a i, .content_sortPagiBar .icon-grid-list #grid.selected a i, .content_sortPagiBar .icon-grid-list #list:hover a i, .content_sortPagiBar .icon-grid-list #list.selected a i {
  color: #fff;
}

.content_sortPagiBar .icon-grid-list #grid {
  margin-right: 15px;
}

.content_sortPagiBar .top-pagination-content {
  border-top: 1px solid #eee;
  margin-top: 10px;
  padding-top: 20px;
}

@media (max-width: 767px) {
  .content_sortPagiBar .top-pagination-content {
    margin-top: 20px;
    padding-top: 20px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .search .content_sortPagiBar label {
    display: block;
    float: none !important;
    text-align: left;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .search .content_sortPagiBar .compare-form {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .search .content_sortPagiBar .compare-form {
    float: none;
    text-align: left;
    margin-bottom: 10px;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .category .content_sortPagiBar .icon-grid-list {
    clear: both;
    float: left;
    margin-top: 10px;
  }
  .category .content_sortPagiBar .icon-grid-list .display-title {
    text-align: left;
    min-width: 54px;
  }
}

/* BOTTOM PAGING BLOCK*/
.bottom-pagination-content {
  border: none;
}

.bottom-pagination-content .product-count, .bottom-pagination-content .showall {
  display: none !important;
}

.bottom-pagination-content div.pagination {
  float: none;
  width: 100%;
}

.bottom-pagination-content div.pagination ul {
  width: 100%;
  text-align: center;
  position: relative;
}

.bottom-pagination-content div.pagination ul li {
  float: none;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav) {
  width: 40px;
  height: 40px;
  border: 1px solid #eee;
  margin: 0 3px;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav) a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav) span {
  width: 100%;
  height: 100%;
  background: none;
  border: none;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  line-height: 36px;
  font-weight: normal;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bottom-pagination-content div.pagination ul li:not(.pagination_nav).active a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav).active span, .bottom-pagination-content div.pagination ul li:not(.pagination_nav):hover a, .bottom-pagination-content div.pagination ul li:not(.pagination_nav):hover span {
  background: #a0a91c;
  border-color: #a0a91c;
  color: #fff;
}

.bottom-pagination-content div.pagination ul li.pagination_previous, .bottom-pagination-content div.pagination ul li.pagination_next {
  min-width: 130px;
  height: 40px;
  border: 1px solid #eee;
  position: absolute;
  top: 0;
}

.bottom-pagination-content div.pagination ul li.pagination_previous.disabled, .bottom-pagination-content div.pagination ul li.pagination_next.disabled {
  opacity: 0.6;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a, .bottom-pagination-content div.pagination ul li.pagination_previous span, .bottom-pagination-content div.pagination ul li.pagination_next a, .bottom-pagination-content div.pagination ul li.pagination_next span {
  float: none;
  width: 100%;
  height: 100%;
  color: #333333;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  position: relative;
  padding: 0;
  line-height: 38px;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i, .bottom-pagination-content div.pagination ul li.pagination_previous span i, .bottom-pagination-content div.pagination ul li.pagination_next a i, .bottom-pagination-content div.pagination ul li.pagination_next span i {
  width: 38px;
  height: 38px;
  background: #eee;
  text-align: center;
  font-size: 14px;
  line-height: 38px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover {
  border-color: #a0a91c;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover span, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover a, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover span {
  color: #a0a91c;
}

.bottom-pagination-content div.pagination ul li.pagination_previous:not(.disabled):hover i, .bottom-pagination-content div.pagination ul li.pagination_next:not(.disabled):hover i {
  background: #a0a91c;
  color: #fff;
}

.bottom-pagination-content div.pagination ul li.pagination_previous {
  left: 0;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a, .bottom-pagination-content div.pagination ul li.pagination_previous span {
  text-align: right;
  padding-right: 27px;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i, .bottom-pagination-content div.pagination ul li.pagination_previous span i {
  float: left;
}

.bottom-pagination-content div.pagination ul li.pagination_previous a i:before, .bottom-pagination-content div.pagination ul li.pagination_previous span i:before {
  content: "\f060";
}

.bottom-pagination-content div.pagination ul li.pagination_next {
  right: 0;
}

.bottom-pagination-content div.pagination ul li.pagination_next a, .bottom-pagination-content div.pagination ul li.pagination_next span {
  text-align: left;
  padding-left: 27px;
}

.bottom-pagination-content div.pagination ul li.pagination_next a i, .bottom-pagination-content div.pagination ul li.pagination_next span i {
  float: right;
}

.bottom-pagination-content div.pagination ul li.pagination_next a i:before, .bottom-pagination-content div.pagination ul li.pagination_next span i:before {
  content: "\f061";
}

@media (max-width: 767px) {
  .bottom-pagination-content div.pagination ul li.pagination_previous, .bottom-pagination-content div.pagination ul li.pagination_next {
    min-width: 0;
    width: 40px;
  }
  .bottom-pagination-content div.pagination ul li.pagination_previous b, .bottom-pagination-content div.pagination ul li.pagination_next b {
    display: none;
  }
  .bottom-pagination-content div.pagination ul li.pagination_previous a, .bottom-pagination-content div.pagination ul li.pagination_previous span, .bottom-pagination-content div.pagination ul li.pagination_next a, .bottom-pagination-content div.pagination ul li.pagination_next span {
    padding: 0;
    width: 100%;
    height: 100%;
  }
}

/* PRODUCT LAYOUT */
.columns-container ul.product_list {
  *zoom: 1;
}

.columns-container ul.product_list:after, .columns-container ul.product_list:before {
  content: "";
  display: table;
  clear: both;
}

.columns-container ul.product_list .product-container .left-block {
  position: relative;
}

.columns-container ul.product_list .functional-buttons {
  display: inline-block;
  background: none;
  width: auto;
}

.columns-container ul.product_list .functional-buttons .wishlist {
  border-right: none;
}

.columns-container ul.product_list .color-list-container {
  margin-bottom: 10px;
}

.columns-container ul.product_list .color-list-container ul li {
  width: 20px;
  height: 20px;
}

.columns-container ul.product_list .color-list-container ul li a {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
}

.columns-container ul.product_list .color-list-container ul li a:after {
  content: "";
  width: 14px;
  height: 14px;
  border: 4px solid #fff;
  position: absolute;
  left: 2px;
  top: 2px;
  display: block;
}

.columns-container ul.product_list .availability span {
  background-color: transparent;
  border: none;
  color: #a0a91c;
}

.columns-container ul.product_list > li {
  padding-left: 0;
  padding-right: 0;
}

.columns-container ul.product_list > li .product-container .product-name {
  text-align: left;
}

.columns-container ul.product_list > li .product-container h5, .columns-container ul.product_list > li .product-container .h5 {
  font-size: 0.8571428571rem;
  margin-bottom: 0;
}

.columns-container ul.product_list > li .product-container h5 a, .columns-container ul.product_list > li .product-container .h5 a {
  margin-bottom: 0;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.columns-container ul.product_list > li .product-container .content_price .product-price {
  display: initial;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.grid > li {
    overflow: hidden;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.grid > li .product-container {
    margin: 0 15px 20px;
  }
}

.columns-container ul.product_list.grid > li .product-container .quick-view-wrapper-mobile {
  display: none;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.grid > li .product-container .right-block .addToWishlist, .columns-container ul.product_list.grid > li .product-container .right-block .ajax_add_to_cart_button, .columns-container ul.product_list.grid > li .product-container .right-block .add_to_compare {
    -webkit-animation-name: none;
    animation-name: none;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.grid > li .product-container:hover .product-image-container:before {
    display: none;
  }
}

.columns-container ul.product_list.grid > li:nth-child(1), .columns-container ul.product_list.grid > li:nth-child(2), .columns-container ul.product_list.grid > li:nth-child(3) {
  border-top: none;
}

.columns-container ul.product_list.grid > li:nth-child(3n) {
  border-right: none;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.grid > li:nth-child(3n+1) {
    clear: none;
  }
  .columns-container ul.product_list.grid > li:nth-child(2n) {
    border-right: none;
  }
}

@media (max-width: 479px) {
  .columns-container ul.product_list.grid > li {
    border-right: none;
  }
  .columns-container ul.product_list.grid > li:nth-child(3n) {
    border-right: none;
  }
}

.columns-container ul.product_list.list > li {
  width: 100%;
  *zoom: 1;
}

.columns-container ul.product_list.list > li:after, .columns-container ul.product_list.list > li:before {
  content: "";
  display: table;
  clear: both;
}

.columns-container ul.product_list.list > li:last-child {
  border: none;
}

.columns-container ul.product_list.list > li .product-container {
  float: left;
  border-radius: 10px;
  margin: 0 5px 30px;
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container {
    margin: 0 15px 20px;
  }
}

.columns-container ul.product_list.list > li .product-container .ajax_add_to_cart_button {
  margin: 0 5px;
  font-size: 0 !important;
  transition: 0.3s;
  min-width: 0;
}

.columns-container ul.product_list.list > li .product-container .left-block {
  width: 26%;
  float: left;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.list > li .product-container .left-block {
    width: 35%;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .left-block {
    width: 40%;
  }
}

.columns-container ul.product_list.list > li .product-container .left-block .product-image-container {
  padding: 20px 0 20px 20px;
}

.columns-container ul.product_list.list > li .product-container .left-block .new-box {
  top: 10px;
  left: 10px;
}

.columns-container ul.product_list.list > li .product-container .left-block .button-container {
  display: none !important;
}

.columns-container ul.product_list.list > li .product-container .right-block {
  width: 74%;
  float: left;
  padding: 20px 10px 20px 30px;
}

@media (max-width: 991px) {
  .columns-container ul.product_list.list > li .product-container .right-block {
    width: 65%;
  }
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .right-block {
    width: 60%;
  }
}

.columns-container ul.product_list.list > li .product-container .right-block .product-name {
  text-align: left;
  padding-right: 50px;
  white-space: normal;
  line-height: 1.2;
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .right-block .product-name {
    padding-right: 0;
  }
}

.columns-container ul.product_list.list > li .product-container .right-block .reviews-container {
  min-height: 0;
}

.columns-container ul.product_list.list > li .product-container .right-block .price-percent-reduction, .columns-container ul.product_list.list > li .product-container .right-block .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices .columns-container ul.product_list.list > li .product-container .right-block #reduction_percent {
  display: none;
}

.columns-container ul.product_list.list > li .product-container .right-block .comments_note {
  text-align: left;
}

.columns-container ul.product_list.list > li .product-container .right-block .content_price {
  height: 0;
  margin-top: -24px;
  padding-bottom: 24px;
}

.columns-container ul.product_list.list > li .product-container .right-block .content_price .product-price {
  display: block;
  padding: 5px 10px 5px 0;
}

@media (max-width: 767px) {
  .columns-container ul.product_list.list > li .product-container .right-block .content_price {
    margin-top: 0;
    height: auto;
  }
  .columns-container ul.product_list.list > li .product-container .right-block .content_price .product-price {
    text-align: left;
    padding-left: 0;
  }
}

.columns-container ul.product_list.list > li .product-container .right-block .lnk_view,
.columns-container ul.product_list.list > li .product-container .right-block .product-flags, .columns-container ul.product_list.list > li .product-container .right-block .quick-view-wrapper-mobile {
  display: none;
}

@media (max-width: 420px) {
  .columns-container ul.product_list.list > li .product-container .right-block .addToWishlist, .columns-container ul.product_list.list > li .product-container .right-block .ajax_add_to_cart_button, .columns-container ul.product_list.list > li .product-container .right-block .add_to_compare {
    width: 30px;
    height: 30px;
  }
  .columns-container ul.product_list.list > li .product-container .right-block .addToWishlist:before, .columns-container ul.product_list.list > li .product-container .right-block .ajax_add_to_cart_button:before, .columns-container ul.product_list.list > li .product-container .right-block .add_to_compare:before {
    line-height: 28px;
    font-size: 13px;
  }
}

.columns-container ul.product_list.list > li .product-container .right-block .button-container {
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

.columns-container ul.product_list.list > li .product-container .right-block .button-container .wishlist, .columns-container ul.product_list.list > li .product-container .right-block .button-container .ajax_add_to_cart_button, .columns-container ul.product_list.list > li .product-container .right-block .button-container .compare, .columns-container ul.product_list.list > li .product-container .right-block .button-container .quick-view {
  display: inline-block;
}

.columns-container ul.product_list.list > li .product-container .right-block .button-container .addToWishlist, .columns-container ul.product_list.list > li .product-container .right-block .button-container .add_to_compare, .columns-container ul.product_list.list > li .product-container .right-block .button-container .quick-view span, .columns-container ul.product_list.list > li .product-container .right-block .button-container .ajax_add_to_cart_button {
  border: 1px solid #eee;
}

.columns-container ul.product_list.list > li .product-container .right-block .button-container .ajax_add_to_cart_button {
  background: #a0a91c;
  color: #ffffff;
}

.columns-container ul.product_list.list > li .product-container .right-block .button-container .ajax_add_to_cart_button:hover {
  background: #c9d523;
}

.button.lnk_view {
  display: none;
}

/*=====================================CSS FOR PRODUCT DETAIL PAGE==================================================*/
.product .top-hr {
  display: none;
}

@media (max-width: 991px) {
  .product .pb-left-column {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product .pb-left-column {
    width: 100%;
  }
}

.product .pb-left-column #image-block {
  border: none;
  padding: 0;
  width: 100%;
}

.product .pb-left-column #image-block .zoomPad, .product .pb-left-column #image-block img {
  width: 100%;
  height: auto;
}

.product .pb-left-column #views_block {
  position: relative;
}

.product .pb-left-column #views_block .view_scroll_spacer {
  margin: 0;
}

.product .pb-left-column #views_block #thumbs_list {
  width: 100%;
}

.product .pb-left-column #views_block #thumbs_list li {
  text-align: center;
  float: left;
  height: auto;
  border: none;
  padding: 0 5px;
  margin: 0;
  width: 92px;
}

@media (max-width: 1199px) {
  .product .pb-left-column #views_block #thumbs_list li {
    width: 101px;
  }
}

@media (max-width: 991px) {
  .product .pb-left-column #views_block #thumbs_list li {
    width: 115px;
  }
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #thumbs_list li img {
    width: 100%;
    display: inline-block;
  }
}

.product .pb-left-column #views_block #thumbs_list li a {
  border: 1px solid transparent;
  display: block;
  *zoom: 1;
}

.product .pb-left-column #views_block #thumbs_list li a:after, .product .pb-left-column #views_block #thumbs_list li a:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-left-column #views_block #thumbs_list li a.shown, .product .pb-left-column #views_block #thumbs_list li a:hover {
  border-color: #a0a91c;
}

.product .pb-left-column #views_block #thumbs_list li img {
  border: none;
  float: none;
  width: 100%;
  height: auto;
}

.product .pb-left-column #views_block #thumbs_list ul#thumbs_list_frame {
  height: auto;
}

.product .pb-left-column #views_block #view_scroll_left, .product .pb-left-column #views_block #view_scroll_right {
  position: absolute;
  top: 0;
  margin: 0 !important;
  z-index: 20;
}

.product .pb-left-column #views_block #view_scroll_left {
  left: 0;
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #view_scroll_left {
    left: 0px;
  }
}

.product .pb-left-column #views_block #view_scroll_right {
  right: 0;
}

@media (max-width: 767px) {
  .product .pb-left-column #views_block #view_scroll_right {
    right: 0px;
  }
}

.product .pb-center-column {
  padding-left: 85px;
}

@media (max-width: 991px) {
  .product .pb-center-column {
    width: 50%;
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .product .pb-center-column {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.product .pb-center-column h1, .product .pb-center-column .h1 {
  font-size: 1.7142857143rem;
  margin-top: 0;
}

.product .pb-center-column div.selector {
  height: 40px;
}

.product .pb-center-column div.selector > span, .product .pb-center-column div.selector select {
  width: 100% !important;
  max-width: 100% !important;
  height: 40px;
}

.product .pb-center-column div.selector > span:after, .product .pb-center-column div.selector select:after {
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.product .pb-center-column div.selector > span {
  padding-right: 55px;
  line-height: 40px;
}

@media (max-width: 767px) {
  .product .pb-center-column div.selector {
    width: 100% !important;
  }
}

.product .pb-center-column .content_prices {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  position: relative;
}

.product .pb-center-column .content_prices .our_price_display {
  display: inline-block;
}

.product .pb-center-column .content_prices .our_price_display .price {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7142857143rem;
  font-weight: normal;
  margin-right: 15px;
}

.product .pb-center-column .content_prices .price {
  font-family: "Montserrat", sans-serif;
  font-size: 1.7142857143rem;
  font-weight: normal;
}

.product .pb-center-column .content_prices #reduction_percent {
  line-height: 24px;
  right: 0;
  top: 0;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  display: none !important;
}

.product .pb-center-column #availability_statut #availability_value {
  margin-left: 0;
  background: none;
  border: none;
  padding-left: 0;
}

.product .pb-center-column #product_comments_block_extra {
  margin-top: 0;
  padding: 0;
  background: none;
  border: none;
}

.product .pb-center-column #product_comments_block_extra .comments_advices .reviews, .product .pb-center-column #product_comments_block_extra .comments_advices .open-comment-form {
  margin-right: 10px;
  text-transform: uppercase;
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
}

.product .pb-center-column #product_comments_block_extra .comments_advices li:nth-child(2) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .product .pb-center-column #product_comments_block_extra .comments_advices li {
    display: inline-block;
  }
}

.product .pb-center-column #short_description_block {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  display: inline-block;
  width: 100%;
}

.product .pb-center-column #short_description_block #short_description_content {
  padding-bottom: 0;
}

.product .pb-center-column .box-info-product {
  background: none;
  border: none;
}

.product .pb-center-column .box-info-product .box-cart-bottom {
  margin-bottom: 20px;
  margin-top: -65px;
  float: right;
}

@media (max-width: 1199px) {
  .product .pb-center-column .box-info-product .box-cart-bottom {
    margin-top: 0;
    clear: both;
    float: none;
  }
}

.product .pb-center-column .box-info-product .box-cart-bottom > div {
  display: inline-block;
  float: left;
}

.product .pb-center-column .box-info-product .product_attributes, .product .pb-center-column .box-info-product .box-cart-bottom {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  *zoom: 1;
}

.product .pb-center-column .box-info-product .product_attributes:after, .product .pb-center-column .box-info-product .product_attributes:before, .product .pb-center-column .box-info-product .box-cart-bottom:after, .product .pb-center-column .box-info-product .box-cart-bottom:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-center-column .box-info-product .product_attributes label, .product .pb-center-column .box-info-product .box-cart-bottom label {
  text-align: left;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  margin-bottom: 20px;
  float: left;
  width: 100%;
}

.product .pb-center-column .box-info-product .product_attributes fieldset, .product .pb-center-column .box-info-product .box-cart-bottom fieldset {
  padding-bottom: 30px;
}

.product .pb-center-column .box-info-product #color_to_pick_list li {
  width: 60px;
  height: 40px;
  border: 1px solid #eee;
}

.product .pb-center-column .box-info-product #color_to_pick_list li a {
  width: 100%;
  height: 100%;
}

.product .pb-center-column .box-info-product #color_to_pick_list li.selected {
  border: 2px solid #a0a91c;
}

.product .pb-center-column .box-info-product #quantity_wanted_p {
  float: left;
  position: relative;
  margin-bottom: 0;
}

.product .pb-center-column .box-info-product #quantity_wanted_p label {
  display: none;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn {
  margin-left: 0;
  border-color: #eee;
  position: absolute;
  width: 25px;
  height: 22px;
  border: none;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn span {
  border: none;
  background: #dddddd;
  width: 100%;
  height: 100%;
  -webkit-transition: background .3s;
  -o-transition: background .3s;
  transition: background .3s;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn span i {
  color: #676767;
  font-size: 14px;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-minus {
  border-right: none;
  right: 0;
  bottom: 0;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-minus i:before {
  content: "\f0d7";
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-plus {
  border-left: none;
  right: 0;
  bottom: 22px;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn.button-plus i:before {
  content: "\f0d8";
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn:hover {
  color: #fff;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn:hover span {
  background: #333333;
}

.product .pb-center-column .box-info-product #quantity_wanted_p .btn:hover span i {
  color: #fff;
}

.product .pb-center-column .box-info-product #quantity_wanted_p input {
  height: 45px;
  padding-right: 30px;
  background: #eee;
  text-align: center;
  line-height: 45px;
  border: 1px solid #e5e9e9 !important;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
}

.product .pb-center-column .box-info-product #add_to_cart {
  padding-left: 0;
  padding-right: 0;
  float: left;
}

.product .pb-center-column .box-info-product #add_to_cart button {
  border: none;
  color: #fff;
  padding: 0 20px;
  border-radius: 10px;
  height: 45px;
  line-height: 45px;
  margin-right: 5px;
}

.product .pb-center-column .box-info-product #add_to_cart button:before {
  position: relative;
  width: auto;
  height: auto;
  line-height: 1;
  border: none;
  text-shadow: none;
}

.product .pb-center-column .box-info-product #add_to_cart button:after {
  display: none;
}

.product .pb-center-column .box-info-product #add_to_cart button:hover {
  background: #242424;
}

.product .pb-center-column .box-info-product .functional-buttons {
  float: right;
  min-width: 90px;
  white-space: nowrap;
  font-size: 0;
  padding-top: 13px;
}

.product .pb-center-column .box-info-product .functional-buttons .buttons_bottom_block, .product .pb-center-column .box-info-product .functional-buttons .compare {
  padding: 0;
  margin-bottom: 0;
  display: inline-block;
  float: left;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare {
  float: none !important;
  border: 1px solid #eee;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:before {
  content: "\f004";
  font-family: "FontAweSome";
  font-size: 12px;
  line-height: 50px;
  background: none;
  height: auto;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:hover {
  padding: 0;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare {
  float: left;
  margin-right: 5px;
  width: 45px;
  height: 45px;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:before, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare:before {
  margin-right: 0;
  font-size: 15.5px;
  line-height: 45px;
}

.product .pb-center-column .box-info-product .functional-buttons #wishlist_button_nopop:hover:before, .product .pb-center-column .box-info-product .functional-buttons .add_to_compare:hover:before {
  padding: 0;
}

.product .pb-center-column .socialsharing_product {
  clear: both;
}

.product .pb-center-column .socialsharing_product:before {
  text-transform: uppercase;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
  display: inline-block;
  margin-right: 15px;
}

@media (max-width: 767px) {
  .product .pb-center-column .socialsharing_product:before {
    margin-right: 10px;
  }
}

.product .pb-center-column .socialsharing_product button {
  background: none;
  font-size: 0;
  width: 50px;
  height: 50px;
  margin-right: 0px;
  text-align: center;
  padding: 0;
  min-width: 0;
  vertical-align: middle;
  /*&:hover {
					&.btn-twitter {
						border-color: #00aaf0;
						background: #00aaf0;
					}
					&.btn-facebook {
						border-color: #435f9f;
						background: #435f9f;
					}
					&.btn-google-plus {
						border-color: #e04b34;
						background: #e04b34;
					}
					&.btn-pinterest {
						border-color: #ce1f21;
						background: #ce1f21;
					}
				}*/
}

.product .pb-center-column .socialsharing_product button i {
  display: inline-block;
  font-size: 14px;
  line-height: 38px;
  color: #333333;
}

.product .pb-center-column .socialsharing_product button:hover i {
  color: #a0a91c;
}

@media (max-width: 1199px) {
  .product .pb-center-column .socialsharing_product button {
    width: 30px;
    height: 30px;
  }
  .product .pb-center-column .socialsharing_product button i {
    line-height: 28px;
  }
}

@media (max-width: 767px) {
  .product .pb-center-column .socialsharing_product button {
    width: 25px;
    height: 25px;
  }
  .product .pb-center-column .socialsharing_product button i {
    line-height: 23px;
  }
}

@media (max-width: 991px) {
  .product .pb-right-column {
    display: none;
  }
}

.product .pb-right-column .product-custom-content h3, .product .pb-right-column .product-custom-content .h3 {
  text-transform: uppercase;
  color: #333333;
  margin-top: 0;
  margin-bottom: 30px;
}

.product .pb-right-column .product-custom-content .feature {
  *zoom: 1;
  margin-bottom: 20px;
}

.product .pb-right-column .product-custom-content .feature:after, .product .pb-right-column .product-custom-content .feature:before {
  content: "";
  display: table;
  clear: both;
}

.product .pb-right-column .product-custom-content .feature .icon {
  width: 55px;
  height: 55px;
  margin-right: 10px;
  float: left;
  line-height: 55px;
  border-radius: 55px;
  text-align: center;
  background-color: #a0a91c;
  color: #fff;
  font-size: 18px;
}

.product .pb-right-column .product-custom-content .feature .custom-title {
  text-transform: uppercase;
  color: #333333;
  font-family: "Montserrat", sans-serif;
  margin-top: 5px;
  margin-bottom: 5px;
}

.product #more_info_block {
  *zoom: 1;
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
  margin-bottom: 20px;
}

.product #more_info_block:after, .product #more_info_block:before {
  content: "";
  display: table;
  clear: both;
}

@media (min-width: 767px) {
  .product #more_info_block #more_info_tabs {
    width: 25%;
    float: left;
    z-index: 2;
    position: relative;
    margin: 0;
  }
  .product #more_info_block #more_info_tabs:after {
    border-right: 1px solid #f4f4f4;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    content: '.';
    text-indent: -5000px;
    display: block;
    z-index: 1;
  }
}

.product #more_info_block #more_info_tabs li {
  margin: 0;
  border-bottom: 1px solid #f4f4f4;
  z-index: 2;
}

.product #more_info_block #more_info_tabs li a {
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  padding: 15px 5px 15px 10px;
  display: block;
  color: #333333;
  transition: 0.3s;
  position: relative;
  background: none;
}

.product #more_info_block #more_info_tabs li a.selected, .product #more_info_block #more_info_tabs li a:hover {
  color: #a0a91c;
}

@media (min-width: 767px) {
  .product #more_info_block #more_info_tabs li a {
    border-left: 2px solid #f4f4f4;
  }
  .product #more_info_block #more_info_tabs li a.selected {
    border-left: 2px solid #a0a91c;
    background: #fff;
    z-index: 2;
  }
}

@media (max-width: 767px) {
  .product #more_info_block #more_info_tabs li {
    display: block;
    float: none;
    text-align: center;
    margin-bottom: 5px;
  }
  .product #more_info_block #more_info_tabs li a {
    display: inline-block;
    padding: 10px;
    border-bottom: 3px solid transparent;
    font-size: 16px;
    margin-right: 0;
  }
}

@media (min-width: 767px) {
  .product #more_info_block #more_info_sheets {
    float: left;
    width: 75%;
    margin-top: 35px;
    z-index: 1;
    position: relative;
  }
  .product #more_info_block #more_info_sheets > div {
    padding-left: 30px;
  }
  .product #more_info_block #more_info_sheets:before {
    content: '.';
    text-indent: -5000px;
    border-left: 1px solid #f4f4f4;
    position: absolute;
    left: -1px;
    top: 0;
    z-index: 1;
    height: 100%;
  }
}

.product #more_info_block .content-tab {
  padding-left: 0;
  padding-top: 15px;
}

.product #more_info_block .table-data-sheet {
  border-color: #eee;
}

.product #more_info_block .table-data-sheet tr, .product #more_info_block .table-data-sheet td {
  border-color: #eee;
}

.product #more_info_block .table-data-sheet td:first-child {
  text-transform: uppercase;
}

.product #more_info_block #product_comments_block_tab #new_comment_tab_btn {
  border: none;
  height: 40px;
  padding: 0 15px;
  line-height: 40px;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  border-radius: 0;
}

.product #more_info_block #product_comments_block_tab #new_comment_tab_btn:hover span {
  background: none;
}

.product #more_info_block #product_comments_block_tab #new_comment_tab_btn span {
  border: none;
  padding: 0;
}

.product.content_only .primary_block.row {
  margin-left: 0;
  margin-right: 0;
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
}

.product.content_only #scroll-to-top {
  display: none;
}

.product.content_only .pb-left-column {
  padding-bottom: 30px;
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column {
    width: 40%;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-left-column {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product.content_only .pb-left-column {
    padding-left: 0;
    padding-right: 15px;
    width: 50%;
    float: left;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #thumbs_list li {
    width: 98px;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-left-column #views_block #thumbs_list li {
    width: 107px;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #view_scroll_right {
    top: 0;
    right: 0;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-left-column #views_block #view_scroll_left {
    top: 0;
    left: 0;
  }
}

.product.content_only .pb-left-column .resetimg {
  margin-top: 10px;
}

.product.content_only .pb-center-column {
  padding-left: 15px;
}

@media (max-width: 1199px) {
  .product.content_only .pb-center-column {
    width: 60%;
  }
}

@media (max-width: 991px) {
  .product.content_only .pb-center-column {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .product.content_only .pb-center-column {
    width: 50%;
    padding-right: 0;
    padding-left: 15px;
    float: left;
  }
}

@media (max-width: 320px) {
  .product.content_only .pb-center-column .box-info-product .functional-buttons {
    float: left;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-center-column .box-info-product .box-cart-bottom {
    margin-top: -65px;
    float: right;
  }
}

@media (max-width: 1199px) {
  .product.content_only .pb-right-column {
    display: none;
  }
}

#index .product {
  display: none;
}

/*PRODUCT RELATED SECTION*/
.page-product-box {
  padding-top: 30px;
  overflow: hidden;
}

.page-product-box .page-product-heading {
  background: none;
  border: none;
  padding: 0;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  border-bottom: 3px solid #a0a91c;
  padding-bottom: 10px;
  display: inline-block;
}

.page-product-box .block_content {
  margin-left: -15px;
  margin-right: -15px;
}

.page-product-box .owl-controls {
  position: absolute;
  right: 15px;
  top: 0;
  margin-top: -50px;
}

.page-product-box .item {
  width: 100%;
  padding: 0 15px;
  text-align: center;
}

.page-product-box .item .product_desc {
  position: relative;
  overflow: hidden;
}

.page-product-box .item .product-name {
  text-align: center;
}

.page-product-box .item .price {
  color: #a0a91c;
  font-family: "Montserrat", sans-serif;
}

.page-product-box .item .product-image {
  border: none;
}

.page-product-box .item .ajax_add_to_cart_button {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 30px;
}

.page-product-box .item .ajax_add_to_cart_button:before {
  content: "\f07a";
  font-family: "FontAweSome";
  color: #fff;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}

.page-product-box .item .ajax_add_to_cart_button span {
  padding: 0;
}

.heading-counter {
  margin-bottom: 0;
  font-family: "Montserrat", sans-serif;
}

.heading-counter #summary_products_quantity {
  font-size: 14px;
  color: #a0a91c;
}

/*Step*/
ul.step {
  border-radius: 0;
  margin-top: 25px;
}

ul.step li.step_todo {
  background: #333333;
  border: none;
  border-radius: 0;
}

ul.step li.step_todo span, ul.step li.step_todo a {
  border-radius: 0;
  border: none;
  text-shadow: none;
  color: #fff;
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
}

ul.step li.step_todo span:after, ul.step li.step_todo a:after {
  display: none;
}

ul.step li.step_current, ul.step li.step_done {
  background: #a0a91c;
  border: none;
  border-radius: 0;
}

ul.step li.step_current a, ul.step li.step_current span, ul.step li.step_done a, ul.step li.step_done span {
  text-shadow: none;
  border: none;
}

ul.step li.step_current a:after, ul.step li.step_current span:after, ul.step li.step_done a:after, ul.step li.step_done span:after {
  display: none;
}

ul.step li.step_done {
  font-style: italic;
}

ul.step li.step_done em {
  font-style: italic;
}

/*cart summary table*/
#cart_summary {
  position: relative;
}

#cart_summary:after {
  background: #eee none repeat scroll 0 0;
  bottom: -1px;
  content: "";
  display: block;
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}

#cart_summary tr td {
  border-color: #eee;
}

#cart_summary thead th {
  padding: 15px 20px;
}

#cart_summary tbody td {
  padding: 15px 20px;
}

#cart_summary tbody td.cart_product {
  padding: 20px;
}

#cart_summary tbody td.cart_product img {
  border: none;
}

#cart_summary tbody td.cart_avail span {
  border: none;
  background: none;
}

#cart_summary tfoot tr {
  background: none;
}

#cart_summary tfoot tr td {
  padding: 15px 20px;
}

#cart_summary > thead > tr > th {
  background: #eee;
  color: #333333;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
}

#cart_summary .price {
  color: #a0a91c;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
}

#cart_summary .price .price-percent-reduction, #cart_summary .price .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #cart_summary .price #reduction_percent {
  border-radius: 0;
  height: auto;
  width: auto;
  padding: 5px;
  position: relative;
  top: 0;
  right: 0;
}

#cart_summary #total_price {
  font-size: 18px;
  color: #a0a91c;
  font-weight: bold;
}

#cart_summary .btn.button-plus, #cart_summary .btn.button-minus {
  color: #fff;
  border: none;
}

@media (max-width: 767px) {
  #cart_summary .btn.button-plus, #cart_summary .btn.button-minus {
    display: inline-block;
    float: left;
  }
}

#cart_summary .btn.button-plus span, #cart_summary .btn.button-minus span {
  background: #333333;
}

#cart_summary .btn.button-plus:hover span, #cart_summary .btn.button-minus:hover span {
  background: #a0a91c;
}

#cart_summary .btn.button-plus {
  float: right;
}

.cart_navigation .standard-checkout, .cart_navigation .button-exclusive, .cart_navigation .btn {
  height: 40px;
  line-height: 40px;
  padding: 0px 20px;
  margin: 0;
}

@media (max-width: 767px) {
  .cart_navigation .standard-checkout, .cart_navigation .button-exclusive, .cart_navigation .btn {
    display: block;
    margin-bottom: 10px;
    float: left;
    width: 100%;
  }
}

.cart_navigation .standard-checkout i.right, .cart_navigation .standard-checkout i.left, .cart_navigation .standard-checkout i, .cart_navigation .button-exclusive i.right, .cart_navigation .button-exclusive i.left, .cart_navigation .button-exclusive i, .cart_navigation .btn i.right, .cart_navigation .btn i.left, .cart_navigation .btn i {
  color: inherit;
  font-size: 12px;
  line-height: 1;
  vertical-align: 0px;
  display: none;
}

.cart_navigation .standard-checkout span, .cart_navigation .button-exclusive span, .cart_navigation .btn span {
  font-size: 1rem;
}

.cart_navigation .standard-checkout, .cart_navigation [type="submit"] {
  border: none;
}

.cart_navigation .standard-checkout span, .cart_navigation [type="submit"] span {
  border: none;
  padding: 0;
}

.cart_navigation .button-exclusive {
  border: none;
  color: #fff;
}

#order label, #authentication label, #address label, #identity label {
  margin-bottom: 10px;
}

#order .form-group, #authentication .form-group, #address .form-group, #identity .form-group {
  margin-top: 20px;
}

#order .checkbox .checker, #authentication .checkbox .checker, #address .checkbox .checker, #identity .checkbox .checker {
  margin-top: 3px;
}

#order .addresses div.checker {
  margin-top: 3px;
}

#order .addresses .address_add.submit {
  margin-top: 20px;
}

#order p.payment_module a {
  border-radius: 0;
  background-color: transparent;
  border-color: #eee;
  background-position: 15px 50%;
}

#order p.payment_module a:hover {
  color: #a0a91c;
}

#order p.payment_module a:hover span {
  color: inherit;
}

#order p.payment_module a:hover:after {
  color: inherit;
}

/*Authentication*/
#authentication .box {
  border: none;
  background: none;
  padding-left: 0;
  padding-right: 0;
}

#authentication h3 + .required, #authentication .h3 + .required {
  display: none;
}

#authentication button#SubmitCreate {
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}

#authentication button#SubmitCreate span {
  border: none;
  padding: 0;
}

@media (max-width: 767px) {
  #authentication #create-account_form {
    min-height: 0;
  }
}

#authentication #login_form button#SubmitLogin {
  border: none;
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
}

#authentication #login_form button#SubmitLogin span {
  border: none;
  padding: 0;
}

/*Adress*/
#address #adress_alias {
  margin-bottom: 40px;
}

/*My account*/
#my-account ul.myaccount-link-list li a {
  border-radius: 0;
  background: none;
  border-color: #eee;
  color: #242424;
}

#my-account ul.myaccount-link-list li a i {
  text-shadow: none;
  border-radius: 0;
  border: none;
  color: inherit;
}

#my-account ul.myaccount-link-list li a span {
  font-size: 1.1428571429rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  border: none;
  border-left: 1px solid #eee;
  color: inherit;
  text-shadow: none;
  border-radius: 0;
}

#my-account ul.myaccount-link-list li a:hover {
  background: #a0a91c;
  color: #fff;
  border-color: #a0a91c;
}

/*Product Compare*/
#products-comparison #product_comparison {
  border-color: #eee;
}

#products-comparison #product_comparison .remove a {
  color: #333333;
}

#products-comparison #product_comparison .remove a:hover {
  color: #a0a91c;
}

#products-comparison #product_comparison tr td {
  border-color: #eee;
  padding: 20px;
}

#products-comparison #product_comparison tr td.comparison_infos {
  position: relative;
}

#products-comparison #product_comparison tr td.td_empty, #products-comparison #product_comparison tr td.feature-name {
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

#products-comparison #product_comparison tr td .price-percent-reduction, #products-comparison #product_comparison tr td .product .pb-center-column .content_prices #reduction_percent, .product .pb-center-column .content_prices #products-comparison #product_comparison tr td #reduction_percent {
  top: 54px;
  margin-top: 15px;
}

#products-comparison #product_comparison .product-image-block {
  width: 100%;
}

#products-comparison #product_comparison .product-image-block .product_image {
  border: none;
}

#products-comparison #product_comparison .product-image-block .product_image img {
  width: 100%;
}

#products-comparison #product_comparison h5, #products-comparison #product_comparison .h5 {
  min-height: 0;
  padding-bottom: 0;
}

#products-comparison #product_comparison .product-name {
  text-transform: uppercase;
  font-size: 1.1428571429rem;
}

#products-comparison #product_comparison .prices-container .product-price {
  font-size: 1rem;
}

#products-comparison #product_comparison .button-container a {
  margin: 0 5px;
}

#products-comparison #product_comparison .button-container a span {
  font-size: 1rem;
}

#products-comparison .table-responsive {
  margin-top: 30px;
}

#products-comparison div.star:after {
  color: #a0a91c;
}

#products-comparison div.star.star_on:after {
  color: #a0a91c;
}

/*ONESTEP CHECKOUT*/
#order-opc div.checker {
  margin-top: 3px;
}

#order-opc .title_block {
  font-weight: normal;
}

#order-opc div.selector {
  width: 100%;
}

#order-opc div.selector span, #order-opc div.selector select {
  width: 100%;
}

#order-opc .opc-wapper {
  margin-left: -15px;
  margin-right: -15px;
}

#order-opc #openLoginFormBlock:hover {
  color: #a0a91c;
}

#order-opc .form-group {
  margin-bottom: 10px;
}

#order-opc .form-group .form-control {
  width: 100%;
  max-width: 100%;
}

#order-opc #opc_new_account,
#order-opc #carrier_area,
#order-opc .opc_payment_area,
#order-opc .opc-account-area {
  width: 50%;
  float: left;
  padding: 0 15px;
}

@media (max-width: 767px) {
  #order-opc #opc_new_account,
  #order-opc #carrier_area,
  #order-opc .opc_payment_area,
  #order-opc .opc-account-area {
    width: 100%;
  }
}

/*OUR STORES*/
#stores .radius-input label {
  padding-top: 5px;
}

/*ABOUT US*/
.cms-about-us #center_column ul li {
  color: #a0a91c;
}

/*CONTACT*/
#contact .contact-info {
  padding-bottom: 30px;
}

#contact .contact-info .icon {
  margin-right: 10px;
  float: left;
}

#contact .contact-info .contact-info-title {
  display: block;
  text-transform: uppercase;
  font-size: 1.1428571429rem;
  font-family: "Montserrat", sans-serif;
  color: #a0a91c;
}

#contact .contact-info .contact-info-subtitle {
  text-transform: uppercase;
  color: #333333;
}

@media (max-width: 1199px) {
  #contact .contact-info .icon {
    display: block;
    float: none;
    text-align: center;
    margin-bottom: 10px;
  }
  #contact .contact-info .contact-info-title,
  #contact .contact-info .contact-info-subtitle {
    text-align: center;
    display: block;
  }
}

#contact .contact-form-box {
  padding-top: 0;
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#contact .contact-form-box select.form-control {
  max-width: 100%;
}

#contact .contact-form-box fieldset {
  background: none;
  padding: 0;
}

#contact .contact-form-box fieldset > .clearfix > div:nth-child(1) {
  padding-left: 0;
}

@media (max-width: 991px) {
  #contact .contact-form-box fieldset > .clearfix > div:nth-child(1) {
    padding-right: 0;
    margin-bottom: 15px;
  }
}

#contact .contact-form-box fieldset > .clearfix > div:nth-child(2) {
  padding-right: 0;
}

@media (max-width: 991px) {
  #contact .contact-form-box fieldset > .clearfix > div:nth-child(2) {
    padding-left: 0;
  }
}

#contact .contact-form-box .page-subheading {
  margin-top: 0;
}

#contact .contact-form-box div.selector {
  width: 100% !important;
}

#contact .contact-form-box input {
  width: 100%;
  max-width: 100%;
  background: #fff;
}

#contact .contact-form-box div.uploader {
  height: 35px;
  line-height: 35px;
}

#contact .contact-form-box div.uploader input, #contact .contact-form-box div.uploader span {
  height: 35px;
  line-height: 35px;
}

#contact .contact-form-box div.uploader span.action {
  width: auto;
  border-radius: 0;
  line-height: 35px;
}

@media (max-width: 370px) {
  #contact .contact-form-box div.uploader span.action {
    padding: 0 5px;
    min-width: 0;
  }
}

#contact address .address-title {
  font-size: 1.2857142857rem;
  font-family: "Montserrat", sans-serif;
  color: #a0a91c;
  display: block;
  text-align: center;
  text-transform: uppercase;
}

#contact .right-contact {
  margin-bottom: 30px;
}

#contact .right-contact p {
  color: #888888;
  line-height: 24px;
}

#contact .box-time-contact {
  background: #000 url(../images/bg-contact.jpg) center left no-repeat;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  padding: 35px;
  margin-top: 60px;
}

#contact .box-time-contact b {
  display: block;
  font-size: 30px;
  color: #a0a91c;
  margin-top: 25px;
}

/*SITEMAP*/
#left_column ul.tree {
  padding-left: 0;
}

#left_column ul.tree li {
  padding: 0;
  margin-left: 0;
  background: none;
  border: none;
}

#sitemap .page-subheading {
  border-bottom: 1px solid #eee;
}

#sitemap .sitemap_block li a:hover {
  font-weight: normal;
  color: #a0a91c;
}

/*ORDER HISTORY*/
#history .footable-row-detail-row {
  line-height: 2.3em;
}

#history .footable-row-detail-row .link-button {
  margin-left: 10px;
}

#history .footable-row-detail-row .link-button:hover {
  color: #a0a91c;
}

@media (max-width: 370px) {
  #history .footer_links li {
    margin-bottom: 10px;
    margin-left: 0;
    margin-right: 10px;
  }
}

/*WISHLIST*/
.module-blockwishlist-mywishlist #block-history {
  overflow: auto;
}

@media (max-width: 350px) {
  .module-blockwishlist-mywishlist .footer_links li {
    clear: both;
    margin-left: 0;
    margin-bottom: 10px;
  }
}

.cms-about-us p {
  color: #888888;
  line-height: 24px !important;
}

.cms-about-us .block-title h3, .cms-about-us .block-title .h3 {
  font-size: 20px !important;
}

.cms-about-us .box-about {
  margin: 0 0 50px;
}

.cms-about-us .box-about .page-heading {
  font-size: 30px;
}

.cms-about-us .box-text-about {
  background: #eee;
  padding: 20px;
  margin-bottom: 30px;
  transition: 0.4s;
}

.cms-about-us .box-text-about h4, .cms-about-us .box-text-about .h4 {
  font-size: 13px;
  text-transform: uppercase;
  margin-bottom: 20px;
  line-height: 22px;
}

.cms-about-us .box-text-about h4 i, .cms-about-us .box-text-about .h4 i {
  margin-right: 10px;
  font-size: 18px;
  float: left;
  vertical-align: middle;
  line-height: 22px;
}

.cms-about-us .box-text-about h4 span, .cms-about-us .box-text-about .h4 span {
  overflow: hidden;
  display: block;
}

.cms-about-us .box-text-about:hover {
  background: #333;
}

.cms-about-us .box-text-about:hover h4, .cms-about-us .box-text-about:hover .h4 {
  color: #fff;
}

.cms-about-us .about-center {
  position: relative;
  border-top: 1px solid #EAEAEA;
  padding-top: 60px;
  margin: 60px 0;
}

.cms-about-us .about-center:before {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 1px;
  background: #dbdbdb;
}

.cms-about-us .about-center .team-about {
  text-align: center;
  position: relative;
}

.cms-about-us .about-center .team-about h3, .cms-about-us .about-center .team-about .h3 {
  text-transform: uppercase;
  font-size: 16px !important;
  padding: 0 0 5px !important;
  margin: 0;
}

.cms-about-us .about-center .team-about span {
  color: #888888;
  font-style: italic;
  display: block;
  margin-bottom: 15px;
}

.cms-about-us .about-center .team-about .team-detail {
  background: #fff;
  margin: 0 20px;
  padding: 30px 20px;
  height: 80px;
  overflow: hidden;
  transition: 0.4s;
}

.cms-about-us .about-center .team-about:hover .team-detail {
  position: absolute;
  height: auto;
  bottom: -60px;
  overflow: visible;
  box-shadow: 1px 2px 1px 1px #eee;
}

.cms-about-us .about-center .team-about:hover span {
  color: #a0a91c;
}

@media (max-width: 767px) {
  .cms-about-us .about-center .team-about {
    margin-bottom: 50px;
  }
}

.cms-about-us .about-center .social-about {
  margin: 20px 0 0;
  padding: 0;
  text-align: center;
  list-style: none;
}

.cms-about-us .about-center .social-about li {
  display: inline-block;
  margin: 0 8px;
}

.cms-about-us .about-center .social-about li a {
  font-size: 0;
  text-align: center;
  background: #eee;
  color: #a0a91c;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}

.cms-about-us .about-center .social-about li a i {
  font-size: 14px;
  line-height: 40px;
}

.cms-about-us .about-center .social-about li a:hover {
  background: #a0a91c;
  color: #eee;
}

.cms-about-us .about-bottom {
  border-top: 1px solid #EAEAEA;
  padding-top: 60px;
}

.cms-about-us .about-bottom .col-sm-6:first-child, .cms-about-us .about-bottom .col-sm-6:nth-child(3n+0) {
  border-right: 1px dashed rgba(170, 170, 170, 0.3);
}

.cms-about-us .about-bottom .col-sm-6:first-child, .cms-about-us .about-bottom .col-sm-6:nth-child(2) {
  border-bottom: 1px dashed rgba(170, 170, 170, 0.3);
}

.cms-about-us .about-bottom .col-service {
  padding: 30px 0;
}

.cms-about-us .about-bottom .col-service .left-service {
  transition: 0.4s;
  float: left;
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 0;
  margin-right: 20px;
  background: #a0a91c;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  border: 5px solid #fff;
  box-shadow: 0 0 0 1px #a0a91c;
}

.cms-about-us .about-bottom .col-service .left-service i {
  font-size: 24px;
  line-height: 70px;
}

.cms-about-us .about-bottom .col-service .right-service {
  overflow: hidden;
}

.cms-about-us .about-bottom .col-service .right-service h3, .cms-about-us .about-bottom .col-service .right-service .h3 {
  text-transform: uppercase;
  margin-bottom: 20px !important;
  position: relative;
}

.cms-about-us .about-bottom .col-service .right-service h3:before, .cms-about-us .about-bottom .col-service .right-service .h3:before {
  content: "";
  height: 2px;
  width: 41px;
  background: #a0a91c;
  position: absolute;
  left: 0;
  bottom: 0;
}

.cms-about-us .about-bottom .col-service:hover .left-service {
  cursor: pointer;
  background: #333;
  box-shadow: 0 0 0 1px #333;
}

/*==============================CSS FOR PASSWORD PAGE==============================*/
#password p.submit {
  margin-bottom: 20px;
}

.post-description {
  display: none;
}

.module-bcblog-categoryPost .select {
  text-align: right;
}

.module-bcblog-categoryPost div.selector {
  max-width: 200px;
  display: inline-block;
}

.module-bcblog-categoryPost div.selector span {
  text-align: left;
}

.module-bcblog-categoryPost .product-count {
  margin-top: 10px;
  display: inline-block;
}

.module-bcblog-categoryPost .rss {
  margin-left: 10px;
  text-transform: uppercase;
}

.module-bcblog-categoryPost .rss:hover {
  color: #a0a91c;
}

.module-bcblog-categoryPost .columns-container ul.product_list {
  margin-left: -15px;
  margin-right: -15px;
  border: none;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-item {
  margin-bottom: 40px;
  padding: 0 15px;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image {
  position: relative;
  overflow: hidden;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  border: 2px solid #fff;
  opacity: 0;
  z-index: 1;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image a:hover:before {
  opacity: 1;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image img {
  width: 100%;
  height: auto;
  transition: 1s;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image:hover img {
  transform: scale3d(1.1, 1.1, 1);
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-left-post .post-image:hover a:before {
  opacity: 1;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name h3, .module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-name .h3 {
  font-size: 0.8571428571rem;
  text-transform: uppercase;
}

.module-bcblog-categoryPost .columns-container ul.product_list .bc-right-post .post-description {
  font-size: 0.8571428571rem;
  display: block;
}

.module-bcblog-categoryPost .columns-container ul.product_list.grid .bc-item {
  float: left;
  width: 50%;
}

.module-bcblog-categoryPost .columns-container ul.product_list.grid .bc-item:nth-child(2n+1) {
  clear: both;
}

@media (max-width: 480px) {
  .module-bcblog-categoryPost .columns-container ul.product_list.grid .bc-item {
    width: 100%;
  }
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item {
  float: left;
  width: 100%;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-left-post {
  width: 50%;
  float: left;
  padding-right: 15px;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post {
  width: 50%;
  float: left;
}

.module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post h3, .module-bcblog-categoryPost .columns-container ul.product_list.list .bc-item .bc-right-post .h3 {
  margin-top: 0;
}

.frame-date-author {
  font-family: "Open Sans", sans-serif;
  font-style: italic;
  color: #888888;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 10px;
  *zoom: 1;
}

.frame-date-author:after, .frame-date-author:before {
  content: "";
  display: table;
  clear: both;
}

.frame-date-author > div {
  display: block;
  *zoom: 1;
  float: left;
  margin-right: 12px;
}

.frame-date-author > div:after, .frame-date-author > div:before {
  content: "";
  display: table;
  clear: both;
}

.frame-date-author .post-comment span {
  font-size: 0;
}

.frame-date-author span {
  font-family: "Open Sans", sans-serif;
  font-style: inherit;
  font-size: 12px;
  color: inherit;
  display: inline-block;
}

.frame-date-author span:nth-child(1) {
  font-style: normal;
  font-weight: bold;
  font-size: 0;
  width: 18px;
}

.frame-date-author .date-add {
  font-size: 0;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  padding: 0 5px 0 0;
  margin: 0 !important;
}

.frame-date-author .date-add:before {
  content: "";
  font-family: "FontAweSome";
  font-size: 13px;
  display: inline-block;
  color: #888888;
  font-weight: normal;
}

.frame-date-author .author {
  font-size: 0;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  padding: 0 5px 0 0;
  margin: 0 !important;
}

.frame-date-author .author:before {
  content: "";
  font-family: "FontAweSome";
  font-size: 13px;
  display: inline-block;
  color: #888888;
  font-weight: normal;
}

.frame-date-author span.comment-count {
  font-size: 0;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  padding: 0 5px 0 0;
  margin: 0 !important;
  font-size: 12px;
  width: auto;
}

.frame-date-author span.comment-count:before {
  content: "";
  font-family: "FontAweSome";
  font-size: 13px;
  display: inline-block;
  color: #888888;
  font-weight: normal;
}

.frame-date-author span.comment-count:before {
  padding-right: 5px;
}

.module-bcblog-post .name_detail {
  text-transform: uppercase;
  color: #333333;
  margin-bottom: 5px;
  margin-top: 0;
}

.module-bcblog-post .img_detailblog {
  margin-bottom: 30px;
  text-align: center;
}

.module-bcblog-post .plpost_content {
  padding-bottom: 10px;
}

.module-bcblog-post .tag_blog {
  padding-bottom: 10px;
}

.module-bcblog-post .tag_blog .title_tag_blog {
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
  color: #333333;
}

.module-bcblog-post .tag_blog .tag_list {
  font-style: italic;
}

.module-bcblog-post .tag_blog .tag_list a:hover {
  color: #a0a91c;
  text-decoration: underline;
}

.module-bcblog-post .out_related_products {
  margin-bottom: 50px;
}

.module-bcblog-post .out_related_products .title {
  color: #333333;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #a0a91c;
}

.module-bcblog-post .out_related_products .product-image {
  border: none;
}

.module-bcblog-post .out_related_products .price {
  font-size: 0.7142857143rem;
  font-family: "Montserrat", sans-serif;
  color: #a0a91c;
}

.module-bcblog-post .out_related_products .ajax_add_to_cart_button {
  margin-top: 10px;
}

.module-bcblog-post .blog-comment-list {
  margin-bottom: 50px;
}

.module-bcblog-post .blog-comment-list .title {
  color: #333333;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 2px solid #a0a91c;
}

.module-bcblog-post .blog-comment-list .comment-item {
  margin-bottom: 20px;
}

.module-bcblog-post .blog-comment-list .comment-item > div span {
  font-family: "Playfair Display", serif;
  font-style: italic;
}

.module-bcblog-post .blog-comment-list .comment-item > div span:nth-child(1) {
  font-size: 0.8571428571rem;
  font-family: "Montserrat", sans-serif;
  margin-right: 10px;
  font-style: normal;
}

.module-bcblog-post .blog-comment-list .comment-item > div.comment-content {
  font-size: 0.8571428571rem;
  margin: 5px 0;
}

.module-bcblog-post #plpost {
  margin-bottom: 40px;
}

.module-bcblog-post #plpost .frame-date-author {
  border-bottom: none;
  padding-bottom: 30px;
}

.module-bcblog-post .related_posts .title {
  color: #333333;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #a0a91c;
}

.module-bcblog-post .related_posts .blog_content .item-related-post {
  margin-bottom: 40px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post:last-child {
  margin-bottom: 0;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name {
  margin-bottom: 20px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3 {
  margin-top: 0;
  color: #a0a91c;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8571428571rem;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-name h3 a, .module-bcblog-post .related_posts .blog_content .item-related-post .post-name .h3 a {
  color: inherit;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-description {
  margin: 0 0 10px;
  padding: 10px 0;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author {
  *zoom: 1;
  display: inline-block;
  margin-right: 12px;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add:after, .module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add:before, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author:after, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author:before {
  content: "";
  display: table;
  clear: both;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add span, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author span {
  font-family: "Open Sans", sans-serif;
  font-style: inherit;
  font-size: 12px;
  color: inherit;
  display: inline-block;
  float: left;
  line-height: 1.5;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add span:nth-child(1), .module-bcblog-post .related_posts .blog_content .item-related-post .post-author span:nth-child(1) {
  color: #333333;
  font-style: normal;
  padding-right: 5px;
  font-weight: bold;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add .date-add, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author .date-add {
  font-size: 0;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  padding: 0 5px 0 0;
  margin: 0 !important;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add .date-add:before, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author .date-add:before {
  content: "";
  font-family: "FontAweSome";
  font-size: 13px;
  display: inline-block;
  color: #888888;
  font-weight: normal;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add .author, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author .author {
  font-size: 0;
  display: inline-block;
  text-align: center;
  font-weight: normal;
  padding: 0 5px 0 0;
  margin: 0 !important;
}

.module-bcblog-post .related_posts .blog_content .item-related-post .post-date-add .author:before, .module-bcblog-post .related_posts .blog_content .item-related-post .post-author .author:before {
  content: "";
  font-family: "FontAweSome";
  font-size: 13px;
  display: inline-block;
  color: #888888;
  font-weight: normal;
}

.module-bcblog-post .pl_comment_form .title {
  color: #333333;
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 2px solid #a0a91c;
}

.module-bcblog-post .pl_comment_form .submit a {
  display: inline-block;
}

.post-name a:hover {
  color: #a0a91c;
}
