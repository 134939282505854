$baseFontSize: 14px;
$fontHeading: 'Montserrat', sans-serif;
$fontText: 'Open Sans', sans-serif;
$fontTextArt: 'Playfair Display', serif;
$fonticont: "FontAweSome";
$fontslide: 'Playball', cursive;
$colorText: #242424;
$colorText1: #ffffff;
$colorHeading: #333333;
$colorText2: #888888;
$colorTopNav__Currency: #a91111;
$bkgNav: #f9f9f9;
$bkgMenu: #a0a91c;
$bkgMenuHover: #333333;
$bkgFooter: #f9f9f9;
$colortheme: #333;
$colortheme1:#a0a91c;
$colortheme2: #fff;
$colortheme3: #eee;
$colortheme4:#919719;
$colortheme5:rgba(109,102,160,0.90);
$colortheme6: #f1f1f1;
$bordercolor: #f4f4f4;
$colortheme7:#171717;
// // /*----------------Color set green----------------------*/
// $bkgMenu: #70AF1A;
// $colortheme1:#70AF1A;
// $colortheme4:#8ACF2B;
// $colortheme5:rgba(112,175,26,0.9);
// // // /*----------------Color set blue----------------------*/
// $bkgMenu: #00A1D9;
// $colortheme1:#00A1D9;
// $colortheme4:#26BDF2;
// $colortheme5:rgba(0,161,217,0.9);
// // // /*----------------Color set orange----------------------*/
// $bkgMenu: #ff9000;
// $colortheme1:#ff9000;
// $colortheme4:#ff9000;
// $colortheme5:rgba(224,54,40,0.9);
// // // /*----------------Color set red----------------------*/
// $bkgMenu: #E03628;
// $colortheme1:#E03628;
// $colortheme4:#EC584C;
// $colortheme5:rgba(224,54,40,0.9);
